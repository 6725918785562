import { create } from 'zustand'
import { createSubcategorySlice } from './Slices/SubcategorySlice'
import { createCategorySlice } from './Slices/CategorySlice'
import {createTemplateSlice} from "./Slices/TemplateSlice";
import {createVideoEditorSlice} from "./Slices/VideoEditorSlice";
import {createUserSlice} from "./Slices/UserSlice";
import {createTagSlice} from "./Slices/TagSlice";
import {createPromoCodeSlice} from "./Slices/PromoCodeSlice";
import {createB2bCompaniesSlice} from "./Slices/B2bCompaniesSlice";
import {createB2bTemplateSlice} from "./Slices/B2bTemplateSlice";
import {createB2bVideoResultSlice} from "./Slices/B2bVideoResultSlice";
import {createStatisticsSlice} from "./Slices/StatisticsSlice";
import {createQrSlice} from "./Slices/QrSlice";

export const useBoundStore = create((...a) => ({
    ...createSubcategorySlice(...a),
    ...createCategorySlice(...a),
    ...createTemplateSlice(...a),
    ...createVideoEditorSlice(...a),
    ...createUserSlice(...a),
    ...createTagSlice(...a),
    ...createPromoCodeSlice(...a),
    ...createB2bCompaniesSlice(...a),
    ...createB2bTemplateSlice(...a),
    ...createB2bVideoResultSlice(...a),
    ...createStatisticsSlice(...a),
    ...createQrSlice(...a),
}))