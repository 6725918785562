import {AuthClient} from "./HttpClient";

export function getPaymentUrlAPI(data) {
    return AuthClient()
        .post(`dropbox`, data).then((res) => {
            return res.data
        });
}

export function getAllFilesDropbxoAPI(id) {
    return AuthClient()
        .get(`dropbox/${id}`).then((res) => {
            return res.data
        });
}

export function downloadAllFilesDropboxAPI(id) {
    return AuthClient()
        .get(`dropbox/${id}/download`).then((res) => {
            return res.data
        });
}

export function uploadMediaAPI(key, file, textReference) {
    const form = new FormData();
    form.append('file', file);

    return AuthClient()
        .post(`dropbox/${key}`, form,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                    textReference.innerText = progress + ' %';
                }
            }).then((res) => {
            return res.data
        });
}

export function validatePromocodeAPI(code) {
    return AuthClient()
        .get(`/dropbox/promocodes/${code}/validate`).then((res) => {
            return res.data
        });
}

export function getQrPackageAPI(id) {
    return AuthClient()
        .get(`dropbox/${id}/package`).then((res) => {
            return res.data
        });
}

export function getCurrentUserActiveDropbox(id) {
    return AuthClient()
        .get(`dropbox/user/active-dropbox`).then((res) => {
            return res.data
        }).catch(err => {
            return null;
        });
}