export default function Button(props){
    const {
        buttonText,
        clickEvent,
        customClass = null,
        isPrimary = true ,
        disabled = false,
        id = null
    } = props;

    const clicked = (e) => {
        if(!disabled){
            clickEvent(e);
        }
    }
    const classList = () => {
        let defaultClasses = 'px-8 py-2 rounded-md select-none m-auto cursor-pointer text-center flex justify-center items-center text-sm ';

        if(!disabled) {
            defaultClasses += 'cursor-pointer ';
        } else {
            defaultClasses += 'opacity-50 '
        }

        if(isPrimary && customClass === null){
            defaultClasses += 'text-white bg-primary border border-transparent hover:border-primary hover:bg-transparent hover:text-primary transition duration-300'
        }else if(customClass === null){
            defaultClasses += 'text-primary bg-transparent border border-primary hover:bg-primary hover:text-white transition duration-300'
        }

        if(customClass === 'only-white'){
            defaultClasses += 'text-white bg-transparent border border-white hover:text-white transition duration-300'
        }

        return defaultClasses;
    }

    const getRandomId = () => {
        return Math.random().toString(36).slice(2)
    }

    return (
      <>
        <div onClick={clicked} className={classList()} id={id !== null ? id : getRandomId()}>
            <span>{buttonText}</span>
        </div>
      </>
    );
}