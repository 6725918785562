import Button from "../Forms/Button";
import {t} from "i18next";

export default function ConfirmDeleteModal(props) {
    const {isShown, closePopup, clickConfirmed} = props;

    const handleCloseClick = () => {
        closePopup();
    }

    return (
        <>
            { isShown &&
                <>
                    <div className={"h-screen z-10 w-screen h-screen bg-gray-800 fixed top-0 left-0 bg-opacity-60"}></div>
                    <div tabIndex="-1" aria-hidden={!isShown} aria-modal={true} role={"dialog"} aria-labelledby={"ConfirmDeleteModal"}
                         className={"transition-all ease-in-out overflow-y-auto overflow-x-hidden flex flex-col justify-center fixed top-0 right-0 left-0 z-50 w-full md:inset-0 md:h-full" + (!isShown ? 'hidden' : '')}>
                        <div className="relative p-4 w-full max-w-md m-auto">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                                <div className="flex flex-row justify-between bg-primary py-3 px-4 border-b border-white rounded-tl-lg rounded-tr-lg">
                                    <h3 className="text-xl font-medium text-white my-auto">{t('qr.confirm')}</h3>
                                    <button type="button" onClick={handleCloseClick}
                                            className="text-white bg-transparent hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule={"evenodd"} d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule={"evenodd"}></path>
                                        </svg>
                                        <span className="sr-only">{t('qr.close_modal')}</span>
                                    </button>
                                </div>


                                <div className="py-6 px-6 lg:px-8 bg-[#FFF8EC] text-gray-600">
                                    <p className={"mb-4"}>{t('qr.confirm_deleting_message')}</p>

                                    <div className={"flex flex-row justify-between"}>
                                        <Button
                                            isPrimary={false}
                                            buttonText={t('qr.close_modal')}
                                            clickEvent={handleCloseClick}
                                        >
                                        </Button>

                                        <div onClick={clickConfirmed} className={"rounded bg-red-600 border border-transparent px-4 py-2 text-white hover:text-red-600 hover:bg-transparent hover:border hover:border-red-600 cursor-pointer transition-all ease-in-out duration-300"}>
                                            {t('qr.confirm')}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}