import {Header} from "../Components/DemoComponents/header";
import {DemoEditor} from "../Components/DemoComponents/DemoEditor";
import {Helmet} from "react-helmet";

export default function DemoPage() {
    const urlPath = window.location.pathname;
    const demo = (urlPath === '/demo');

    return (
        <div className={"bg-secondary relative overflow-x-hidden"}>
            {
                demo && (
                    <Helmet>
                        <meta
                            name="description"
                            content="Verbeter de betrokkenheid van je fans met gepersonaliseerde promo video’s - test het hier in onze demo."
                        />
                        <meta property="og:description"
                              content="Verbeter de betrokkenheid van je fans met gepersonaliseerde promo video’s - test het hier in onze demo."
                              data-react-helmet="true"
                        />
                    </Helmet>
                )
            }

            {
                !demo && (
                    <Helmet>
                        <meta
                            name="description"
                            content="Verbeter de betrokkenheid van je evenement met gepersonaliseerde video’s van je bezoekers - test het hier in onze demo."
                        />
                        <meta property="og:description"
                              content="Verbeter de betrokkenheid van je evenement met gepersonaliseerde video’s van je bezoekers - test het hier in onze demo."
                              data-react-helmet="true"
                        />
                    </Helmet>
                )
            }
            <Header />
            <DemoEditor/>
        </div>
    );
}