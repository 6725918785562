import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Link} from "react-router-dom";
import ConfirmDeleteModal from "../../../Components/Popups/ConfirmDeleteModal";
import toast from "react-hot-toast";
import Pagination from "../../../Components/General/Pagination";
import {deleteTemplate, getAllTemplates} from "../../../Api/templatesApi";
import {useBoundStore} from "../../../Store/store";

export default function TemplateIndex() {
    const [isConfirmDeleteShown, setIsConfirmDeleteShown] = useState(false);
    const [deletingTemplateID, setDeletingTemplateID] = useState(null);
    const [page, setPage] = useState(1);

    const templates = useBoundStore((state) => state.templates);
    const metaData = useBoundStore((state) => state.templateMetaData);
    const fetchTemplatesPaginated = useBoundStore((state) => state.fetchTemplatesPaginated);

    useEffect(() => {
        fetchTemplatesPaginated(page).then(r => {});
    },[fetchTemplatesPaginated, page]);


    const changePage = async (newPage) => {
        setPage(newPage);
    }

    const handleCloseConfirmDelete = () => {
        setIsConfirmDeleteShown(false);
        setDeletingTemplateID(null);
    }

    const handleOpenConfirmDelete = (id) => {
        setIsConfirmDeleteShown(true);
        setDeletingTemplateID(id);
    }

    const deleteTagAction = async () => {
        if(deletingTemplateID === null) return false;

        await deleteTemplate(deletingTemplateID);
        fetchTemplatesPaginated(page).then(() => {});

        handleCloseConfirmDelete();

        toast.success('Template has been successfully deleted',{
            position: "top-right"
        });
    }

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>Templates</h1>
                <Link to={'/admin/templates/create'} className={"py-2 px-4 ease-in-out duration-300 bg-secondary text-white cursor-pointer rounded-lg border border-transparent transition-all hover:bg-transparent hover:text-secondary hover:border hover:border-secondary"}>
                    Create a new Template
                </Link>
            </div>

            <div className="grid grid-cols-5 text-left mb-2 text-white bg-primary uppercase rounded-tr rounded-tl">
                <div className={"p-2"}>Title</div>
                <div className={"p-2"}>Song</div>
                <div className={"p-2"}>Artist</div>
                <div className={"p-2"}>Price</div>
                <div className={"p-2"}>Actions</div>
            </div>

            {
                templates.map((template) => {
                    return (
                        <div key={`template_${template.id}`}>
                            <div className={"grid grid-cols-5 text-left border-b border-gray-500 border-opacity-30"} >
                                <Link to={`${template.id}`}>
                                    <div className={"p-2"}>{template.title}</div>
                                </Link>
                                <Link to={`${template.id}`}>
                                    <div className={"p-2"}>{template.songTitle}</div>
                                </Link>
                                <Link to={`${template.id}`}>
                                    <div className={"p-2"}>{template.artistName}</div>
                                </Link>
                                <Link to={`${template.id}`}>
                                    <div className={"p-2"}>{template.price / 100} €</div>
                                </Link>
                                <div className={"grid grid-cols-3 p-2"}>
                                    <div>
                                        <FontAwesomeIcon onClick={() => {handleOpenConfirmDelete(template.id)}} icon={icon({name:"trash"})} className={"text-red-600 cursor-pointer"}/>
                                    </div>
                                    <Link to={`${template.id}/update`}>
                                        <div className={"text-right"}>
                                            <FontAwesomeIcon icon={icon({name:"edit"})} className={"cursor-pointer text-gray-500 text-right"}/>
                                        </div>
                                    </Link>

                                    <Link to={`${template.id}`}>
                                        <div className={"text-right"}>
                                            <FontAwesomeIcon icon={icon({name:"angle-right"})} className={"cursor-pointer text-gray-500 text-right"}/>
                                        </div>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    )
                })
            }

            <div className={"w-full text-right mb-8 flex flex-row justify-end px-8"}>
                <Pagination metaData={{...metaData, current_page: page}} changePage={changePage} page={page}/>
            </div>

            <ConfirmDeleteModal
                isShown={isConfirmDeleteShown}
                closePopup={handleCloseConfirmDelete}
                clickConfirmed={deleteTagAction}
            ></ConfirmDeleteModal>
        </>
    )
}