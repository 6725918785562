import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import Button from "../../../Components/Forms/Button";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {createTemplate} from "../../../Api/templatesApi";
import {getAllSubCategories} from "../../../Api/subCategoriesApi";

export default function TemplateCreate() {
    const [template, setTemplate] = useState({
        title: null,
        songTitle: null,
        artistName: null,
        songFile: undefined,
        price: null,
        youtubePreviewUrl: null,
        jsonConfig: null,
        tags: [],
        subcategories: [],
        category: null,
        categoryId: null
    });
    const [subCategories, setSubCategories] = useState([]);
    const [showAllSubcategories, setShowAllSubcategories] = useState(false);
    const [filteredSubcategories, setFilteredSubcategories] = useState([]);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        (async function() {
            await setSubCategories(await getAllSubCategories());
        })()
    }, [])

    const save = async () => {
        const currentTemplate = template;

        if(currentTemplate?.subcategories !== undefined && currentTemplate.subcategories.length > 0){
            currentTemplate.subcategories = currentTemplate?.subcategories.map((subcategory) => {
                return subcategory.id;
            });
        }else{
            delete currentTemplate.subcategories;
        }

        if(currentTemplate?.category !== null && currentTemplate?.category !== undefined){
            currentTemplate.categoryId = parseInt(currentTemplate?.category?.id);
        }

        if(currentTemplate?.jsonConfig !== undefined){
            while (typeof currentTemplate.jsonConfig !== 'object') {
                currentTemplate.jsonConfig = JSON.parse(currentTemplate.jsonConfig);
            }
            currentTemplate.jsonConfig = JSON.stringify(currentTemplate.jsonConfig);
        }

        if(currentTemplate.category === null){
            delete currentTemplate.category;
        }
        if(currentTemplate.categoryId === null){
            delete currentTemplate.categoryId;
        }

        const response = await createTemplate(currentTemplate);
        if('errors' in response){
            setErrors(response.errors);

            toast.error('Not all data has been filled in correctly',{
                position: "top-right"
            });
            return false;
        }

        setTemplate(response);
        setFilteredSubcategories(filterAllSubcategories());

        toast.success('Template has been successfully updated',{
            position: "top-right"
        });

        navigate('/admin/templates', {
            replace: true
        });
    }

    const toggleSubcategories = () => {
        setShowAllSubcategories(!showAllSubcategories);
    }

    const addSubcategoryToTemplate = (subcategory) => {
        const currentTemplate = template;
        currentTemplate?.subcategories.push(subcategory);
        setTemplate(currentTemplate);
        setFilteredSubcategories(filterAllSubcategories());
    }

    const filterAllSubcategories = () => {
        return subCategories?.filter((subcategory) => {
            if(template?.subcategories === [] || template?.subcategories === null || template?.subcategories === undefined) return true;

            const allSubcategoryIds = template?.subcategories.map((subcategory) => {return subcategory.id});
            if(!allSubcategoryIds.includes(subcategory.id)) return true;
            return false
        }) ?? subCategories;
    }

    const removeSubcategoryFromTemplate = (id) => {
        const currentTemplate = template;
        currentTemplate.subcategories = currentTemplate.subcategories.filter((subcategory) => {
            if(subcategory.id === id) return false;
            return true;
        })
        setTemplate(currentTemplate);
        setFilteredSubcategories(filterAllSubcategories());
    }

    useEffect(() => {
        setFilteredSubcategories(filterAllSubcategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subCategories])

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>Template create</h1>
                <Link to={'/admin/templates'} className={"py-2 px-4 ease-in-out duration-300 text-secondary hover:text-primary cursor-pointer transition-all"}>
                    <FontAwesomeIcon icon={icon({name: 'arrow-left'})} className={"mr-2"}/>
                    Back to templates
                </Link>
            </div>

            <div>
                <div className={"text-left mb-2"}>
                    <label htmlFor={"name"} className={"text-left pl-4 font-bold"}>Title</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        defaultValue={template?.title}
                        onChange={(e) => {
                            template.title = e.target.value;
                        }}
                        id={"title"}
                        name={"title"}
                        type={"text"}
                        required
                    />
                </div>

                {
                    'title' in errors &&
                    <span className={"text-red-600 text-left"}>{errors['title']}</span>
                }

                <div className={"text-left mb-2"}>
                    <label htmlFor={"artistName"} className={"text-left pl-4 font-bold"}>Artist Name</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        defaultValue={template?.artistName}
                        onChange={(e) => {
                            template.artistName = e.target.value;
                        }}
                        id={"artistName"}
                        name={"artistName"}
                        type={"text"}
                        required
                    />
                </div>

                {
                    'artistName' in errors &&
                    <span className={"text-red-600 text-left"}>{errors['artistName']}</span>
                }

                <div className={"text-left mb-2"}>
                    <label htmlFor={"songTitle"} className={"text-left pl-4 font-bold"}>Song title</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        defaultValue={template?.songTitle}
                        onChange={(e) => {
                            template.songTitle = e.target.value;
                        }}
                        id={"songTitle"}
                        name={"songTitle"}
                        type={"text"}
                        required
                    />
                </div>

                {
                    'songTitle' in errors &&
                    <span className={"text-red-600"}>{errors['songTitle']}</span>
                }

                <div className={"text-left mb-2"}>
                    <label htmlFor={"name"} className={"text-left pl-4 font-bold"}>Song file</label>
                    <div className={"flex flex-row justify-evenly"}>
                        <input
                            className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                            defaultValue={template?.songFile}
                            onChange={(e) => {
                                template.songFile = e.target.files[0];
                            }}
                            id={"song-file"}
                            name={"song-file"}
                            type={"file"}
                            required
                        />

                        <audio src={template?.songFile?.url} controls />
                    </div>
                </div>

                {
                    'songFile' in errors &&
                    <span className={"text-red-600"}>{errors['songFile']}</span>
                }

                <div className={"text-left mb-2"}>
                    <label htmlFor={"name"} className={"text-left pl-4 font-bold"}>Price</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        defaultValue={template?.price}
                        onChange={(e) => {
                            template.price = e.target.value ;
                        }}
                        id={"price"}
                        name={"price"}
                        type={"number"}
                        min={0}
                        required
                    />
                </div>

                {
                    'price' in errors &&
                    <span className={"text-red-600"}>{errors['price']}</span>
                }

                <div className={"text-left mb-2"}>
                    <label htmlFor={"name"} className={"text-left pl-4 font-bold"}>Youtube preview url</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        defaultValue={template?.youtubePreviewUrl}
                        onChange={(e) => {
                            template.youtubePreviewUrl = e.target.value ;
                        }}
                        id={"youtubePreviewUrl"}
                        name={"youtubePreviewUrl"}
                        type={"text"}
                        min={0}
                        required
                    />
                </div>
                {
                    'youtubePreviewUrl' in errors &&
                    <span className={"text-red-600"}>{errors['youtubePreviewUrl']}</span>
                }

                <div className={"text-left mb-2"}>
                    <label htmlFor={"jsonConfig"} className={"text-left pl-4 font-bold"}>JSON Config file</label>
                    <div className={"flex flex-row justify-between"}>
                        <input
                            className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4 w-3/4"
                            onChange={async (e) => {
                                const file = e.target.files.item(0);
                                template.jsonConfig = await file.text();
                            }}
                            id={"jsonConfig"}
                            name={"jsonConfig"}
                            type={"file"}
                            min={0}
                            required
                        />

                        <div className={"w-1/4 flex flex-col justify-center"}>
                            <a
                                href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(template?.jsonConfig))}`} download={"Config.json"}
                                className={"m-auto w-fit py-2 px-4 border border-secondary text-secondary rounded-md text-left hover:border-transparent hover:text-white hover:bg-secondary transition-all ease-in-out duration-300"}
                            >
                                Download current config
                                <FontAwesomeIcon icon={icon({name: 'download'})} className={"ml-2"}/>
                            </a>
                        </div>
                    </div>
                </div>

                {
                    'jsonConfig' in errors &&
                    <span className={"text-red-600"}>{errors['jsonConfig']}</span>
                }

                <div className={"text-left mb-2"}>
                    <label htmlFor={"subcategory"} className={"text-left pl-4 font-bold"}>Subcategories</label>
                    <div className={"flex flex-wrap justify-start gap-2 mb-2"}>
                        {
                            template?.subcategories?.map((subcategory) => {
                                return (
                                    <div key={`subcategory_select_template_${subcategory.id}`}
                                         className={"px-2 py-1 bg-secondary text-white rounded-md cursor-pointer"}
                                         onClick={() => {
                                             removeSubcategoryFromTemplate(subcategory.id)
                                         }}
                                    >
                                        {subcategory.name}
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div
                        className={"rounded w-fit mt-1 p-2 bg-primary text-white cursor-pointer"}
                        onClick={toggleSubcategories}
                    >
                        +
                    </div>

                    <div className={"flex flex-wrap justify-start gap-2 mb-2 mt-2"}>
                        {
                            showAllSubcategories &&
                            filteredSubcategories?.map((subcategory) => {
                                return (
                                    <div key={`subcategory_select_to_add_to_template_${subcategory.id}`}
                                         className={"px-2 py-1 bg-secondary text-white rounded-md cursor-pointer"}
                                         onClick={() => {addSubcategoryToTemplate(subcategory)}}
                                    >
                                        {subcategory.name}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                {
                    'subcategories' in errors &&
                    <span className={"text-red-600"}>{errors['subcategories']}</span>
                }


                <div className={"flex flex-row justify-end mt-2"}>
                    <Button
                        buttonText={"Save"}
                        clickEvent={save}
                    >
                    </Button>
                </div>
            </div>
        </>
    )
}