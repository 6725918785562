import {AuthClient} from "./HttpClient";

export function getAllPromocodes(page = 1) {
    return AuthClient()
        .get(`promo-codes?page=${page}`).then((res) => {
            return res.data
        });
}

export function getPromocodes(id) {
    return AuthClient()
        .get(`promo-codes/${id}`).then((res) => {
            return res.data
        })
}

export function deletePromocodes(id){
    return AuthClient()
        .delete(`promo-codes/${id}`).then((res) => {
            return res.data
        })
}

export function updatePromocodes(id, params){
    return AuthClient()
        .patch(`promo-codes/${id}`, params).then((res)=>{
            return res.data
        })
}

export function createPromocodes(params){
    return AuthClient()
        .post(`promo-codes`, params).then((res)=>{
            return res.data
        })
}

export function validatePromocode(id){
    return AuthClient()
        .post(`promo-codes/${id}/validatecode`).then((res)=>{
            return res.data
        })
}