import {AuthClient} from "./HttpClient";

export function getAllTags(page = 1) {
    return AuthClient()
        .get(`tags?page=${page}`).then((res) => {
            return res.data
        });
}

export function getAllTagsWithoutPagination(page = 1) {
    return AuthClient()
        .get(`tags-notpaginated`).then((res) => {
            return res.data
        });
}

export function getTag(id) {
    return AuthClient()
        .get(`tags/${id}`).then((res) => {
            return res.data
        })
}

export function deleteTag(id){
    return AuthClient()
        .delete(`tags/${id}`).then((res) => {
            return res.data
        })
}

export function updateTag(id, params){
    return AuthClient()
        .patch(`tags/${id}`, params).then((res)=>{
            return res.data
        })
}

export function createTag(params){
    return AuthClient()
        .post(`tags`, params).then((res)=>{
            return res.data
        })
}