import {useTranslation} from "react-i18next";

export default function CookiePolicy() {
    const {t} = useTranslation();

    return (
        <>
            <div className={"flex flex-row relative"}>
                <div className={"text-left text-white p-8 mt-12 gap-8"}>

                    <p className={"text-primary text-2xl pb-4"}><b><u>{t('legal.cookie_policy.title')}</u></b></p>
                    <p>{t('legal.cookie_policy.subtitle')}</p>

                    <h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.cookie_policy.title_1')}</h1>
					<p>{t('legal.cookie_policy.body_1')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.cookie_policy.title_2')}</h1>
					<p>{t('legal.cookie_policy.body_2')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.cookie_policy.title_3')}</h1>
					<p>{t('legal.cookie_policy.body_3')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.cookie_policy.title_4')}</h1>
					<ul className={"m-auto list-disc pl-4 marker:text-primary"}>
						<li>{t('legal.cookie_policy.list_4_1')}</li>
						<li>{t('legal.cookie_policy.list_4_2')}</li>
						<li>{t('legal.cookie_policy.list_4_3')}</li>
						<li>{t('legal.cookie_policy.list_4_4')}</li>
					</ul>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.cookie_policy.title_5')}</h1>
					<p>{t('legal.cookie_policy.body_5')}</p>
					<ul className={"m-auto list-disc pl-4 marker:text-primary mt-2"}>
						<li>{t('legal.cookie_policy.list_5_1')}</li>
						<li>{t('legal.cookie_policy.list_5_2')}</li>
						<li>{t('legal.cookie_policy.list_5_3')}</li>
						<li>{t('legal.cookie_policy.list_5_4')}</li>
						<li>{t('legal.cookie_policy.list_5_5')}</li>
					</ul>


					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.cookie_policy.title_6')}</h1>
					<p>{t('legal.cookie_policy.body_6')}</p>
					<ul className={"m-auto list-disc pl-4 marker:text-primary mt-2"}>
						<li>{t('legal.cookie_policy.list_6_1')}</li>
					</ul>

				</div>
            </div>
        </>
    );
}