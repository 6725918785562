import {useContext, useState} from "react";
import AuthContext from "../../Helpers/AuthenticationHelper";
import {useTranslation} from "react-i18next";

export default function AdminTopNavigation(){
    const [heightMobileMenu, setHeightMobileMenu] = useState(0);
    const [opacityMobileMenu, setOpacityMobileMenu] = useState(0);
    const { user, logout } = useContext(AuthContext)
    const { t } = useTranslation();

    const setVisibilityMobileMenu = () => {
        if(heightMobileMenu === 0) {
            setHeightMobileMenu('100px')
            setOpacityMobileMenu(1)
        } else {
            setHeightMobileMenu(0)
            setOpacityMobileMenu(0)
        }
    }

    const getBackgroundClass = () => {
        return opacityMobileMenu === 0 ? 'flex' : 'block';
    }

    const logoutUser = () => {
        logout();
    }

    return (
        <>
            <div className="px-6 py-4 bg-primary">
                <nav className="navbar navbar-expand-lg ">
                    <div className="flex flex-row justify-end">
                        <div className={"hidden md:block"}>
                            <div className="flex flex-col justify-center h-full">
                                <div className="m-auto text-white cursor-pointer group">
                                    {
                                        user?.user != null &&
                                        <div>
                                            <p>{user.user.firstname}</p>
                                            <div className={"hidden group-hover:block absolute right-[21px] z-20 flex-col bg-brandblue drop-shadow-lg"}>
                                                <p className="px-5 py-3" onClick={logoutUser}>Logout</p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        user?.user == null &&
                                        <p>{t('authentication.sign_in')}</p>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={"md:hidden"}>
                            <div className="p-4 space-y-2 border border-white rounded shadow cursor-pointer" onClick={setVisibilityMobileMenu}>
                                <span className="block w-8 h-0.5 bg-gray-100"></span>
                                <span className="block w-8 h-0.5 bg-gray-100"></span>
                                <span className="block w-8 h-0.5 bg-gray-100"></span>
                            </div>
                        </div>

                    </div>

                    <div style={{height: heightMobileMenu, opacity: opacityMobileMenu}} className={"transition-all transition transform w-full bg-primary transition-[height] duration-500 ease-in-out " + getBackgroundClass()}>
                        <div className="flex flex-col justify-center mt-4">
                            <div className="m-auto text-white cursor-pointer">
                                <div className="m-auto text-white cursor-pointer group">
                                    {
                                        user?.user != null &&
                                        <div>
                                            <p>{user.user.firstname}</p>
                                            <div className={"hidden group-hover:block absolute right-[21px] z-20 flex-col bg-brandblue drop-shadow-lg"}>
                                                <p className="px-5 py-3" onClick={logoutUser}>Logout</p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        user?.user == null &&
                                        <p>Sign in</p>
                                    }
                                </div>
                            </div>
                        </div>

                        <hr className={"w-full border-t border-white mt-4"} />

                        <div className="flex flex-col justify-center mt-4">
                            <p onClick={logoutUser} className="m-auto text-red-500 cursor-pointer">{t('authentication.logout')}</p>
                        </div>

                    </div>
                </nav>
            </div>
        </>
    )
}