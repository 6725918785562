import useVideoHelper from "../../Helpers/VideoHelper";
import useRunOnce from "../../Helpers/RunOnce";

import React, {useEffect, useState} from "react";
import DemoVideoEditor from "./DemoVideoEditor";
import {useTranslation} from "react-i18next";
import MailchimpSubscribePopup from "../Popups/MailchimpSubscribePopup";

export const DemoEditor = () => {
    let [visible, setVisible] = useState(false);
    let [progress] = useState(null);
    let [currentProgressingId] = useState(null);
    const [showLoading, setShowLoading] = useState({});
    const {t} = useTranslation();
    const [showSubscribe, setShowSubscribe] = useState(false);

    let newFileList = [];

    const videoHelper = useVideoHelper();

    const urlPath = window.location.pathname;
    let prefixUrl;
    if(urlPath === '/demo'){
        prefixUrl = '/videos/demo/editor/demo_url/'
    } else{
        prefixUrl = '/videos/demo/editor/'
    }

    const [jsonData] = useState(urlPath === '/demo'
        ?
        {
            clips:[
                {duration: 26400},
                {duration: 4933.333333333334},
                {duration: 28500}
            ]
        }
        :
        {
            clips:[
                {duration: 9700},
                {duration: 1400},
                {duration: 10500}
            ]
        }
    )

    useEffect(() => {
        setShowLoading(videoHelper.loadingFfmpeg);
    }, [videoHelper?.loadingFfmpeg]);

    useRunOnce({
        fn: () => {
            videoHelper.initiate();
            setVisible(true);
            visible = true;
            videoHelper.setSkipTrimmingForVideos(['0.mp4', '2.mp4']);
        }
    })

    videoHelper.useSetJsonDataTimingsForClips(jsonData.clips);

    const handleDownload = async () => {
        try {

            await videoHelper.getFileFromUrl(new URL(prefixUrl + '0.mp4', document.location).href, '0.mp4');
            await videoHelper.getFileFromUrl(new URL(prefixUrl + '2.mp4', document.location).href, '2.mp4');

            const currentFileList = videoHelper.fileList;
            newFileList = [
                '0.mp4',
                currentFileList[0],
                '2.mp4',
            ];

            const currentTimings = videoHelper.fileTimings;
            const newTimings = [
                null,
                currentTimings[1],
                null,
            ];

            await videoHelper.getResultVideo(
                new URL(prefixUrl + 'song.mp4', document.location).href,
                new URL('/img/empty-png.png', document.location).href,
                true,
                true,
                true,
                newFileList, newTimings
            );

        } catch (err){
            console.log(err)
        }
    }

    const openMailchimpSubscribePopup = () => {
        setShowSubscribe(true);
    }

    return (
        <div className={"p-8 bg-white text-left element relative"} id={"demoEditor"}>
            <h2 className={`text-4xl uppercase mt-4 text-center text-demo ${urlPath === '/demo' ? 'mb-2' : 'mb-8'}`}>
                {
                    urlPath === '/demo' && (
                        <>{t('demo.demo.title_editor')}</>
                    )
                }
                {
                    urlPath !== '/demo' && (
                        <>{t('demo.festival.title_editor')}</>
                    )
                }
            </h2>

            {
                urlPath === '/demo' && (
                    <p className={"text-xl text-black text-center mb-6"}>{t('demo.demo.subtitle_editor')}</p>
                )
            }

            <div id={"loading_screen_editor"} className={`${showLoading?.status ? '' : 'hidden'} absolute z-50 bg-secondary h-full w-full overflow-hidden top-0 left-0 flex flex-col justify-center items-center`}>
                <div className={""}>
                    <img src={'/img/loading.svg'} className={"m-auto"} alt={"Loading animation"}/>
                    <p className={"text-white text-xl"}>{showLoading?.message}</p>
                </div>
            </div>

            {
                visible && (
                    <div>
                        <div className={"flex flex-col md:flex-row"}>
                            <div className={"w-full md:w-[33.33%] flex justify-center md:justify-end"}>
                                <video controls={true} muted={true} className={"h-full object-cover"}>
                                    <source src={`${prefixUrl}0.mp4#t=0.001`} type={"video/mp4"}/>
                                </video>
                            </div>
                            <div className={"w-full md:w-[33.33%] h-full"}>
                                <DemoVideoEditor
                                    key={`wrapper1`}
                                    duration={1.4}
                                    name={`wrapper1`}
                                    VideoHelper={videoHelper}
                                    progress={progress}
                                    currentHandlingId={currentProgressingId}
                                    index={1}
                                    lastInRow={true}
                                    lastElement={true}
                                    className={"h-full"}
                                />
                            </div>
                            <div className={"w-full md:w-[33.33%] flex justify-center md:justify-start"}>
                                <video controls={true} muted={true} className={"h-full object-cover"}>
                                    <source src={`${prefixUrl}2.mp4#t=0.001`} type={"video/mp4"}/>
                                </video>
                            </div>
                        </div>

                        <div className={"flex flex-row justify-center mt-8"}>
                            <div
                                className={"border px-4 py-2 border-demo text-demo cursor-pointer hover:bg-demo hover:text-white transition-all duration-300"}
                                onClick={openMailchimpSubscribePopup}
                                id={'download_video_button'}
                            >
                                {t('demo.demo.download_video')}
                            </div>
                        </div>
                    </div>
                )
            }

            {
                !visible && (
                    <>
                        Setting up
                    </>
                )
            }

            <MailchimpSubscribePopup
                isShown={showSubscribe}
                closePopup={() => setShowSubscribe(false)}
                clickConfirmed={() => {
                    handleDownload()
                }}
            />
        </div>
    )
}
