import {getRandomColor} from "./GeneralHelpers";

export const possibleIndex = [
    'bounce_rate',
    'pageviews',
    'views_per_visit',
    'visit_duration',
    'visitors',
    'visits'
];

export const getLabelForIndex = (index) => {
    switch (index){
        case 'bounce_rate':
            return 'Bounce Rate'
        case 'pageviews':
            return 'Page views'
        case 'views_per_visit':
            return 'Views per visit'
        case 'visit_duration':
            return 'Visit duration'
        case 'visitors':
            return 'Visitors'
        case 'visits':
            return 'Visits'
    }
}

export const getChartDataFromStatisticsForIndex = (index, data) => {
    if(!possibleIndex.includes(index)) return [];
    if(data.length === 0) return [];

    let labels = [];
    let dataset = {
        label: getLabelForIndex(index),
        data: [],
        borderColor: 'rgb(255 ,168 ,0)',
        backgroundColor: 'rgba(255,168,0)',
    }

    labels = data.map((item) => item.date);

    dataset.data = data.map((item) => {
        return {
            y: item[index],
            x: item.date
        }
    })

    return {
        labels: labels,
        datasets: [dataset]
    }
}

export const getChartDataFromStatisticsForIndexes = (indexes, data) => {
    if(data.length === 0) return [];
    let hasInvalidIndex = false;
    indexes.map((index) => {
        if(!possibleIndex.includes(index)) hasInvalidIndex = true;
    })
    if(hasInvalidIndex) return [];

    let labels = [];
    let datasets = [];

    labels = data.map((item) => item.date);

    indexes.map((index) => {
        const randomColor = getRandomColor();
        datasets.push(
            {
                label: getLabelForIndex(index),
                data: getChartDataOnlyForIndex(index, data),
                borderColor: randomColor,
                backgroundColor: randomColor,
            }
        )
    })

    return {
        labels: labels,
        datasets: datasets
    }
}

export const getChartDataOnlyForIndex = (index, data) => {
    if(!possibleIndex.includes(index)) return [];
    if(data.length === 0) return [];

    return data.map((item) => {
        return {
            y: item[index],
            x: item.date
        }
    });
}

export const getTotalSumForGivenIndex = (index, data) => {
    if(data.length === 0) return 0;
    if(!possibleIndex.includes(index)) return [];

    let total = 0;
    data?.map((item) => total += item[index]);
    return total;
}