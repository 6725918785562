import {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Link} from "react-router-dom";
import ConfirmDeleteModal from "../../../Components/Popups/ConfirmDeleteModal";
import toast from "react-hot-toast";
import Pagination from "../../../Components/General/Pagination";
import {deleteUser} from "../../../Api/usersApi";
import AuthContext from "../../../Helpers/AuthenticationHelper";
import {useBoundStore} from "../../../Store/store";

export default function UsersIndex() {
    const [isConfirmDeleteShown, setIsConfirmDeleteShown] = useState(false);
    const [deletingUserID, setDeletingUserID] = useState(null);
    const [page, setPage] = useState(1);
    const {user} = useContext(AuthContext);

    const users = useBoundStore((state) => state.users);
    const metaData = useBoundStore((state) => state.metaData);
    const fetchUsers = useBoundStore((state) => state.fetchUsers);

    useEffect(() => {
        fetchUsers(page).then(r => {});
    },[fetchUsers, page]);

    const changePage = async (newPage) => {
        setPage(newPage);
    }

    const handleCloseConfirmDelete = () => {
        setIsConfirmDeleteShown(false);
        setDeletingUserID(null);
    }

    const handleOpenConfirmDelete = (id) => {
        setIsConfirmDeleteShown(true);
        setDeletingUserID(id);
    }

    const deleteTagAction = async () => {
        if(deletingUserID === null) return false;

        await deleteUser(deletingUserID);
        fetchUsers(page);

        handleCloseConfirmDelete();

        toast.success('User has been successfully deleted',{
            position: "top-right"
        });
    }

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>Users</h1>
            </div>

            <div className="grid grid-cols-4 text-left mb-2 text-white bg-primary uppercase rounded-tr rounded-tl">
                <div className={"p-2"}>ID</div>
                <div className={"p-2"}>Name</div>
                <div className={"p-2"}>Email</div>
                <div className={"p-2"}>Actions</div>
            </div>

            {
                users.map((userLocal) => {
                    return (
                        <div key={`tags_${userLocal.id}`}>
                            <div className={"grid grid-cols-4 text-left border-b border-gray-500 border-opacity-30"} >
                                <Link to={`${userLocal.id}`}>
                                    <div className={"p-2"}>{userLocal.id}</div>
                                </Link>
                                <Link to={`${userLocal.id}`}>
                                    <div className={"p-2"}>{userLocal.firstname} {userLocal.lastname}</div>
                                </Link>
                                <Link to={`${userLocal.id}`}>
                                    <div className={"p-2"}>{userLocal.email}</div>
                                </Link>
                                <div className={"grid grid-cols-3 p-2"}>
                                    {
                                        userLocal.id !== user.user.id &&
                                        <div>
                                            <FontAwesomeIcon onClick={() => {handleOpenConfirmDelete(userLocal.id)}} icon={icon({name:"trash"})} className={"text-red-600 cursor-pointer"}/>
                                        </div>
                                    }

                                    <Link to={`${userLocal.id}`}>
                                        <div className={"text-right"}>
                                            <FontAwesomeIcon icon={icon({name:"angle-right"})} className={"cursor-pointer text-gray-500 text-right"}/>
                                        </div>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    )
                })
            }

            <div className={"w-full text-right mb-8 flex flex-row justify-end px-8"}>
                <Pagination metaData={{...metaData, current_page: page}} changePage={changePage} page={page}/>
            </div>

            <ConfirmDeleteModal
                isShown={isConfirmDeleteShown}
                closePopup={handleCloseConfirmDelete}
                clickConfirmed={deleteTagAction}
            ></ConfirmDeleteModal>
        </>
    )
}