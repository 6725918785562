import {useTranslation} from "react-i18next";

export default function TermsOfService() {
	const {t} = useTranslation();

	return (
        <>
			<div className={"flex flex-row relative bg-[#FFF8EC]"}>
				<div className={"text-left text-gray-700 p-8 mt-12 gap-8"}>

					<p className={"text-primary text-2xl pb-4"}><b><u>{t('legal.tos.title')}</u></b></p>

					<p>{t('legal.tos.body_1')}</p>
					<p>{t('legal.tos.body_2')}</p>
					<p>{t('legal.tos.body_3')}</p>
					<p>{t('legal.tos.body_4')}</p>
					<p>{t('legal.tos.body_5')}</p>

					<ol className={"m-auto pl-4 marker:text-primary mt-2 list-decimal"}>
						<li>{t('legal.tos.list_1_1')}</li>
						<li>{t('legal.tos.list_1_2')}</li>
						<li>{t('legal.tos.list_1_3')}</li>
						<li>{t('legal.tos.list_1_4')}</li>
						<li>{t('legal.tos.list_1_5')}</li>
						<li>{t('legal.tos.list_1_6')}</li>
						<li>{t('legal.tos.list_1_7')}</li>
						<li>{t('legal.tos.list_1_8')}</li>
						<li>{t('legal.tos.list_1_9')}</li>
						<li>{t('legal.tos.list_1_10')}</li>
						<li>{t('legal.tos.list_1_11')}</li>
						<li>{t('legal.tos.list_1_12')}</li>
						<li>{t('legal.tos.list_1_13')}</li>
						<li>{t('legal.tos.list_1_14')}</li>
					</ol>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.tos.title_6')}</h1>
					<p>{t('legal.tos.body_6_1')}</p>
					<p>{t('legal.tos.body_6_2')}</p>
					<p>{t('legal.tos.body_6_3')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.tos.title_7')}</h1>
					<p>{t('legal.tos.body_7_1')}</p>
					<p>{t('legal.tos.body_7_2')}</p>
					<p>{t('legal.tos.body_7_3')}</p>
					<p>{t('legal.tos.body_7_4')}</p>
					<p>{t('legal.tos.body_7_5')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.tos.title_8')}</h1>
					<p>{t('legal.tos.body_8_1')}</p>
					<p>{t('legal.tos.body_8_2')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.tos.title_9')}</h1>
					<p>{t('legal.tos.body_9_1')}</p>
					<p>{t('legal.tos.body_9_2')}</p>
					<p>{t('legal.tos.body_9_3')}</p>
					<p>{t('legal.tos.body_9_4')}</p>
					<p>{t('legal.tos.body_9_5')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.tos.title_10')}</h1>
					<p>{t('legal.tos.body_10_1')}</p>
					<p>{t('legal.tos.body_10_2')}</p>
					<p>{t('legal.tos.body_10_3')}</p>

					<ol className={"m-auto pl-4 marker:text-primary mt-2 list-decimal"}>
						<li>{t('legal.tos.list_10_1')}</li>
						<li>{t('legal.tos.list_10_2')}</li>
					</ol>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.tos.title_11')}</h1>
					<p>{t('legal.tos.body_11_1')}</p>
					<p>{t('legal.tos.body_11_2')}</p>
					<p>{t('legal.tos.body_11_3')}</p>
					<p>{t('legal.tos.body_11_4')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.tos.title_12')}</h1>
					<p>{t('legal.tos.body_12_1')}</p>
					<p>{t('legal.tos.body_12_2')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.tos.title_13')}</h1>
					<p>{t('legal.tos.body_13_1')}</p>
					<p>{t('legal.tos.body_13_2')}</p>
					<p>{t('legal.tos.body_13_3')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.tos.title_14')}</h1>
					<p>{t('legal.tos.body_14_1')}</p>
					<p>{t('legal.tos.body_14_2')}</p>
					<p>{t('legal.tos.body_14_3')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.tos.title_15')}</h1>
					<p>{t('legal.tos.body_15_1')}</p>
					<p>{t('legal.tos.body_15_2')}</p>
					<p>{t('legal.tos.body_15_3')}</p>
					<p>{t('legal.tos.body_15_3')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.tos.title_16')}</h1>
					<p>{t('legal.tos.body_16_1')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.tos.title_17')}</h1>
					<p>{t('legal.tos.body_17_1')}</p>
					<p>{t('legal.tos.body_17_2')}</p>
					<p>{t('legal.tos.body_17_3')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.tos.title_18')}</h1>
					<p>{t('legal.tos.body_18_1')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.tos.title_19')}</h1>
					<p>{t('legal.tos.body_19_1')}</p>
					<p>{t('legal.tos.body_19_2')}</p>
					<p>{t('legal.tos.body_19_3')}</p>
					<p>{t('legal.tos.body_19_4')}</p>
					<p>{t('legal.tos.body_19_5')}</p>
					<p>{t('legal.tos.body_19_6')}</p>

				</div>
			</div>
        </>
    );
}