import Footer from "../Components/General/Footer";
import BareHomeNavigation from "../Components/Navigation/BareHomeNavigation";
import LicenseTerm from "../Components/legal/LicenseTerm";

export default function LicenseTermPage() {
    return (
        <>
            <BareHomeNavigation />
            <LicenseTerm />
            <Footer />
        </>
    );
}