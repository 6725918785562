import React, {useContext, useEffect, useState} from 'react';
import EditorTopNavigation from "../Components/Navigation/EditorTopNavigation";
import EditorActionsNavigation from "../Components/Navigation/EditorActionsNavigation";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useBoundStore} from "../Store/store";
import {VideoBlock} from "../Components/Video_Editor/VideoBlock";
import AuthContext from "../Helpers/AuthenticationHelper";
import ContactInfoPopup from "../Components/Popups/ContactInfoPopup";
import toast from "react-hot-toast";

const Editor = () => {
  const [loading, setLoading] = useState(false);
  const [disabled] = useState(true);
  const { templateId } = useParams();
  const { user } = useContext(AuthContext);

  let [showLoading] = useState({});
  const [showContactInfoModal, setShowContactInfoModal] = useState(false);

  const templateDetails = useBoundStore((state) => state.templateDetails);
  const fetchTemplateDetails = useBoundStore((state) => state.fetchTemplateDetail);
  const videoTimings = useBoundStore((state) => state.videoTimings);
  const videos = useBoundStore((state) => state.videos);
  const renderVideo = useBoundStore((state) => state.renderVideo);

  useEffect(() => {
      fetchTemplateDetails(templateId)
          .then(r => {});
  },[fetchTemplateDetails]);


  const handleTranscode = async (data = null) => {
    try {
        // TODO: Make api call for starting render video

        if(user?.user?.id){
            //User is logged in continue to render and in API assign this result to this user.
            const params = {
                templateId: templateDetails.id,
                videoTimings: videoTimings.map((timing) => {
                    if(timing?.start !== null && timing.end !== null){
                        return [timing.start, timing.end]
                    }
                }).filter((elem) => elem !== null && elem !== undefined),
                videos: videos.filter((value) => value != null).map((vid) => vid.files.id)
            }

            renderVideo(params).then(() => {
                toast.success('Your render has been requested. Result will be send to you through an email');
            });
        } else {
            if(data === null) {
                setShowContactInfoModal(true);
                console.log('Data did not pass correctly to transcode function')
                return false;
            } else {
                setShowContactInfoModal(false);
                const params = {
                    contactInformation: {
                        firstname: data.firstname,
                        lastname: data.lastname,
                        email: data.email,
                    },
                    templateId: templateDetails.id,
                    videoTimings: videoTimings.map((timing) => {
                        if(timing?.start !== null && timing.end !== null){
                            return [timing.start, timing.end]
                        }
                    }).filter((elem) => elem !== null && elem !== undefined),
                    videos: videos.filter((value) => value != null).map((vid) => vid.files.id)
                }

                renderVideo(params).then(() => {
                    toast.success('Your render has been requested. Result will be send to you through an email');
                });
                console.log('Starting download with basic information from user');
            }
        }
    } catch (err){
      console.log(err)
    }
  }

  return (
    <div className={'bg-secondary'}>
        <Helmet>
            <meta
                name="description"
                content="Make great memories, treasure them forever"
            />
        </Helmet>
        <div id={"loading_screen_editor"} className={`${showLoading?.status ? '' : 'hidden'} absolute z-50 bg-secondary h-screen w-screen overflow-hidden top-0 left-0 flex flex-col justify-center items-center`}>
            <div className={""}>
                <img src={'/img/loading.svg'} className={"m-auto"} alt={"Loading animation"}/>
                <p className={"text-white text-xl"}>{showLoading?.message}</p>
            </div>
        </div>
    <div className={"sticky top-0 left-0"} style={{zIndex: 9999}}>
        <EditorTopNavigation/>
        <div className={"mb-4"}>
            <EditorActionsNavigation
                startVideoDownload={handleTranscode}
                disabled={disabled}
                isLoading={loading}
            />
        </div>
    </div>

      <div>
      {
        templateDetails?.jsonConfig?.clips?.length > 1 && (
          <div className={"grid grid-cols-1 md:grid-cols-3 space-4 p-4"}>
              {
                  templateDetails.jsonConfig.clips.map((clip, index) => {
                      return (
                          <div className={"m-2"} key={`video_block_${index}`}>
                              <VideoBlock index={index} videoLength={clip} />
                          </div>
                      )
                  })
              }
          </div>
        )
      }
      </div>


        <ContactInfoPopup
            isShown={showContactInfoModal}
            closePopup={() => setShowContactInfoModal(false)}
            clickConfirmed={handleTranscode}
        />
    </div>
  );
}

export default Editor;