import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPause, faPlay, faRotateLeft, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useRef, useState} from "react";

export const PresetVideoLoaded = (props) => {
    const {
        fileUrl,
        classes = '',
        buttonclasses = '',
        heightClass = '',
    } = props;

    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    const playVideo = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    }

    const resetVideo = () => {
        videoRef.current.currentTime = 0;
        videoRef.current.pause();
        setIsPlaying(false);
    }

    const getButtonClass = () => {
        if(buttonclasses !== ''){
            return 'w-8 h-8 rounded bg-gray-400 flex flex-col justify-center m-1 cursor-pointer'
        } else {
            return `w-8 h-8 rounded flex flex-col justify-center m-1 cursor-pointer ${buttonclasses}`;
        }
    }

    return (
        <div className={`w-full relative rounded-xl flex flex-col justify-center m-2 ${classes}`}>
            <video ref={videoRef} muted={true} className={`rounded-md h-full ${heightClass}`}>
                <source src={`${fileUrl}#t=0.001`} type="video/mp4"/>
            </video>

            <div className={"flex flex-row justify-center"}>
                <div className={buttonclasses} onClick={playVideo}>
                    {isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
                </div>
                <div className={buttonclasses} onClick={resetVideo}>
                    <FontAwesomeIcon icon={faRotateLeft} />
                </div>
            </div>
        </div>
    )
}