import {useContext, useState} from "react";
import LoginPopup from "../Popups/LoginPopup";
import AuthContext from '../../Helpers/AuthenticationHelper';
import { useTranslation } from 'react-i18next';
import {LocaleSwitcher} from "./LocaleSwitcher";

export default function HomeNavigation({searchValue, setSearchValue}) {
    const [showPopup, setShowPopup] = useState(false);
    const [heightMobileMenu, setHeightMobileMenu] = useState(0);
    const [opacityMobileMenu, setOpacityMobileMenu] = useState(0);
    const { user, logout } = useContext(AuthContext)
    const { t } = useTranslation();

    const handleLoginClick = () => {
        if(user?.user === undefined) {
            setShowPopup(true);
        }
    }

    const handleClosePopup = () => {
        setShowPopup(false);
    }

    const setVisibilityMobileMenu = () => {
        if(heightMobileMenu === 0) {
            setHeightMobileMenu('150px')
            setOpacityMobileMenu(1)
        } else {
            setHeightMobileMenu(0)
            setOpacityMobileMenu(0)
        }
    }

    const getBackgroundClass = () => {
        return opacityMobileMenu === 0 ? 'flex' : 'block';
    }

    const logoutUser = () => {
        logout();
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setSearchValue(event.target.value);
        }
    };

    const handleValueChanged = (event) => {
        if(event.target.value === "") {
            setSearchValue("");
        }
    }

    return (
        <>
            <div className="px-6 py-4 bg-primary">
                <nav className="navbar navbar-expand-lg ">
                    <div className="flex flex-row justify-between">
                        <div>
                            <a href="/" >
                                <img
                                    src="/img/nav-logo.png"
                                    className="navbar-brand navbar_logo"
                                    alt="random"
                                />
                            </a>
                        </div>

                        <div className="basis-1/2 hidden md:block">
                            <div className="flex flex-row text-white h-full">
                                <div className="w-full bg-input flex flex-row m-auto rounded-md h-full justify-around">
                                    <img
                                        src="\img\akar-icons_search.png"
                                        className="m-auto ml-2"
                                        alt="\img\akar-icons_search.png"
                                        style={{ width: "24px", height: "24px" }}
                                    />
                                    <input
                                        className="text-white w-full ml-4 rounded-md bg-transparent placeholder:text-white outline-0"
                                        type="search"
                                        id="searchValue"
                                        defaultValue={searchValue}
                                        name="searchValue"
                                        onKeyDown={handleKeyDown}
                                        onChange={handleValueChanged}
                                        aria-label="Search"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={"hidden md:block"}>
                            <div className="flex flex-col justify-center h-full">
                                <div className={"flex flex-row"}>
                                    <LocaleSwitcher />


                                    <div onClick={handleLoginClick} className="m-auto text-white cursor-pointer group ml-2">
                                        {
                                            user?.user != null &&
                                            <>
                                                <p>{user.user.firstname}</p>
                                                <div className="hidden group-hover:block
                                                                 absolute right-[21px] z-20
                                                                 flex-col bg-brandblue
                                                                 drop-shadow-lg"
                                                >
                                                    <p className="px-5 py-3" onClick={logoutUser}>{t('authentication.logout')}</p>
                                                </div>
                                            </>
                                        }
                                        {
                                            user?.user == null &&
                                            <p>{t('authentication.sign_in')}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"md:hidden"}>
                            <div className="p-4 space-y-2 border border-white rounded shadow cursor-pointer" onClick={setVisibilityMobileMenu}>
                                <span className="block w-8 h-0.5 bg-gray-100"></span>
                                <span className="block w-8 h-0.5 bg-gray-100"></span>
                                <span className="block w-8 h-0.5 bg-gray-100"></span>
                            </div>
                        </div>

                    </div>

                    <div style={{height: heightMobileMenu, opacity: opacityMobileMenu}} className={"transition-all transition transform w-full bg-primary transition-[height] duration-500 ease-in-out " + getBackgroundClass()}>
                        <div className="basis-1/2 mt-6">
                            <div className="flex flex-row text-white h-full">
                                <div className="w-full bg-input flex flex-row m-auto rounded-md h-full justify-around p-2">
                                    <img
                                        src="\img\akar-icons_search.png"
                                        className="m-auto ml-2"
                                        alt="\img\akar-icons_search.png"
                                        style={{ width: "24px", height: "24px" }}
                                    />
                                    <input
                                        className="text-white w-full ml-4 rounded-md bg-transparent placeholder:text-white outline-0"
                                        type={"search"}
                                        id="searchValue"
                                        name="searchValue"
                                        onKeyDown={handleKeyDown}
                                        aria-label="Search"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col justify-center mt-4">
                            <div onClick={handleLoginClick} className="m-auto text-white cursor-pointer">
                                <div onClick={handleLoginClick} className="m-auto text-white cursor-pointer group">
                                    {
                                        user?.user != null &&
                                        <>
                                            <p>{user.user.firstname}</p>
                                            <div className="hidden group-hover:block
                                                                 absolute right-[21px] z-20
                                                                 flex-col bg-brandblue
                                                                 drop-shadow-lg"
                                            >
                                                <p className="px-5 py-3" onClick={logoutUser}>{t('authentication.logout')}</p>
                                            </div>
                                        </>
                                    }
                                    {
                                        user?.user == null &&
                                        <p>{t('authentication.login_button')}</p>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={"mt-4"}>
                            <LocaleSwitcher/>
                        </div>

                    </div>
                </nav>
            </div>

            <LoginPopup
                isShown={showPopup}
                closePopup={handleClosePopup}
            ></LoginPopup>
        </>
    )
}