import {getAllSubCategories, getAllSubCategoriesPaginated} from "../../Api/subCategoriesApi";

export const createSubcategorySlice = (set) => ({
    subcategories: [],
    subcategoryMetaData: [],
    fetchSubcategories: async () => {
        const subcategories = await getAllSubCategories();
        set({subcategories: subcategories});
    },
    fetchSubcategoriesPaginated: async (page = 1) => {
        const subcategories = await getAllSubCategoriesPaginated(page);
        set({subcategories: subcategories.data, subcategoryMetaData: subcategories.meta});
    }
});