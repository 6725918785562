import Footer from "../Components/General/Footer";
import BareHomeNavigation from "../Components/Navigation/BareHomeNavigation";
import CookiePolicy from "../Components/legal/CookiePolicy";

export default function CookiePolicyPage() {
    return (
        <>
            <BareHomeNavigation />
            <CookiePolicy />
            <Footer />
        </>
    );
}