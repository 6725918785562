import {useEffect, useState} from "react";
import {getCategory} from "../../../Api/categoriesApi";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {getSubCategory} from "../../../Api/subCategoriesApi";

export default function SubcategoryDetail() {
    const [subcategory, setSubcategory] = useState();
    const [category, setCategory] = useState();
    const { id } = useParams()

    useEffect(() => {
        (async function() {
            const result = await getSubCategory(id)
            setCategory(await getCategory(result.category_id));
            setSubcategory(result);
        })()
    }, [id])

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>Subcategory detail</h1>
                <Link to={'/admin/subcategories'} className={"py-2 px-4 ease-in-out duration-300 text-secondary hover:text-primary cursor-pointer transition-all"}>
                    <FontAwesomeIcon icon={icon({name: 'arrow-left'})} className={"mr-2"}/>
                    Back to subcategories
                </Link>
            </div>

            <div className={"grid grid-cols-3"}>
                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>ID</p>
                    <p className={"text-left"}>{subcategory?.id}</p>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>NAME</p>
                    <p className={"text-left"}>{subcategory?.name}</p>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>CATEGORY</p>
                    <p className={"text-left"}>{category?.name}</p>
                </div>
            </div>
        </>
    )
}