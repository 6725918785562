import {useEffect, useState} from "react";
import {deleteCategory, getAllCategoriesPaginated} from "../../../Api/categoriesApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Link} from "react-router-dom";
import ConfirmDeleteModal from "../../../Components/Popups/ConfirmDeleteModal";
import toast from "react-hot-toast";
import Pagination from "../../../Components/General/Pagination";
import {useBoundStore} from "../../../Store/store";

export default function CategoryIndex() {
    const [isConfirmDeleteShown, setIsConfirmDeleteShown] = useState(false);
    const [deletingCategoryID, setDeletingCategoryID] = useState(null);
    const [page, setPage] = useState(1);

    const categories = useBoundStore((state) => state.categories);
    const metaData = useBoundStore((state) => state.categoriesMetaData);
    const fetchCategoriesPaginated = useBoundStore((state) => state.fetchCategoriesPaginated);

    useEffect(() => {
        fetchCategoriesPaginated(page).then(r => {});
    },[fetchCategoriesPaginated, page]);

    const changePage = async (newPage) => {
        setPage(newPage);
    }

    const handleCloseConfirmDelete = () => {
        setIsConfirmDeleteShown(false);
        setDeletingCategoryID(null);
    }

    const handleOpenConfirmDelete = (id) => {
        setIsConfirmDeleteShown(true);
        setDeletingCategoryID(id);
    }

    const deleteCategoryAction = async () => {
        if(deletingCategoryID === null) return false;

        await deleteCategory(deletingCategoryID);
        fetchCategoriesPaginated(page).then(() => {});

        handleCloseConfirmDelete();

        toast.success('Category has been successfully deleted',{
            position: "top-right"
        });
    }

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>Categories</h1>
                <Link to={'/admin/categories/create'} className={"py-2 px-4 ease-in-out duration-300 bg-secondary text-white cursor-pointer rounded-lg border border-transparent transition-all hover:bg-transparent hover:text-secondary hover:border hover:border-secondary"}>Create a new Category</Link>
            </div>

            <div className="grid grid-cols-3 text-left mb-2 text-white bg-primary uppercase rounded-tr rounded-tl">
                <div className={"p-2"}>ID</div>
                <div className={"p-2"}>Name</div>
                <div className={"p-2"}>Actions</div>
            </div>

            {
                categories.map((category) => {
                    return (
                        <div key={`categories_${category.id}`}>
                            <div className={"grid grid-cols-3 text-left border-b border-gray-500 border-opacity-30"} key={category.id}>
                                <Link to={`${category.id}`}>
                                    <div className={"p-2"}>{category.id}</div>
                                </Link>
                                <Link to={`${category.id}`}>
                                    <div className={"p-2"}>{category.name}</div>
                                </Link>
                                <div className={"grid grid-cols-3 p-2"}>
                                    <div>
                                        <FontAwesomeIcon onClick={() => {handleOpenConfirmDelete(category.id)}} icon={icon({name:"trash"})} className={"text-red-600 cursor-pointer"}/>
                                    </div>
                                    <Link to={`${category.id}/update`}>
                                        <div className={"text-right"}>
                                            <FontAwesomeIcon icon={icon({name:"edit"})} className={"cursor-pointer text-gray-500 text-right"}/>
                                        </div>
                                    </Link>

                                    <Link to={`${category.id}`}>
                                        <div className={"text-right"}>
                                            <FontAwesomeIcon icon={icon({name:"angle-right"})} className={"cursor-pointer text-gray-500 text-right"}/>
                                        </div>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    )
                })
            }

            <div className={"w-full text-right mb-8 flex flex-row justify-end px-8"}>
                <Pagination metaData={{...metaData, current_page: page}} changePage={changePage} page={page}/>
            </div>

            <ConfirmDeleteModal
                isShown={isConfirmDeleteShown}
                closePopup={handleCloseConfirmDelete}
                clickConfirmed={deleteCategoryAction}
            ></ConfirmDeleteModal>
        </>
    )
}