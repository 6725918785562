import AdminLayout from "../Layouts/AdminLayout";
import { Outlet } from "react-router-dom";

export default function Home(){
    return (
        <>
            <AdminLayout>
                <Outlet/>
            </AdminLayout>
        </>
    )
}