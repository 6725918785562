import React, {useContext, useRef} from "react";
import {useBoundStore} from "../../../Store/store";
import AuthContext from "../../../Helpers/AuthenticationHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";

export const VideoUpload = (props) => {
    const {
        index,
        clipLength = null,
        showIndexNumber,
        backgroundColor,
        icon,
        showSupportTextClickUpload,
        innerTextColor,
        blockBorder,
        roundedBorder,
        companyId
    } = props;
    const {user} = useContext(AuthContext);

    const fileUpload = useRef(null)
    const uploadVideo = useBoundStore((state) => state.uploadVideo);

    const openFileHandle = () => {
        fileUpload.current.click();
    }

    const onFileChangeCapture = (e) => {
        if(e.target.files.length === 0) return;

        const file = e.target.files[0];

        let reader = new FileReader();
        reader.onload = function() {
            var aud = new Audio(reader.result);
            aud.onloadedmetadata = function(){
                const duration = aud.duration * 1000;

                if(duration > clipLength) {
                    uploadVideo(e.target.files[0], index, clipLength, user, companyId).then((res) => {});
                } else {
                    toast.error(`Your video is not long enough to fit in here. It should be at least ${Math.ceil(clipLength / 1000)} seconds.`, {
                        duration: 15000
                    })
                }
            };
        };
        reader.readAsDataURL(file);

    }

    return (
        <div className={`w-full relative bg-brandblue ${roundedBorder ? 'rounded-xl' : ''} flex flex-col justify-center min-h-[150px] h-full m-2 cursor-pointer`} style={{backgroundColor:backgroundColor, border:blockBorder}} onClick={openFileHandle}>
            <input type={"file"} className={"hidden"} ref={fileUpload} accept="video/*" onChangeCapture={onFileChangeCapture}/>

            {
                showIndexNumber && (
                    <div className={"absolute bg-secondary h-8 w-8 rounded-full -right-2 -top-3 flex flex-col justify-center items-center"}>
                        <div className={"bg-[#025182] rounded-full w-6 h-6 text-white text-sm m-auto flex flex-col justify-center items-center"}>{index +1}</div>
                    </div>
                )
            }

            {
                showSupportTextClickUpload && (
                    <div className={"text-2xl mx-auto mb-2 select-none"} style={{ color:innerTextColor }}>
                        Click here to upload
                    </div>
                )
            }

            <div className={"text-5xl mx-auto select-none"} style={{ color:innerTextColor }}>
                <FontAwesomeIcon icon={icon} />
            </div>
        </div>
    )
}