import {AuthClient} from "./HttpClient";

export function getAllB2bCompaniesPaginated(page = 1) {
    return AuthClient()
        .get(`b2b-companies?page=${page}`).then((res) => {
            return res.data
        });
}
export function fetchB2bCompanyTemplatesAPI(companyId) {
    return AuthClient()
        .get(`/b2b-companies/${companyId}/templates`).then((res) => {
            return res.data
        });
}