import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {axiosClient} from "../../Api/HttpClient";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";

export default function ContactInfoPopup(props) {
    const {isShown, closePopup, clickConfirmed} = props;

    const {t} = useTranslation();

    const handleCloseClick = () => {
        closePopup();
    }

    const validationSchema = yup.object({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string().email().required(),
        optin: yup.bool().nullable(),
    }).required();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const handleSubmitData = (data) => {
        clickConfirmed(data);
    }

    return (
        <>
            { isShown &&
                <>
                    <div className={"h-screen w-screen bg-gray-800 fixed top-0 left-0 bg-opacity-60"} style={{zIndex:60}}></div>
                    <div tabIndex="-1" aria-hidden={!isShown} aria-modal={true} role={"dialog"} aria-labelledby={"ConfirmDeleteModal"}
                         className={"transition-all ease-in-out overflow-y-auto overflow-x-hidden flex flex-col justify-center fixed top-0 right-0 left-0 w-full md:inset-0 md:h-full" + (!isShown ? 'hidden' : '')} style={{zIndex:70}}>
                        <div className="relative p-4 w-full max-w-lg m-auto">
                            <form className="relative bg-white rounded-lg shadow dark:bg-gray-700" onSubmit={handleSubmit(handleSubmitData)}>

                                <div className="flex flex-row justify-between bg-primary py-3 px-4 border-b border-white rounded-tl-lg rounded-tr-lg">
                                    <h3 className="text-xl font-medium text-white my-auto uppercase">Information</h3>
                                    <button type="button" onClick={handleCloseClick}
                                            className="text-white bg-transparent hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule={"evenodd"} d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule={"evenodd"}></path>
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>


                                    <div className="py-6 px-6 lg:px-8 bg-secondary text-white">
                                        <div className={"max-w-xs mx-auto mb-4"}>
                                            <p>Before your download can start we need some information to keep you posted about your result. </p>
                                            <p>Once you submit this form you will receive an email containing a link to your personal result!</p>
                                        </div>

                                        <div className={"flex flex-row mb-2"}>
                                            <div className={"w-full flex flex-col mx-1"}>
                                                <label htmlFor={"firstname"}>{t("mailchimp_popup.firstname")}</label>
                                                <input
                                                    type={"text"}
                                                    id={"firstname"}
                                                    name={"firstname"}
                                                    className={"text-sm text-white w-full p-2 bg-gray-800 bg-opacity-40 rounded border border-gray-600"}
                                                    {...register('firstname')}
                                                />

                                                {errors?.firstname && <p className={"text-red-600 text-sm"}>{errors.firstname.message}</p>}
                                            </div>

                                            <div className={"w-full flex flex-col mx-1"}>
                                                <label htmlFor={"lastname"}>{t("mailchimp_popup.lastname")}</label>
                                                <input
                                                    type={"text"}
                                                    name={"lastname"}
                                                    id={"lastname"}
                                                    className={"text-sm text-white w-full p-2 bg-gray-800 bg-opacity-40 rounded border border-gray-600"}
                                                    {...register('lastname')}
                                                />
                                                {errors?.lastname && <p className={"text-red-600 text-sm"}>{errors.lastname.message}</p>}
                                            </div>
                                        </div>

                                        <div className={"w-full flex flex-col mx-1 mb-6"}>
                                            <label htmlFor={"email"}>{t("mailchimp_popup.email")}</label>
                                            <input
                                                type={"email"}
                                                name={"email"}
                                                id={"email"}
                                                className={"text-sm text-white w-full p-2 bg-gray-800 bg-opacity-40 rounded border border-gray-600"}
                                                {...register('email')}
                                            />
                                            {errors?.email && <p className={"text-red-600 text-sm"}>{errors.email.message}</p>}
                                        </div>

                                        <div className={"w-full flex flex-col mx-1 mb-6"}>
                                            <label htmlFor={"optin"} className={"grid grid-cols-2 cursor-pointer"} style={{gridTemplateColumns:'1fr 50px'}}>
                                                <span className={'text-sm'}>{t("mailchimp_popup.optin_text")}</span>
                                                <input
                                                    type={"checkbox"}
                                                    name={"optin"}
                                                    id={"optin"}
                                                    className={"text-xs h-5 m-auto text-white w-full p-2 bg-gray-800 bg-opacity-40 rounded border border-gray-600"}
                                                    {...register('optin')}
                                                />
                                            </label>
                                        </div>

                                        <div className={"flex flex-row justify-between"}>
                                            <input
                                                type={"submit"}
                                                className={"w-full text-center select-none rounded bg-green-600 border border-transparent px-4 py-2 text-white hover:text-green-600 hover:bg-transparent hover:border hover:border-green-600 cursor-pointer transition-all ease-in-out duration-300"}
                                                value={t("mailchimp_popup.download_video_button")}
                                            />
                                        </div>
                                    </div>
                            </form>
                        </div>
                    </div>
                </>
            }
        </>
    )
}