import {fetchB2bCompanyTemplatesAPI, getAllB2bCompaniesPaginated} from "../../Api/b2bCompanies";

export const createB2bCompaniesSlice = (set, get) => ({
    b2bcompanies: [],
    b2bCompanyTemplates: [],
    b2bCompaniesMetaData: [],
    fetchB2bCompanies: async (page = 1) => {
        const companies = await getAllB2bCompaniesPaginated(page);
        set({b2bcompanies: companies.data, b2bCompaniesMetaData: companies.meta});
    },
    fetchB2bCompanyTemplates: async (companyId) => {
        const templateData = await fetchB2bCompanyTemplatesAPI(companyId);
        set({b2bCompanyTemplates: templateData});
    },
    getB2bCompany: (companyId) => {
        return get().b2bcompanies.find((company) => {
            return company.id === parseInt(companyId)
        });
    },
});