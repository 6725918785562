import {useTranslation} from "react-i18next";

export default function LicenseTerm() {
	const {t} = useTranslation();

	return (
        <>
			<div className={"flex flex-row relative"}>
				<div className={"text-left text-white p-8 mt-12 gap-8"}>

					<p className={"text-primary text-2xl pb-4"}><b><u>{t('legal.license_term.title')}</u></b></p>

					<p>{t('legal.license_term.body_1')}</p>
					<p>{t('legal.license_term.body_2')}</p>
					<p>{t('legal.license_term.body_3')}</p>
					<p>{t('legal.license_term.body_4')}</p>
					<p>{t('legal.license_term.body_5')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.license_term.title_6')}</h1>
					<ol className={"m-auto pl-4 marker:text-primary mt-2 list-decimal"}>
						<li>{t('legal.license_term.list_6_1')}</li>
						<li>{t('legal.license_term.list_6_2')}</li>
					</ol>
					<p>{t('legal.license_term.body_6')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.license_term.title_7')}</h1>
					<p>{t('legal.license_term.body_7')}</p>
					<ol className={"m-auto pl-4 marker:text-primary mt-2 list-decimal"}>
						<li>{t('legal.license_term.list_7_1')}</li>
						<li>{t('legal.license_term.list_7_2')}</li>
						<li>{t('legal.license_term.list_7_3')}</li>
						<li>{t('legal.license_term.list_7_4')}</li>
						<li>{t('legal.license_term.list_7_5')}</li>
					</ol>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.license_term.title_8')}</h1>
					<p>{t('legal.license_term.body_8')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.license_term.title_9')}</h1>
					<p>{t('legal.license_term.body_9')}</p>
				</div>
			</div>
        </>
    );
}