import Button from "../Forms/Button";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";

export default function PresentingSpeedModal(props) {
    const {isShown, closePopup} = props;
    const {t} = useTranslation();

    const handleCloseClick = () => {
        closePopup(null);
    }

    const validationSchema = yup.object({
        speed: yup.number().required().min(1).max(60),
    }).required();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const handleSubmitData = (data) => {
        closePopup(data.speed);
    }

    return (
        <>
            { isShown &&
                <>
                    <div className={"h-screen z-50 w-screen bg-gray-800 fixed top-0 left-0 bg-opacity-60"}></div>
                    <div tabIndex="-1" aria-hidden={!isShown} aria-modal={true} role={"dialog"} aria-labelledby={"PresentingSpeedModal"}
                         className={"transition-all ease-in-out overflow-y-auto overflow-x-hidden flex flex-col justify-center fixed top-0 right-0 left-0 z-50 w-full md:inset-0 md:h-full" + (!isShown ? 'hidden' : '')}>
                        <div className="relative p-4 w-full max-w-lg m-auto">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                                <div className="flex flex-row justify-between bg-primary py-3 px-4 border-b border-white rounded-tl-lg rounded-tr-lg">
                                    <h3 className="text-xl font-medium text-white my-auto">{t('qr.speed_settings_title')}</h3>
                                    <button type="button" onClick={handleCloseClick}
                                            className="text-white bg-transparent hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule={"evenodd"} d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule={"evenodd"}></path>
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>


                                <div className="py-6 px-6 lg:px-8 bg-[#FFF8EC] text-gray-600">

                                    <form onSubmit={handleSubmit(handleSubmitData)}>
                                        {errors.length > 0 &&
                                            <>
                                                <div className={"w-full bg-red-500 border border-red-900 mb-2"}>
                                                    <p className={"m-auto py-2 text-red-900"}>{errors[0].message}</p>
                                                </div>
                                            </>
                                        }

                                        <p className={"mb-4 text-left"}>{t('qr.speed_settings_explanation')}</p>

                                        <div className={'my-4'}>
                                            <label htmlFor="speed"
                                                   className="block mb-2 text-sm font-medium text-gray-400 text-left">{t('qr.speed_in_seconds')}</label>
                                            <input type="text" {...register("speed")}
                                                   className=" text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            />

                                            {errors?.speed && <p className={"text-red-600 text-sm"}>{errors.speed.message}</p>}
                                        </div>

                                        <div className={"grid grid-cols-2 space-x-4"}>
                                            <Button
                                                isPrimary={false}
                                                buttonText={t('qr.cancel_button')}
                                                clickEvent={handleCloseClick}
                                            >
                                            </Button>

                                            <Button
                                                isPrimary={true}
                                                buttonText={t('qr.start_presenting_button')}
                                                clickEvent={handleSubmit(handleSubmitData)}
                                            >
                                            </Button>
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}