import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Link} from "react-router-dom";
import ConfirmDeleteModal from "../../../Components/Popups/ConfirmDeleteModal";
import toast from "react-hot-toast";
import Pagination from "../../../Components/General/Pagination";
import {deleteSubCategory} from "../../../Api/subCategoriesApi";
import {useBoundStore} from "../../../Store/store";

export default function SubcategoryIndex() {
    const [isConfirmDeleteShown, setIsConfirmDeleteShown] = useState(false);
    const [deletingSubcategoryID, setDeletingSubcategoryID] = useState(null);
    const [page, setPage] = useState(1);

    const subcategories = useBoundStore((state) => state.subcategories);
    const categories = useBoundStore((state) => state.categories);
    const metaData = useBoundStore((state) => state.subcategoryMetaData);
    const fetchSubcategoriesPaginated = useBoundStore((state) => state.fetchSubcategoriesPaginated);
    const fetchCategories = useBoundStore((state) => state.fetchCategories);

    useEffect(() => {
        fetchSubcategoriesPaginated(page).then(r => {});
        fetchCategories().then(() => {});
    }, [fetchSubcategoriesPaginated, page])

    const changePage = async (newPage) => {
        setPage(newPage);
    }

    const handleCloseConfirmDelete = () => {
        setIsConfirmDeleteShown(false);
        setDeletingSubcategoryID(null);
    }

    const handleOpenConfirmDelete = (id) => {
        setIsConfirmDeleteShown(true);
        setDeletingSubcategoryID(id);
    }

    const deleteCategoryAction = async () => {
        if(deletingSubcategoryID === null) return false;

        await deleteSubCategory(deletingSubcategoryID);
        fetchSubcategoriesPaginated(page);
        fetchCategories();

        handleCloseConfirmDelete();

        toast.success('Subcategory has been successfully deleted',{
            position: "top-right"
        });
    }

    const getCategoryNameById = (categoryId) => {
        const categoryFound = categories.find((category) => categoryId === category.id);
        return categoryFound?.name ?? '';
    }

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>Subcategories</h1>
                <Link to={'/admin/subcategories/create'} className={"py-2 px-4 ease-in-out duration-300 bg-secondary text-white cursor-pointer rounded-lg border border-transparent transition-all hover:bg-transparent hover:text-secondary hover:border hover:border-secondary"}>Create a new Subcategory</Link>
            </div>

            <div className="grid grid-cols-4 text-left mb-2 text-white bg-primary uppercase rounded-tr rounded-tl">
                <div className={"p-2"}>ID</div>
                <div className={"p-2"}>Category</div>
                <div className={"p-2"}>Name</div>
                <div className={"p-2"}>Actions</div>
            </div>

            {
                subcategories.map((subcategory) => {
                    return (
                        <div key={`subcategories_${subcategory.id}`}>
                            <div className={"grid grid-cols-4 text-left border-b border-gray-500 border-opacity-30"} key={subcategory.id}>
                                <Link to={`${subcategory.id}`}>
                                    <div className={"p-2"}>{subcategory.id}</div>
                                </Link>
                                <Link to={`${subcategory.id}`}>
                                    <div className={"p-2"}>{getCategoryNameById(subcategory.category_id)}</div>
                                </Link>
                                <Link to={`${subcategory.id}`}>
                                    <div className={"p-2"}>{subcategory.name}</div>
                                </Link>
                                <div className={"grid grid-cols-3 p-2"}>
                                    <div>
                                        <FontAwesomeIcon onClick={() => {handleOpenConfirmDelete(subcategory.id)}} icon={icon({name:"trash"})} className={"text-red-600 cursor-pointer"}/>
                                    </div>
                                    <Link to={`${subcategory.id}/update`}>
                                        <div className={"text-right"}>
                                            <FontAwesomeIcon icon={icon({name:"edit"})} className={"cursor-pointer text-gray-500 text-right"}/>
                                        </div>
                                    </Link>

                                    <Link to={`${subcategory.id}`}>
                                        <div className={"text-right"}>
                                            <FontAwesomeIcon icon={icon({name:"angle-right"})} className={"cursor-pointer text-gray-500 text-right"}/>
                                        </div>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    )
                })
            }

            <div className={"w-full text-right mb-8 flex flex-row justify-end px-8"}>
                <Pagination metaData={{...metaData, current_page: page}} changePage={changePage} page={page}/>
            </div>

            <ConfirmDeleteModal
                isShown={isConfirmDeleteShown}
                closePopup={handleCloseConfirmDelete}
                clickConfirmed={deleteCategoryAction}
            ></ConfirmDeleteModal>
        </>
    )
}