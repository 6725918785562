import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import Button from "../../../Components/Forms/Button";
import toast from 'react-hot-toast';
import {getTemplate, updateTemplate} from "../../../Api/templatesApi";
import {getAllSubCategories} from "../../../Api/subCategoriesApi";

export default function TemplateUpdate() {
    const [template, setTemplate] = useState();
    const [subcategories, setSubcategories] = useState();
    const [showAllSubcategories, setShowAllSubcategories] = useState(false);
    const [filteredSubcategories, setFilteredSubcategories] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        (async function() {
            await setTemplate(await getTemplate(id));
            await setSubcategories(await getAllSubCategories());
        })()
    }, [id])

    useEffect(() => {
        setFilteredSubcategories(filterAllSubcategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subcategories])

    const save = async () => {
        const currentTemplate = template;


        if(currentTemplate.subcategories !== undefined){
            currentTemplate.subcategories = currentTemplate?.subcategories.map((subcategory) => {
                return subcategory.id;
            });
        }

        while (typeof currentTemplate.jsonConfig !== 'object') {
            currentTemplate.jsonConfig = JSON.parse(currentTemplate.jsonConfig);
        }
        currentTemplate.jsonConfig = JSON.stringify(currentTemplate.jsonConfig);

        const response = await updateTemplate(template.id, currentTemplate);
        await setTemplate(response);
        setFilteredSubcategories(filterAllSubcategories());

        toast.success('Template has been successfully updated',{
            position: "top-right"
        });
    }

    const toggleSubcategories = () => {
        setShowAllSubcategories(!showAllSubcategories);
    }

    const addSubcategoryToTemplate = (subcategory) => {
        const currentTemplate = template;

        const duplicate = currentTemplate?.subcategories.filter((subc) => {
            return subc.id === subcategory.id;
        });
        if(duplicate.length > 0) return;

        currentTemplate?.subcategories.push(subcategory);
        setTemplate(currentTemplate);
        setFilteredSubcategories(filterAllSubcategories());
    }

    const filterAllSubcategories = () => {
        return subcategories?.filter((subcategory) => {
            if(template?.subcategories === [] || template?.subcategories === null || template?.subcategories === undefined) return true;

            const allSubcategoryIds = template?.subcategories.map((subcategory) => {return subcategory.id});
            if(!allSubcategoryIds.includes(subcategory.id)) return true;
            return false
        }) ?? subcategories;
    }

    const removeSubcategoryFromTemplate = (id) => {
        const currentTemplate = template;
        currentTemplate.subcategories = currentTemplate.subcategories.filter((subcategory) => {
            if(subcategory.id === id) return false;
            return true;
        })
        setTemplate(currentTemplate);
        setFilteredSubcategories(filterAllSubcategories());
    }

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>Template update</h1>
                <Link to={'/admin/templates'} className={"py-2 px-4 ease-in-out duration-300 text-secondary hover:text-primary cursor-pointer transition-all"}>
                    <FontAwesomeIcon icon={icon({name: 'arrow-left'})} className={"mr-2"}/>
                    Back to templates
                </Link>
            </div>

            <div>
                <div className={"text-left mb-2"}>
                    <label htmlFor={"name"} className={"text-left pl-4 font-bold"}>Title</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        defaultValue={template?.title}
                        onChange={(e) => {
                            template.title = e.target.value;
                        }}
                        id={"title"}
                        name={"title"}
                        type={"text"}
                        required
                    />
                </div>

                <div className={"text-left mb-2"}>
                    <label htmlFor={"name"} className={"text-left pl-4 font-bold"}>Song title</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        defaultValue={template?.songTitle}
                        onChange={(e) => {
                            template.songTitle = e.target.value;
                        }}
                        id={"songTitle"}
                        name={"songTitle"}
                        type={"text"}
                        required
                    />
                </div>

                <div className={"text-left mb-2"}>
                    <label htmlFor={"name"} className={"text-left pl-4 font-bold"}>Song file</label>
                    <div className={"flex flex-row justify-evenly"}>
                        <input
                            className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                            defaultValue={template?.songFile}
                            onChange={(e) => {
                                template.songFile = e.target.files[0];
                            }}
                            id={"song-file"}
                            name={"song-file"}
                            type={"file"}
                            required
                        />

                        <audio src={template?.songFile?.url} controls />
                    </div>
                </div>

                <div className={"text-left mb-2"}>
                    <label htmlFor={"name"} className={"text-left pl-4 font-bold"}>Price</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        defaultValue={template?.price}
                        onChange={(e) => {
                            template.price = e.target.value ;
                        }}
                        id={"price"}
                        name={"price"}
                        type={"number"}
                        min={0}
                        required
                    />
                </div>

                <div className={"text-left mb-2"}>
                    <label htmlFor={"name"} className={"text-left pl-4 font-bold"}>Youtube preview url</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        defaultValue={template?.youtubePreviewUrl}
                        onChange={(e) => {
                            template.youtubePreviewUrl = e.target.value ;
                        }}
                        id={"youtubePreviewUrl"}
                        name={"youtubePreviewUrl"}
                        type={"text"}
                        min={0}
                        required
                    />
                </div>
                <div className={"text-left mb-2"}>
                    <label htmlFor={"name"} className={"text-left pl-4 font-bold"}>JSON Config file</label>
                    <div className={"flex flex-row justify-between"}>
                        <input
                            className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4 w-3/4"
                            onChange={(e) => {
                                template.jsonConfig = e.target.value ;
                            }}
                            id={"youtubePreviewUrl"}
                            name={"youtubePreviewUrl"}
                            type={"file"}
                            min={0}
                            required
                        />

                        <div className={"w-1/4 flex flex-col justify-center"}>
                            <a
                                href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(template?.jsonConfig))}`} download={"Config.json"}
                                className={"m-auto w-fit py-2 px-4 border border-secondary text-secondary rounded-md text-left hover:border-transparent hover:text-white hover:bg-secondary transition-all ease-in-out duration-300"}
                            >
                                Download current config
                                <FontAwesomeIcon icon={icon({name: 'download'})} className={"ml-2"}/>
                            </a>
                        </div>
                    </div>
                </div>

                <div className={"text-left mb-2"}>
                    <label htmlFor={"subcategories"} className={"text-left pl-4 font-bold"}>Subcategories</label>
                    <div className={"flex flex-wrap justify-start gap-2 mb-2"}>
                        {
                            template?.subcategories?.map((subcategory) => {
                                return (
                                    <div key={`subcategory_select_template_${subcategory.id}`}
                                        className={"px-2 py-1 bg-secondary text-white rounded-md cursor-pointer"}
                                         onClick={() => {
                                             removeSubcategoryFromTemplate(subcategory.id)
                                         }}
                                    >
                                        {subcategory.name}
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div
                        className={"rounded w-fit mt-1 p-2 bg-primary text-white cursor-pointer"}
                        onClick={toggleSubcategories}
                    >
                        +
                    </div>

                    <div className={"flex flex-wrap justify-start gap-2 mb-2 mt-2"}>
                        {
                            showAllSubcategories &&
                            filteredSubcategories?.map((subcategories) => {
                                return (
                                    <div key={`subcategory_select_to_add_to_template_${subcategories.id}`}
                                         className={"px-2 py-1 bg-secondary text-white rounded-md cursor-pointer"}
                                         onClick={() => {addSubcategoryToTemplate(subcategories)}}
                                    >
                                        {subcategories.name}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>


                <div className={"flex flex-row justify-end mt-2"}>
                    <Button
                        buttonText={"Save"}
                        clickEvent={save}
                    >
                    </Button>
                </div>
            </div>
        </>
    )
}