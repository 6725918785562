import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useBoundStore} from "../../Store/store";
import {Link, useNavigate, useParams} from "react-router-dom";
import { FileDrop } from 'react-file-drop'
import {useRef, useState} from "react";
import {axiosClient} from "../../Api/HttpClient";
import toast from "react-hot-toast";
import {setUser} from "@sentry/react";
import {useTranslation} from "react-i18next";
import {LocaleSwitcher} from "../../Components/Navigation/LocaleSwitcher";


export const UploadFiles = () => {
    const { uploadQrToolMedia } = useBoundStore();
    const {key} = useParams();
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [currentFiles, setCurrentFiles] = useState([]);
    const {t} = useTranslation();
    const [isPrivate, setIsPrivate] = useState(0);
    const [isAdmin, setIsAdmin] = useState(0);

    const elementsRef = useRef([]);
    const titlePage = useRef(null);

    axiosClient().get(`/dropbox/${key}/legit-upload`).then((res) => {
        if(res.data){
            if(!res.data.isLegit){
                navigate('/qr/login');
            }

            if(res.data.hasOwnProperty('dropbox')){
                titlePage.current.innerText = res.data.dropbox.title;
                setIsPrivate(res.data.dropbox.private);
                setIsAdmin(res.data.dropbox.isAdmin);
            }
        } else {
            navigate('/qr/login');
        }
    })

    const onFileInputChange = (event) => {
        const { files } = event.target;
        setCurrentFiles(Array.from(files));
    }

    const validationSchema = yup.object({}).required();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const handleSubmitData = async (data) => {
        let finishedAmount = [];

        // eslint-disable-next-line array-callback-return
        currentFiles.map(async (file, index) => {
            console.log(file);
            uploadQrToolMedia(key, file, elementsRef.current[index]).then(() => {
                finishedAmount.push(index);

                if (finishedAmount.length === currentFiles.length) {
                    setCurrentFiles([]);
                    toast.success(t('qr.all_files_are_uploaded'),{
                        duration: 15000,
                    });
                }
            });
        })
    }

    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    return (
        <div className="relative mx-auto w-full bg-[#FFF8EC]">
            <div className="grid min-h-screen grid-cols-10">
                <div className="col-span-full py-6 px-4 sm:py-12 lg:col-span-6 lg:py-24">
                    <div className="mx-auto w-full max-w-lg">
                        <h1 className="relative text-2xl font-medium text-gray-700 sm:text-3xl">
                            <span ref={titlePage}></span>
                            <span className="mt-2 block h-1 w-10 bg-primary sm:w-20"></span>
                        </h1>

                        <form onSubmit={handleSubmit(handleSubmitData)} className="mt-6 flex flex-col space-y-4">
                            <div>
                                <input type="file"
                                       id="files"
                                       multiple

                                       onChange={onFileInputChange}
                                       ref={fileInputRef}

                                       name="files"
                                       className="hidden mt-1 block w-full rounded border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                                />
                            </div>

                            <div>
                                <div className={'mb-4'}>
                                    {
                                        currentFiles?.length > 0 && currentFiles?.map((file, index) => {
                                            return <p key={`file_${index}`} ref={ref => {
                                                elementsRef.current[index] = ref
                                            }}>{file?.name}</p>
                                        })
                                    }
                                </div>

                                <FileDrop
                                    onTargetClick={onTargetClick}
                                >
                                    <div
                                        className={'cursor-pointer w-full border border-primary p-12 rounded text-primary'}>
                                        {t('qr.click_to_upload')}
                                    </div>
                                </FileDrop>
                            </div>

                            <button type="submit"
                                    className="mt-4 inline-flex w-full items-center justify-center rounded bg-primary py-2.5 px-4 text-base font-semibold tracking-wide text-white text-opacity-80 outline-none ring-offset-2 transition hover:text-opacity-100 focus:ring-2 focus:ring-primary sm:text-lg">
                                {t('qr.start_upload_button')}
                            </button>
                        </form>

                        { (!isPrivate || isAdmin) && (
                            <p className={'mt-4 text-gray-400'}>
                                {t('qr.to_gallery_1')}
                                <span>
                                <Link to={`/qr/${key}/files`} className="text-primary mt-4 ml-1">
                                    {t('qr.to_gallery_2')}
                                </Link>
                            </span>
                            </p>
                        )}
                    </div>
                </div>
                <div className="relative col-span-full flex flex-col py-6 pl-8 pr-4 sm:py-12 lg:col-span-4 lg:py-24">
                    <img src={'/img/nav-logo.png'} className={"mx-auto h-10 z-50 mb-8"} alt={"Logo exid"}/>
                    <div className={'z-50 absolute top-2 right-2'}>
                        <LocaleSwitcher bgColor={'bg-[#FFF8EC]'}/>
                    </div>

                    <div>
                        <img
                            src="https://images.unsplash.com/photo-1533158307587-828f0a76ef46?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            alt="" className="absolute inset-0 h-full w-full object-cover"/>
                        <div
                            className="absolute inset-0 h-full w-full bg-gradient-to-t from-input to-primary opacity-95"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}