import { Link } from 'react-scroll'
import {useTranslation} from "react-i18next";

export const Header = () => {
    const {t} = useTranslation();

    return (
        <div>
            <div className={"h-screen w-screen flex flex-col justify-center absolute z-50 m-auto"}>
                <div className={"w-auto p-8 flex flex-col justify-center w-max mx-auto bg-demo rounded"}>
                    <p className={"text-xl md:text-4xl text-white uppercase mx-auto"}>{t('demo.demo.title_block_1')} <br/> </p>
                    <p className={"text-xl md:text-4xl mt-2 text-white uppercase"}>{t('demo.demo.title_block_2')}</p>

                    <img src={'/img/nav-logo.png'} className={"mx-auto mt-4 h-10"} alt={"Logo exid"} />

                    <Link to="demoEditor" spy={true} smooth={true} className={"px-4 py-2 bg-transparent border border-white mt-8 uppercase text-white w-max mx-auto cursor-pointer hover:bg-white hover:text-black duration-300 transition-all"}>
                        {t('demo.demo.create_my_video_button')}
                    </Link>
                </div>
            </div>
            <div className={"opacity-70"}>
                <div className={"h-[25vh] slider"}>
                    <div className={'h-full flex flex-row overflow-hidden space-x-1 slide-track'}>
                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300" }>
                            <source src={"/videos/demo/1.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/2.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/3.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/4.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/5.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/6.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/7.mp4"} type={"video/mp4"} />
                        </video>

                    </div>
                </div>

                <div className={"h-[25vh] slider"}>
                    <div className={'h-full flex flex-row overflow-hidden space-x-1 slide-track-inverse'}>
                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300" }>
                            <source src={"/videos/demo/8.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/9.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/10.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/11.mp4"} type={"video/mp4"} />
                        </video>


                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/12.mp4"} type={"video/mp4"} />
                        </video>


                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/13.mp4"} type={"video/mp4"} />
                        </video>


                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/14.mp4"} type={"video/mp4"} />
                        </video>

                    </div>
                </div>

                <div className={"h-[25vh] slider"}>
                    <div className={'h-full flex flex-row overflow-hidden space-x-1 slide-track'}>
                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300" }>
                            <source src={"/videos/demo/15.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/16.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/17.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/18.mp4"} type={"video/mp4"} />
                        </video>


                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/19.mp4"} type={"video/mp4"} />
                        </video>


                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/20.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/21.mp4"} type={"video/mp4"} />
                        </video>

                    </div>
                </div>

                <div className={"h-[25vh] slider"}>
                    <div className={'h-full flex flex-row overflow-hidden space-x-1 slide-track-inverse'}>
                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300" }>
                            <source src={"/videos/demo/22.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/23.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/24.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/25.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/26.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/27.mp4"} type={"video/mp4"} />
                        </video>

                        <video muted={true} autoPlay={true} loop={true} controls={false} className={"slide h-full grayscale hover:grayscale-0 transition-all duration-300"}>
                            <source src={"/videos/demo/28.mp4"} type={"video/mp4"} />
                        </video>

                    </div>
                </div>
            </div>
        </div>
    )
}