import {uploadMedia} from "../../Api/mediaApi";
import {deepCloneObject} from "../../Helpers/GeneralHelpers";

export const createVideoEditorSlice = (set, get) => ({
    videos: [],
    videoTimings: [],
    uploadingProgress: [],
    videoTotalTimes: [],
    setVideoAmount: (amount) => {
        if(amount === undefined || amount === null) return;
        set({videos: [...new Array(amount)].map(x => null)});
        set({videoTimings: [...new Array(amount)].map(x => {
            return {start: 0, end: null}
        })});
        set({uploadingProgress: [...new Array(amount)].map(x => null)});
        set({videoTotalTimes: [...new Array(amount)].map(x => null)});
    },
    setVideo: (index, videoObject) => {
        let newVideos = deepCloneObject(get().videos);
        newVideos[index] = videoObject;
        set((state) => ({videos: newVideos}));
    },
    deleteVideo: (index) => {
        let newVideos = deepCloneObject(get().videos);
        newVideos[index] = null;
        set((state) => ({videos: newVideos}));
    },
    uploadVideo: async(file, index, clipLength = null, user = null, companyId = null) => {
        uploadMedia(file, index, user, companyId).then(res => {
            get().finishUploadingProgressForVideo(index, clipLength);
            get().setVideo(index, res);
        });
    },
    setUploadProgress: (index, progress) => {
        let newProgress = deepCloneObject(get().uploadingProgress);
        if(progress === 100){
            newProgress[index] = {progress: 95};
        } else {
            newProgress[index] = {progress: progress};
        }

        set({uploadingProgress: newProgress});
    },
    finishUploadingProgressForVideo: (index, videoLength = null) => {
        let newProgress = deepCloneObject(get().uploadingProgress);
        newProgress[index] = null;
        set({uploadingProgress: newProgress});

        if(videoLength !== null){
            get().setTimingForVideo(index, 0, videoLength)
        }
    },
    setTimingForVideo: (index, start, end) => {
        let newTimings = deepCloneObject(get().videoTimings);
        newTimings[index] = {
            start: start,
            end: end
        };
        set({videoTimings: newTimings})
    },
    setTotalVideoTimeForVideo: (index, time) => {
        let newTotalTimes = deepCloneObject(get().videoTotalTimes);
        newTotalTimes[index] = time;
        set((state) => ({videoTotalTimes: newTotalTimes}));
    },
    getVideoTimingsForIndex: (index) => {
        return get().videoTimings[index] ?? [0,0];
    }
});