import './App.css';
import {AppRouter} from "./Router/AppRouter";
import {AuthenticationHelper} from "./Helpers/AuthenticationHelper";
import { Suspense} from "react";
import { Toaster } from 'react-hot-toast';
import './extra.scss';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

function App() {
    Sentry.init({
        dsn: "https://ffab70f0b2034c8180bbcef868125d92@o4504786287525888.ingest.sentry.io/4504786289754112",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });

    return (
        <Suspense fallback={"loading"}>
            <AuthenticationHelper>
                <Toaster></Toaster>
                <div className="App">
                  <AppRouter />
                </div>
            </AuthenticationHelper>
        </Suspense>
  );
}

export default App;
