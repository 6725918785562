import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useBoundStore} from "../../Store/store";
import {useContext, useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {login, register as RegisterAPI} from "../../Api/authenticationApi";
import toast from "react-hot-toast";
import AuthContext from "../../Helpers/AuthenticationHelper";
import {LocaleSwitcher} from "../../Components/Navigation/LocaleSwitcher";
import {useTranslation} from "react-i18next";

export const Register = () => {
    const navigate = useNavigate();
    const {setLoggedInUser} = useContext(AuthContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const {t} = useTranslation();

    const validationSchema = yup.object({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string().required(),
        password: yup.string().required(),
        confirmPassword: yup.string().required().oneOf([yup.ref('password'), null], 'Passwords must match')
    }).required();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues:{
            firstname: null,
            lastname: null,
            email: null,
            password: null,
            confirmPassword: null
        }
    });

    const handleSubmitData = async (data) => {
        const transformedForAPI = {
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            password: data.password,
            password_confirmation: data.confirmPassword,
            approved_tos: true,
            fromQr: true
        }

        const response = await RegisterAPI(transformedForAPI);
        if('errors' in response){
            toast.error('Not all data has been filled in correctly',{
                position: "top-right"
            });
            return false;
        }else{
            const loginResponse = await login(data.email,data.password);
            await setLoggedInUser(loginResponse.access_token, loginResponse.refresh_token);

            toast.success(`Welcome to EXID ${data?.firstname}`,{
                position: "top-right"
            });

            navigate(`/qr/buy?package=${searchParams.has('package') ? searchParams.get('package') : 1}`);
        }
    }

    return (
        <div className="relative mx-auto w-full bg-[#FFF8EC]">
            <div className="grid min-h-screen grid-cols-10">
                <div className="col-span-full py-6 px-4 sm:py-12 lg:col-span-6 lg:py-24">
                    <div className="mx-auto w-full max-w-lg">
                        <h1 className="relative text-2xl font-medium text-gray-700 sm:text-3xl">{t('qr.register')}<span
                            className="mt-2 block h-1 w-10 bg-primary sm:w-20"></span></h1>
                        <form action="" onSubmit={handleSubmit(handleSubmitData)}
                              className="mt-10 flex flex-col space-y-4">
                            <div>
                                <label
                                    htmlFor="firstname"
                                    className="text-xs font-semibold text-gray-500"
                                >
                                    {t('qr.firstname')}
                                </label>
                                <input type="text"
                                       id="firstname"
                                       name="firstname"
                                       {...register('firstname')}
                                       className="mt-1 block w-full rounded border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                                />
                                {errors?.firstname &&
                                    <p className={"text-red-600 text-sm mt-2"}>{errors.firstname.message}</p>}
                            </div>
                            <div>
                                <label
                                    htmlFor="lastname"
                                    className="text-xs font-semibold text-gray-500"
                                >
                                    {t('qr.lastname')}
                                </label>
                                <input type="text"
                                       id="lastname"
                                       name="lastname"
                                       {...register('lastname')}
                                       className="mt-1 block w-full rounded border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                                />
                                {errors?.lastname &&
                                    <p className={"text-red-600 text-sm mt-2"}>{errors.lastname.message}</p>}
                            </div>

                            <div>
                                <label
                                    htmlFor="email"
                                    className="text-xs font-semibold text-gray-500"
                                >
                                    {t('qr.email')}
                                </label>
                                <input type="email"
                                       id="email"
                                       name="email"
                                       {...register('email')}
                                       className="mt-1 block w-full rounded border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                                />
                                {errors?.email &&
                                    <p className={"text-red-600 text-sm mt-2"}>{errors.email.message}</p>}
                            </div>

                            <div>
                                <label
                                    htmlFor="password"
                                    className="text-xs font-semibold text-gray-500"
                                >
                                    {t('qr.password')}
                                </label>
                                <input type="password"
                                       id="password"
                                       name="password"
                                       {...register('password')}
                                       className="mt-1 block w-full rounded border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                                />
                                {errors?.password &&
                                    <p className={"text-red-600 text-sm mt-2"}>{errors.password.message}</p>}
                            </div>

                            <div>
                                <label
                                    htmlFor="password_confirm"
                                    className="text-xs font-semibold text-gray-500"
                                >
                                    {t('qr.confirm_password')}
                                </label>
                                <input type="password"
                                       id="password_confirm"
                                       name="password_confirm"
                                       {...register('confirmPassword')}
                                       className="mt-1 block w-full rounded border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                                />
                                {errors?.confirmPassword &&
                                    <p className={"text-red-600 text-sm mt-2"}>{errors.confirmPassword.message}</p>}
                            </div>

                            <p className="mt-10 text-center text-sm font-semibold text-gray-500">
                                {t('qr.already_have_account')} <Link to={`/qr/login?package=${searchParams.has('package') ? searchParams.get('package') : 1}`}><span
                                className={'text-primary'}>{t('qr.login_here')}</span></Link>
                            </p>

                            <button type="submit"
                                    className="mt-8 inline-flex w-full items-center justify-center rounded bg-primary py-2.5 px-4 text-base font-semibold tracking-wide text-white text-opacity-80 outline-none ring-offset-2 transition hover:text-opacity-100 focus:ring-2 focus:ring-primary sm:text-lg">
                                {t('qr.register_button')}
                            </button>
                        </form>
                    </div>
                </div>
                <div className="relative col-span-full flex flex-col py-6 pl-8 pr-4 sm:py-12 lg:col-span-4 lg:py-24">
                    <img src={'/img/nav-logo.png'} className={"mx-auto h-10 z-50"} alt={"Logo exid"}/>
                    <div className={'z-50 absolute top-2 right-2'}>
                        <LocaleSwitcher bgColor={'bg-[#FFF8EC]'}/>
                    </div>

                    <div>
                        <img
                            src="https://images.unsplash.com/photo-1533158307587-828f0a76ef46?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            alt="" className="absolute inset-0 h-full w-full object-cover"/>
                        <div
                            className="absolute inset-0 h-full w-full bg-gradient-to-t from-input to-primary opacity-95"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}