import React from "react";
import {useBoundStore} from "../../Store/store";
import {VideoLoaded} from "./EditorComponents/VideoLoaded";
import {VideoUpload} from "./EditorComponents/VideoUpload";
import {VideoUploadingProgress} from "./EditorComponents/VideoUploadingProgress";
import {faAdd, faUpload} from "@fortawesome/free-solid-svg-icons";

export const VideoBlock = (props) => {
    const {
        index,
        videoLength,
        classes = '',
        showIndexNumber= true,
        backgroundColor= '#005083',
        icon= faAdd,
        buttonclasses = '',
        innerTextColor = '#FFFFFF',
        showSupportTextClickUpload = false,
        blockBorder = '0px solid rgba(0,0,0,0)',
        roundedBorder= true,
        companyId = null
    } = props;

    const videos = useBoundStore((state) => state.videos);
    const uploadingProgress = useBoundStore((state) => state.uploadingProgress);

    const hasVideo = () => {
        return videos[index]?.id !== null && videos[index]?.id !== undefined;
    }

    return (
        <div className={`${classes}`}>
            {
                !hasVideo() && uploadingProgress[index] == null && (
                    <VideoUpload
                        index={index}
                        clipLength={videoLength?.duration}
                        showIndexNumber={showIndexNumber}
                        backgroundColor={backgroundColor}
                        icon={icon}
                        innerTextColor={innerTextColor}
                        showSupportTextClickUpload={showSupportTextClickUpload}
                        blockBorder={blockBorder}
                        roundedBorder={roundedBorder}
                        companyId={companyId}
                    />
                )
            }

            {
                uploadingProgress[index] !== null && !hasVideo() && (
                    <VideoUploadingProgress
                        index={index}
                        backgroundColor={backgroundColor}
                        showIndexNumber={showIndexNumber}
                        innerTextColor={innerTextColor}
                        blockBorder={blockBorder}
                        roundedBorder={roundedBorder}
                    />
                )
            }

            {
                hasVideo() && uploadingProgress[index] == null && (
                    <VideoLoaded index={index} video={videos[index]} videoLength={videoLength} buttonclasses={buttonclasses}/>
                )
            }
        </div>
    )
}