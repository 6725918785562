import {createTheme, ThemeProvider, Pagination as PaginationMui} from "@mui/material";

export default function Pagination(props) {
    const {metaData, changePage} = props;

    const amountOfPages = () => {
        const amountOfPages = Math.ceil(metaData?.total / metaData?.per_page)
        return amountOfPages <= 1 ? 1 : amountOfPages;
    }

    const theme = createTheme({
        palette: {
            primary: {main: "#ffa800"},
        },
    });

    return (
        <div className="flex flex-row justify-around gap-3 mt-4">
            <ThemeProvider theme={theme}>
                <PaginationMui
                    count={amountOfPages()}
                    color="primary"
                    variant="outlined"
                    page={metaData?.current_page}
                    onChange={(event, value) => {
                        changePage(value);
                    }}
                    shape={"rounded"}
                />
            </ThemeProvider>

            {/*{*/}
            {/*    Array.from({length: amountOfPages()}, (item, index) => {*/}
            {/*        return (*/}
            {/*            <div key={'pagination_item_' + index+1} className={"border border-primary text-primary px-2 py-1 cursor-pointer m-auto " + (index+1 === metaData?.current_page ? '!bg-primary !text-white !cursor-default' : '')} onClick={() => {*/}
            {/*                if(metaData?.current_page !== index+1){*/}
            {/*                    changePage(index + 1)*/}
            {/*                }*/}
            {/*            }}>*/}
            {/*                {index + 1}*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    })*/}
            {/*}*/}
        </div>
    )
}