import ProductCard from "./ProductCard";
import {useEffect, useState} from "react";
import HomeSidebarNavigation from "../Navigation/HomeSidebarNavigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import {getFilteredTemplates} from "../../Api/templatesApi";
import Pagination from "../General/Pagination";
import { useCallback } from 'react';

export default function ProductOverview({searchValue, filterCategories, setFilterCategories, categoriesChanged}) {
    const [products, setProducts] = useState([]);
    const [metaData, setMetaData] = useState({});
    const [page, setPage] = useState(1);
    const [widthMobileMenu, setWidthMobileMenu] = useState(0);
    const [opacityMobileMenu, setOpacityMobileMenu] = useState(0);
    const [mobileMenuVisible, setMobileMenuVisible] = useState(0);

    const [fullCategoryData, setFullCategoryData] = useState([]);

    const getTemplates = useCallback(async () => {
        const result = await getFilteredTemplates(searchValue, filterCategories, page);
        setProducts(result?.data);
        setMetaData(result?.meta);
    }, [filterCategories, page, searchValue])
    
    useEffect(() => {
        getTemplates();
    }, [getTemplates, searchValue, filterCategories])

    const toggleCategoriesMenu = () => {
        if(widthMobileMenu === 0) {
            setMobileMenuVisible(true);
            setWidthMobileMenu('300px');
            setOpacityMobileMenu(1);
        } else {
            setWidthMobileMenu(0);
            setOpacityMobileMenu(0);
            setMobileMenuVisible(false);
        }
    }

    const changePage = async (page) => {
        await getTemplates()
        setPage(page);
    }

    const getBackgroundClass = () => {
        return opacityMobileMenu === 0 ? 'flex' : 'block';
    }

    const onCategorySelected = (categories) => {
        categoriesChanged(categories);
        filterCategories = categories;
        getTemplates();
    }

    const deleteCategory = (categoryId) => {
        const categories = filterCategories.filter(c => {
            return c !== categoryId;
        })

        setFilterCategories(categories);
        onCategorySelected(categories);
    }

    return (
      <>
          <div style={{width: widthMobileMenu, opacity: opacityMobileMenu}} className={"transition-all z-10 h-full bg-[#FFF8EC] fixed top-0 overflow-y-auto transform w-full duration-500 ease-in-out " + getBackgroundClass() + (mobileMenuVisible ? "" : "invisible")}>
              <div className="p-4 basis-1/2 mt-6 ">
                  <HomeSidebarNavigation selectedCategories={filterCategories} setCategoryNames={setFullCategoryData} onCategorySelected={onCategorySelected}/>
              </div>
          </div>

          <div className={"flex flex-row relative"}>
              <div className={"w-1/4 mt-4 p-4 sticky top-4 hidden md:block"}>
                  <HomeSidebarNavigation selectedCategories={filterCategories} setCategoryNames={setFullCategoryData} onCategorySelected={onCategorySelected}/>
              </div>
              <div className={"fixed bottom-4 right-4 md:hidden"}>
                  <div className="flex bg-primary flex-col justify-center w-14 h-14 rounded-full shadow cursor-pointer" onClick={toggleCategoriesMenu}>
                      <FontAwesomeIcon className="text-2xl m-auto text-white" icon={icon({name: "filter"})} />
                  </div>
              </div>

              <div className="flex flex-col justify-center md:justify-start h-full w-full md:w-3/4">
                  <div className="flex flex-wrap align-middle sticky">
                      { fullCategoryData.length > 0 &&
                          fullCategoryData.map(
                              (category) => {
                                  if(filterCategories.indexOf(category.id) !== -1) {
                                      return (
                                          <div className={"text-left text-white m-3 p-3 rounded bg-primary"}
                                               key={`category_delete_${category.id}`}>
                                              {category.name} <FontAwesomeIcon onClick={() => {
                                                      deleteCategory(category.id)
                                                  }} className="ml-2 cursor-pointer hover:text-red-500" icon={icon({name: "circle-xmark"})} />
                                          </div>
                                      )
                                  } else {
                                      return ""
                                  }
                              })}
                  </div>

                  <div className="flex flex-wrap justify-center md:justify-start h-full p-8 mt-12 gap-8 w-full">

                      { products.length > 0 &&
                          products.map(
                              (product, index) => {
                                  return (
                                      <ProductCard product={product} key={`product_${product.id}_${index}`}/>
                                  )
                              }
                          )}
                      {products.length === 0 &&
                          <p>No templates found</p>
                      }
                  </div>
              </div>
          </div>
          <div className={"w-full text-right mb-8 flex flex-row justify-end px-8"}>
              <Pagination metaData={metaData} changePage={changePage}/>
          </div>
      </>
    );
}