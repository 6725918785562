import { useEffect, useState} from "react";
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";

export const useVideoHelper = () => {
    const logger = new EventTarget();

    const {t} = useTranslation();

    const [fileList, setFileList] = useState([]);
    let [fileTimings, setFileTimings] = useState([]);
    const [jsonTimingData, setJsonTimingData] = useState([]);
    let [skipTrimmingForVideos, setSkipTrimmingForVideos] = useState([]);
    let currentProgress = 0;
    const [loadingFfmpeg, setLoadingFfmpeg] = useState({
        status: false,
        message: null
    });

    let handlingVideoId = null;
    let disabled = false;

    let trimmingVideoNumber = null;

    const [ffmpeg] = useState(createFFmpeg({
        corePath: new URL("/js/ffmpeg-core.js", document.location).href,
        log: true,
        logger: (l) => {
            logger.dispatchEvent(new CustomEvent('ffmpeg-log', { detail: l }));
        }
    }));

    const logListenerFunction = (logData) => {
        if (logData.detail?.type === 'fferr') { // The most interesting data is inside the fferr log type
            if (logData.detail.message.includes('Video: ')) {
                console.log('-- Codec:', parseStreamFromLogs(logData.detail.message));
            } else if (logData.detail.message.includes('Input #0,')) {
                console.log('-- Name: ', parseNameFromLogs(logData.detail.message));
            }
        }

        document.removeEventListener('ffmpeg-log', logListenerFunction);
    }

    const addLogListener = () => {
        logger.addEventListener('ffmpeg-log', logListenerFunction);
    }

    const keyStrokeLogger = (e) => {
        if(e.key === 'l') {
            console.log(ffmpeg.FS('readdir', '/'));

            console.log(fileList)
        }

        document.removeEventListener('keyup', keyStrokeLogger);
    }

    const addKeystrokeDirectoryListener = () => {
        document.addEventListener('keyup', keyStrokeLogger);
    }

    const initiate = () => {
        if(!ffmpeg.isLoaded()){
            setLoadingFfmpeg({
                status: true,
                error: false,
                message: t('editor.setting_up_editor')
            });
            ffmpeg.load().then((res) => {
                setLoadingFfmpeg({
                    status: false,
                    error: false,
                    message: t('editor.setting_up_editor')
                });
            }).catch((err) => {
                setLoadingFfmpeg({
                    status: true,
                    error: true,
                    message: t('editor.setting_up_editor_error')
                });
            });
        }

        addLogListener();
        addKeystrokeDirectoryListener();
    }

    const recheckLoadStateOfFFmpeg = () => {
        if(!ffmpeg.isLoaded()) {
            ffmpeg.load();
        }
    }

    const addVideoToList = async (file, index) => {
        const fileData = await fetchFile(file);
        const currentExt = await getExtension(file.name);

        const uploadedFileName = `${Date.now()}.${currentExt}`;
        const newFileName = `${index}.mp4`;
        await writeFileIntoFFmpegFileSystem(uploadedFileName, fileData);

        handlingVideoId = index;

        blockUnblockUIonHandlingVideo();
        setProgress();

        await ffmpeg.run(
            '-i', uploadedFileName,
            // '-map', '0:v',
            '-c:v', 'libx264',
            '-crf', '18',
            '-preset','ultrafast',
            '-filter:v', 'fps=30',
            '-an',
            newFileName
        );

        await readFileIntoFFmpegFilesystem(newFileName);

        fileList.push(newFileName);

        handlingVideoId = null;
        blockUnblockUIonHandlingVideo(false);
    }

    const blockUnblockUIonHandlingVideo = (block = true) => {
        const count = jsonTimingData.length;

        for (let i = 0; i < count; i++){
            const elem = document.getElementById(`video_ui_block_${i}`);

            if(i !== handlingVideoId && elem){
                if(block) {
                    elem.classList.remove('hidden');
                } else {
                    elem.classList.add('hidden');
                }
            }
        }
    }

    const removeVideoFromList = (index) => {
        fileList.splice(index, 1);
    }

    const addWaterMarkToVideo = async (fileName,imageWatermakFilename, outputName) => {
        await ffmpeg.run(
            '-i', `${fileName}`,
            '-i',`${imageWatermakFilename}`,
            '-filter_complex', '[1][0]scale2ref=w=oh*mdar:h=ih*0.1[logo1][video];[logo1]format=rgba,colorchannelmixer=aa=0.7[logo];[video][logo]overlay=(W-w)/2:(H-h)/2',
            "-preset",
            "ultrafast",
            `${outputName}`
        );
    }

    const concatenateAllVideos = async (filelistName, outputName) => {
        await ffmpeg.run(
            '-f', 'concat',
            '-i',`${filelistName}`,
            '-c:v','copy',
            '-bsf:a', 'aac_adtstoasc',
            `${outputName}`
        );
    }

    const addAudioToVideo = async (inputFileName,audioFileName, outputName) => {
        await ffmpeg.run(
            '-i',`${inputFileName}`,
            '-i',`${audioFileName}`,
            '-filter_complex', '[1:0] apad',
            '-c:v','copy',
            '-c:a','aac',
            '-shortest',
            `${outputName}`
        );
    }

/*
    const cropVideo = async (horizontal = true, fileName, outputFileName) => {
        if(horizontal){
            await ffmpeg.run('-i', fileName, '-vf', 'scale=1280:-1,crop=iw:720', outputFileName);
        } else {
            await ffmpeg.run('-i', fileName, '-vf', 'scale=1280:-1,crop=iw:720', outputFileName);
        }
    }
*/

    const createFileListTextFile = async (fileName, otherList = null) => {
        let translated = [];
        const list = otherList !== null ? otherList : fileList;
        for (const name of list) {
            translated.push(`file ${name}`);
        }

       await writeFileIntoFFmpegFileSystem(fileName,translated.join('\n'));
    }

    const getResultVideo = async (
        audioFileUrl = null,
        watermarkImageUrl = null ,
        addWatermark = true,
        addAudio = true,
        autoDownload = false,
        fileListOverwrite = null,
        timingsOverwrite = null
    ) => {
        if(handlingVideoId !== null){
            toast.error(t('editor.video_still_preparing'));
            return false;
        }

        const filelistName = 'concat.txt';
        let currentOutputFileName;
        let amountOfSteps = 1;
        if(addWatermark) {
            amountOfSteps++;
        }

        if(addAudio){
            amountOfSteps++;
        }

        setSteppedProgressWithText(amountOfSteps, 1, false);
        const trimmedList = await trimVideosOnTimings((fileListOverwrite !== null ? fileListOverwrite : null), (timingsOverwrite !== null ? timingsOverwrite : null));
        await createFileListTextFile(filelistName, trimmedList);

        setSteppedProgressWithText(amountOfSteps, 1);
        await concatenateAllVideos(filelistName, 'output_all_videos.mp4');
        currentOutputFileName = 'output_all_videos.mp4';
        await readFileIntoFFmpegFilesystem(currentOutputFileName);

        if(addWatermark && watermarkImageUrl !== null){
            setSteppedProgressWithText(amountOfSteps, 2);
            await getFileFromUrl(watermarkImageUrl, 'nav-logo.png');
            await addWaterMarkToVideo(currentOutputFileName,'nav-logo.png', 'output_all_videos_watermarked.mp4');
            currentOutputFileName = 'output_all_videos_watermarked.mp4';
            await readFileIntoFFmpegFilesystem(currentOutputFileName);
        }

        if(addAudio && audioFileUrl !== null){
            setSteppedProgressWithText(amountOfSteps, 3);
            await getFileFromUrl(audioFileUrl,'song.wav');
            await addAudioToVideo(currentOutputFileName, 'song.wav', 'output_all_videos_audio.mp4')
            currentOutputFileName = 'output_all_videos_audio.mp4'
            await readFileIntoFFmpegFilesystem(currentOutputFileName);
        }

        if(autoDownload) {
            readFileIntoFFmpegFilesystem(currentOutputFileName, true).then((data) => {
                downloadVideo(new Blob([data.buffer], { type: 'video/mp4' }));
            });
        }
        return currentOutputFileName;
    }

    const trimVideosOnTimings = async (overwrittenFileList = null, overwrittenTimings = null) => {
        const trimmedFilesList = [];
        let counter = 1;
        for (const name of (overwrittenFileList !== null ? overwrittenFileList : fileList)) {
            trimmingVideoNumber = counter;

            if(skipTrimmingForVideos.includes(name)){
                trimmedFilesList.push(name);
                continue;
            }

            const newFileName = `trimmed_${name}`
            let index, timings;
            if(overwrittenFileList !== null){
                index = overwrittenFileList.indexOf(name);
                timings = overwrittenTimings[index];
            }else{
                index = fileList.indexOf(name);
                timings = fileTimings[index];
            }
            const definedDuration = jsonTimingData[index]?.duration / 1000;
            await trimVideoWithTiming(definedDuration, timings, name, newFileName);
            trimmedFilesList.push(newFileName);
            counter++;
        }
        return trimmedFilesList;
    }

    const trimVideoWithTiming = async (duration, startTime, inputFileName, outputName, horizontal = true) => {
        // horizontal => '-vf', 'scale=1280:-1,crop=iw:720',
        // vertical => '-vf', 'scale=1280:-1,crop=iw:720'
        const vfCommandValue = (horizontal ? 'scale=1280:-1,crop=iw:720' : 'scale=1280:-1,crop=iw:720');

        if(startTime !== 0 && startTime !== undefined && startTime !== '0') {
            await ffmpeg.run(
                '-ss', `${startTime}`,
                '-i', `${inputFileName}`,
                '-t', `${duration * 1000}ms`,
                '-c:v', 'libx264',
                '-avoid_negative_ts', 'make_zero',
                '-an',
                '-vf' , vfCommandValue,
                outputName
            );
        } else{
            await ffmpeg.run(
                '-i', `${inputFileName}`,
                '-t', `${duration * 1000}ms`,
                // '-vcodec', 'copy',
                // '-acodec', 'copy',
                // '-f', 'segment',
                '-reset_timestamps' , '1',
                '-avoid_negative_ts', 'make_zero',
                '-an',
                '-vf' , vfCommandValue,
                outputName
            );
        }

        await readFileIntoFFmpegFilesystem(outputName)
    }

    const readFileIntoFFmpegFilesystem = async (fileName, returnBlob = false) => {
        const data = await ffmpeg.FS('readFile', fileName);
        if(returnBlob) return data;
    }

    const writeFileIntoFFmpegFileSystem = async (fileName, data) => {
        await ffmpeg.FS('writeFile', fileName, data);
    }

    const getFileFromUrl = async (url, fileName) => {
        ffmpeg.FS("writeFile", fileName, await fetchFile(url));
    }

    const downloadVideo = (dataBlob, filename = 'ExidVideo.mp4') => {
        const link = document.createElement("a");

        link.href = URL.createObjectURL(dataBlob);
        link.download = filename;

        document.body.appendChild(link);

        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        document.body.removeChild(link);
    }

    const getExtension = async (fileName) => {
        const splitted = fileName.split('.');
        return splitted[1];
    }

    const parseStreamFromLogs = (input) => {
        const streamKeyword = "Video: "
        const streamString = input.slice(input.indexOf(streamKeyword) + streamKeyword.length);

        return streamString.split(" ")[0]
    }

    const parseNameFromLogs = (input) => {
        const splitted = input.split(',');
        return splitted[splitted.length - 1];
    }

    const setTimingForVideo = (index, startTime) => {
        const timings = fileTimings;
        timings[index] = startTime;
        setFileTimings(timings);
    }

    const useSetJsonDataTimingsForClips = (timings) => {
        useEffect(() => { setJsonTimingData(timings) }, [timings]);
    }

    const setProgress = () => {
        ffmpeg.setProgress((ratio) => {
            currentProgress = Math.floor(100 * ratio.ratio);
            if(currentProgress > 0 && currentProgress <= 100){
                if(currentProgress === 100) {
                    document.getElementById(`play_button_${handlingVideoId}`).click();
                    document.getElementById(`play_button_${handlingVideoId}`).click();

                    document.getElementById(`video_controls_${handlingVideoId}`).classList.remove('invisible');
                    document.getElementById(`video_playback_${handlingVideoId}`).classList.remove('invisible');

                    document.getElementById(`loading_screen_video_${handlingVideoId}`).classList.add('hidden');
                }else{
                    document.getElementById(`video_playback_${handlingVideoId}`).classList.add('invisible');
                    document.getElementById(`video_controls_${handlingVideoId}`).classList.add('invisible');

                    document.getElementById(`loading_screen_video_${handlingVideoId}`).classList.remove('hidden');

                    document.getElementById(`prep_video_percentage_${handlingVideoId}`).innerHTML = (currentProgress) + '%';
                }
            }
        })
    }

    const setSteppedProgressWithText = (steps , currentStep, setPercentage = true) => {
        const totalPercentagePerStep = 100 / steps;

        ffmpeg.setProgress((ratio) => {
            currentProgress = Math.floor(100 * ratio.ratio);
            if(currentProgress > 0 && currentProgress <= 100){
                const relativePercentage = (currentProgress / 100) * totalPercentagePerStep;
                const currentProgressRelative = (totalPercentagePerStep * (currentStep - 1)) + relativePercentage;

                if(currentProgressRelative >= 100) {
                    document.getElementById(`download_video_button`).innerText = t('editor.download_video_button');
                }else{
                    if(setPercentage){
                        document.getElementById(`download_video_button`).innerText = Math.floor(currentProgressRelative) + '%';
                    } else {
                        document.getElementById(`download_video_button`).innerText = `${t('editor.preparing_video')} ${trimmingVideoNumber} / ${fileList.length}`;
                    }
                }
            }
        })
    }

    return {
        recheckLoadStateOfFFmpeg,
        readFileIntoFFmpegFilesystem,
        initiate,
        addVideoToList,
        downloadVideo,
        getResultVideo,
        setTimingForVideo,
        useSetJsonDataTimingsForClips,
        removeVideoFromList,
        getFileFromUrl,
        fileList,
        jsonTimingData,
        currentProgress,
        handlingVideoId,
        disabled,
        loadingFfmpeg,
        fileTimings,
        setFileTimings,
        setFileList,
        setSkipTrimmingForVideos
    }
}

export default useVideoHelper;