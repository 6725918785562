import {useBoundStore} from "../../Store/store";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {LocaleSwitcher} from "../../Components/Navigation/LocaleSwitcher";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import FilesZippingModal from "../../Components/Popups/FilesZippingModal";
import {axiosClient} from "../../Api/HttpClient";
import ConfirmDeleteModal from "../../Components/Popups/ConfirmDeleteModal";
import toast from "react-hot-toast";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import {Autoplay, Navigation} from "swiper/modules";
import "swiper/css/autoplay";
import Pagination from "../../Components/General/Pagination";
import PresentingSpeedModal from "../../Components/Popups/PresentingSpeedModal";
import SettingsDropboxModal from "../../Components/Popups/SettingsDropboxModal";

export const FileListPage = () => {
    const { fetchAllFilesForDropbox,downloadZip, downloadSingleFile, deleteFileFromDropbox } = useBoundStore();
    const {key} = useParams();
    const {t} = useTranslation();
    const presentationSlider = useRef(null);

    const [chunked, setChunked] = useState([]);
    const [allMedia, setAllMedia] = useState([]);
    const [page, setPage] = useState(1);

    const [amountOfPages, setAmountOfPages] = useState(1);
    const [showDownloading, setShowDownloading] = useState(false);
    const titlePage = useRef(null);
    const [deletingMediaId, setDeletingMediaId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(null);
    const [showPresentingSpeedModal, setShowPresentingSpeedModal] = useState(null);
    const [showSettingsModal, setShowSettingsModal] = useState(null);
    const [presentingSpeed, setPresentingSpeed] = useState(2500);
    const [isAdmin, setIsAdmin] = useState(false);
    const [dropboxSettings, setDropboxSettings] = useState({});
    const handle = useFullScreenHandle();

    const navigate = useNavigate();

    useEffect(() => {
        fetchAllFilesForDropbox(key).then((res) => {
            let chunked = [];
            const chunkSize = 4;
            setAllMedia(res);
            for (let i = 0; i < res.length; i += chunkSize) {
                chunked.push(res.slice(i, i + chunkSize));
            }

            const amountOfPages = Math.ceil(chunked.length / 5) ?? 1;
            setAmountOfPages(amountOfPages);

            setChunked(chunked);
        })

        axiosClient().get(`/dropbox/${key}/legit`, {
            headers: {
                'Authorization': `Bearer ${ localStorage.getItem('exid_user_access_token')}`
            }
        }).then((res) => {
            if(res.data){
                if(!res.data.isLegit){
                    navigate('/qr/login');
                }

                if(res.data.hasOwnProperty('dropbox') && titlePage.current !== null){
                    titlePage.current.innerText = res.data.dropbox.title;
                }

                if(res.data.hasOwnProperty('dropbox') && res.data.dropbox.hasOwnProperty('isAdmin')){
                    setIsAdmin(res.data.dropbox.isAdmin);
                    setDropboxSettings(res.data.dropbox);
                }
            } else {
                navigate('/qr/login');
            }
        });

    }, [deletingMediaId]);

    const downloadFile = async (id, fileName) => {
        downloadSingleFile(key, id, fileName).then(r => {console.log('downloaded file')});
    }

    const isVideo = (fileName) => {
        const extensions = [
            '.webm',
            '.mpg',
            '.mp2',
            '.mpeg',
            '.mpe',
            '.mpv',
            '.ogg',
            '.mp4',
            '.m4p',
            '.m4v',
            '.avi',
            '.wmv',
            '.mov',
            '.qt',
            '.flv',
            '.swf',
            '.avchd'
        ];

        for(const extension of extensions ){
            if(fileName.toLowerCase().endsWith(extension)){
                return true;
            }
        }

        return false;
    }

    return (
        <div className="relative mx-auto w-full bg-[#FFF8EC]">
            <div className="grid min-h-screen grid-cols-10">
                <div className="col-span-full py-6 px-4 sm:py-12 lg:col-span-6 lg:py-24">
                    <div className="mx-auto w-full max-w-lg">

                        <h1 className="relative text-2xl font-medium text-gray-700 sm:text-3xl">
                            <span ref={titlePage}></span>
                            <span className="mt-2 block h-1 w-10 bg-primary sm:w-20"></span>
                        </h1>

                        <p className={'mt-4 text-gray-400'}>
                            {t('qr.to_upload_1')}
                            <span>
                                <Link to={`/qr/upload/${key}`} className="text-primary mt-4 ml-1">
                                    {t('qr.to_upload_2')}
                                </Link>
                            </span>
                        </p>

                        {
                            isAdmin && (
                                <button onClick={() => {
                                    downloadZip(key).then(r => {
                                        setShowDownloading(true)
                                    });
                                }}
                                        className="mt-4 inline-flex w-full items-center justify-center rounded bg-primary py-2.5 px-4 text-base font-semibold tracking-wide text-white text-opacity-80 outline-none ring-offset-2 transition hover:text-opacity-100 focus:ring-2 focus:ring-primary sm:text-lg">
                                    {t('qr.download_my_files')}
                                </button>
                            )
                        }

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                            {
                                chunked.length > 0 && chunked.map((items, index) => {
                                    const beginIndex = (page - 1) * 5;
                                    const endIndex = ((page) * 5) - 1;

                                    // if index is not between beginindex and endindex
                                    if (index < beginIndex || index > endIndex) {
                                        return null;
                                    }

                                    return (
                                        <div className="grid gap-4" key={`row_item_${index}`}>
                                            {
                                                items.map((item) => {
                                                    if (isVideo(item.fileName)) {
                                                        return (
                                                            <div key={`item_of_${item.id}`} className={'relative'}>
                                                                <video className="h-auto max-w-full rounded-lg" muted
                                                                       controls playsInline>
                                                                    <source src={item.url}/>
                                                                </video>

                                                                {
                                                                    isAdmin && (
                                                                        <div
                                                                            className={"h-8 w-8 rounded-full bg-[#FFF8EC] border border-primary absolute -top-2 -right-2 flex flex-col justify-center cursor-pointer"}
                                                                            onClick={() => {
                                                                                downloadFile(item.id, item.fileName).then(r => {
                                                                                })
                                                                            }}>
                                                                            <FontAwesomeIcon icon={icon({name: 'download'})}
                                                                                             className={'text-gray-700'}/>
                                                                        </div>
                                                                    )
                                                                }

                                                                {
                                                                    isAdmin && (
                                                                        <div
                                                                            className={"h-8 w-8 rounded-full bg-[#FFF8EC] border border-primary absolute -top-2 -right-2 flex flex-col justify-center cursor-pointer"}
                                                                            onClick={() => {
                                                                                downloadFile(item.id, item.fileName).then(r => {
                                                                                })
                                                                            }}>
                                                                            <FontAwesomeIcon icon={icon({name: 'download'})}
                                                                                             className={'text-gray-700'}/>
                                                                        </div>
                                                                    )
                                                                }

                                                                {
                                                                    isAdmin && (
                                                                        <div
                                                                            className={"h-8 w-8 rounded-full bg-[#FFF8EC] border border-red-700 absolute -top-2 -left-2 flex flex-col justify-center cursor-pointer"}
                                                                            onClick={() => {
                                                                                setDeletingMediaId(item.id);
                                                                                setShowDeleteModal(true);
                                                                            }}>
                                                                            <FontAwesomeIcon icon={icon({name: 'trash'})}
                                                                                             className={'text-red-700'}/>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    }

                                                    return (
                                                        <div key={`item_of_${item.id}`} className={'relative'}>
                                                            <img className="h-auto max-w-full rounded-lg"
                                                                 src={item.url}
                                                                 alt=""/>

                                                            {
                                                                isAdmin && (
                                                                    <div
                                                                        className={"h-8 w-8 rounded-full bg-[#FFF8EC] border border-primary absolute -top-2 -right-2 flex flex-col justify-center cursor-pointer"}
                                                                        onClick={() => {
                                                                            downloadFile(item.id, item.fileName).then(r => {
                                                                            })
                                                                        }}>
                                                                        <FontAwesomeIcon icon={icon({name: 'download'})}
                                                                                         className={'text-gray-700'}/>
                                                                    </div>
                                                                )
                                                            }

                                                            {
                                                                isAdmin && (
                                                                    <div
                                                                        className={"h-8 w-8 rounded-full bg-[#FFF8EC] border border-red-700 absolute -top-2 -left-2 flex flex-col justify-center cursor-pointer"}
                                                                        onClick={() => {
                                                                            setDeletingMediaId(item.id);
                                                                            setShowDeleteModal(true);
                                                                        }}>
                                                                        <FontAwesomeIcon icon={icon({name: 'trash'})}
                                                                                         className={'text-red-700'}/>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="flex flex-row justify-around gap-3 mt-4 mx-auto">
                            <Pagination
                                metaData={{current_page: page, total: chunked.length, per_page: 5}}
                                changePage={(value) => setPage(value)}
                            />

                        </div>
                    </div>
                </div>
                <div className="relative col-span-full flex flex-col py-6 pl-8 pr-4 sm:py-12 lg:col-span-4 lg:py-24">
                    <img src={'/img/nav-logo.png'} className={"mx-auto h-10 z-50 mb-8"} alt={"Logo exid"}/>

                    {
                        isAdmin && Array.isArray(allMedia) && allMedia.filter((item) => !isVideo(item.fileName)).length > 0 && (
                            <>
                                <button onClick={() => setShowPresentingSpeedModal(true)}
                                        className="mt-4 inline-flex border border-white w-full items-center justify-center rounded bg-transparent py-2.5 px-4 text-white ring-offset-2 transition z-20">
                                    >
                                    {t('qr.start_presenting')}
                                </button>
                                <PresentingSpeedModal isShown={showPresentingSpeedModal} closePopup={(speed) => {
                                    if (speed === null) {
                                        setShowPresentingSpeedModal(false);
                                        return;
                                    }
                                    setPresentingSpeed(speed * 1000)
                                    setShowPresentingSpeedModal(false);
                                    handle.enter().then(r => {
                                    })
                                }}/>
                            </>
                        )
                    }

                    { isAdmin && (
                        <>
                            <button onClick={() => setShowSettingsModal(true)}
                                    className="mt-4 inline-flex border border-white w-full items-center justify-center rounded bg-transparent py-2.5 px-4 text-white ring-offset-2 transition z-20">
                                >
                                {t('qr.settings')}
                            </button>

                            <SettingsDropboxModal
                                settings={dropboxSettings ?? null}
                                dropboxKey={key}
                                isShown={showSettingsModal}
                                closePopup={() => {
                                    setShowSettingsModal(false);
                                }}
                            />
                        </>
                    )}


                    <div className={'z-50 absolute top-2 right-2'}>
                        <LocaleSwitcher bgColor={'bg-[#FFF8EC]'}/>
                    </div>

                    <div>
                        <img
                            src="https://images.unsplash.com/photo-1533158307587-828f0a76ef46?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            alt="" className="absolute inset-0 h-full w-full object-cover"/>
                        <div
                            className="absolute inset-0 h-full w-full bg-gradient-to-t from-input to-primary opacity-95"></div>
                    </div>
                </div>
            </div>

            {
                isAdmin && (
                    <FilesZippingModal isShown={showDownloading} closePopup={() => setShowDownloading(false)}/>
                )
            }

            {
                isAdmin && (
                    <ConfirmDeleteModal isShown={showDeleteModal} closePopup={() => setShowDeleteModal(false)} clickConfirmed={() => {
                        deleteFileFromDropbox(key, deletingMediaId).then(r => {
                            setDeletingMediaId(null);
                            setShowDeleteModal(false);
                            toast.success(t('qr.file_deleted_success_message'));
                        });
                    }}/>
                )
            }

            <FullScreen handle={handle} className={`${handle.active ? '' : 'hidden'}`}>
                <Swiper
                    slidesPerView={1}
                    ref={presentationSlider}
                    loop={true}
                    modules={[Autoplay, Navigation]}
                    autoplay={{
                        delay: presentingSpeed,
                        disableOnInteraction: false,
                        stopOnLastSlide: false,
                    }}
                    className={'h-screen w-screen'}
                >
                    {
                        Array.isArray(allMedia) &&
                        allMedia?.filter((item) => !isVideo(item.fileName)).length > 0 &&
                        allMedia?.filter((item) => !isVideo(item.fileName)).map((item) => {
                           /* if(isVideo(item.fileName)){
                                return (
                                    <SwiperSlide
                                        key={`item_of_${item.id}`}
                                        className={"mx-auto flex flex-col justify-center h-screen w-screen"}
                                        lazy={true}
                                    >
                                        <video className="h-full max-w-full rounded-lg m-auto"
                                               muted={true}
                                               controls={true}
                                               autoPlay={true}
                                               playsInline={true}
                                               onEnded={() => {
                                                   console.log('ended')
                                                   presentationSlider.current.swiper.slideNext()
                                               }}
                                        >
                                            <source src={item.url}/>
                                        </video>
                                    </SwiperSlide>
                                )
                            }*/

                            return (
                                <SwiperSlide key={`item_of_${item.id}`} className={'mx-auto flex flex-col justify-center h-screen w-screen'}>
                                    <img className="h-full m-auto max-w-full rounded-lg"
                                         src={item.url}
                                         alt=""/>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </FullScreen>
        </div>
    );
}