import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {getUser} from "../../../Api/usersApi";

export default function UsersDetail() {
    const [user, setUser] = useState();
    const { id } = useParams()

    useEffect(() => {
        (async function() {
            setUser(await getUser(id));
        })()
    }, [id])

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>User detail</h1>
                <Link to={'/admin/users'} className={"py-2 px-4 ease-in-out duration-300 text-secondary hover:text-primary cursor-pointer transition-all"}>
                    <FontAwesomeIcon icon={icon({name: 'arrow-left'})} className={"mr-2"}/>
                    Back to users
                </Link>
            </div>

            <div className={"grid grid-cols-3 gap-y-4"}>
                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>ID</p>
                    <p className={"text-left"}>{user?.id}</p>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>FIRSTNAME</p>
                    <p className={"text-left"}>{user?.firstname}</p>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>LASTNAME</p>
                    <p className={"text-left"}>{user?.lastname}</p>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>EMAIL</p>
                    <p className={"text-left"}>{user?.email}</p>
                </div>
            </div>
        </>
    )
}