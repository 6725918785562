import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {getTemplate} from "../../../Api/templatesApi";

export default function TemplateDetail() {
    const [template, setTemplate] = useState();
    const { id } = useParams()

    useEffect(() => {
        (async function() {
            setTemplate(await getTemplate(id));
        })()
    }, [id])

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>Template detail</h1>
                <Link to={'/admin/templates'} className={"py-2 px-4 ease-in-out duration-300 text-secondary hover:text-primary cursor-pointer transition-all"}>
                    <FontAwesomeIcon icon={icon({name: 'arrow-left'})} className={"mr-2"}/>
                    Back to templates
                </Link>
            </div>

            <div className={"grid grid-cols-3 gap-4 break-all"}>
                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>ID</p>
                    <p className={"text-left"}>{template?.id}</p>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>TITLE</p>
                    <p className={"text-left"}>{template?.title}</p>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>SONG TITLE</p>
                    <p className={"text-left"}>{template?.songTitle}</p>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>SONG ARTIST</p>
                    <p className={"text-left"}>{template?.artistName}</p>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-2"}>SONG</p>
                    <audio src={template?.songFile?.url} controls />
                </div>

                <div className={"text-left"}>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-2"}>JSON CONFIG</p>
                    <a
                        href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(template?.jsonConfig))}`} download={"Config.json"}
                        className={"py-2 px-4 border border-secondary text-secondary rounded-md text-left hover:border-transparent hover:text-white hover:bg-secondary transition-all ease-in-out duration-300"}
                    >
                        Download JSON file
                        <FontAwesomeIcon icon={icon({name: 'download'})} className={"ml-2"}/>
                    </a>
                </div>

                <div className={"text-left"}>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>SUBCATEGORIES</p>
                    <div className={"flex flex-wrap justify-start gap-2"}>
                        {
                            template?.subcategories?.length > 0 &&
                            template?.subcategories?.map((subcategory) => {
                                return (
                                    <div className={"px-2 py-1 bg-secondary text-white rounded-md"} key={`template_detail_subcategories_${subcategory.id}`}>
                                        {subcategory.name}
                                    </div>
                                )
                            })
                        }
                        {
                            template?.subcategories?.length === 0 && '-'
                        }
                    </div>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>CATEGORY</p>
                    <p className={"text-left"}>{template?.category?.name ?? '-'}</p>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>YOUTUBE PREVIEW</p>
                    <iframe title={"Preview template youtube link"} src={template?.youtubePreviewUrl} className={"rounded-md w-fit"}></iframe>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>PRICE</p>
                    <p className={"text-left"}>{template?.price / 100} €</p>
                </div>
            </div>
        </>
    )
}