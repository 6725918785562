import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useBoundStore} from "../../Store/store";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {LocaleSwitcher} from "../../Components/Navigation/LocaleSwitcher";
import {getQrPackageAPI} from "../../Api/qrApi";

export const BuyQr = () => {
    const { fetchPaymentUrl, validatePromocode } = useBoundStore();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [qrPackage, setQrPackage] = useState(null);

    const [isCompany, setIsCompany] = useState(false);
    const [promocode, setPromocode] = useState(null);
    const [promocodeValidated, setPromocodeValidated] = useState(null);

    const [vatCountryCode, setVatCountryCode] = useState("BE");
    const vatCountryCodes = ["BE", "DE", "NL", "FR", "LU"];

    // const countries = require("i18n-iso-countries");
    // countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    // countries.registerLocale(require("i18n-iso-countries/langs/de.json")); // German
    // countries.registerLocale(require("i18n-iso-countries/langs/fr.json")); // French
    // countries.registerLocale(require("i18n-iso-countries/langs/es.json")); // Spanish

    // const countryNames = countries.getNames("fr", { select: "native" });

    const validationSchema = yup.object({
        map: yup.string().required().max(255),
        vat: yup.string().nullable().matches(/^\d+$/, t('VAT number should contain only numbers')).max(255),
        name: yup.string().nullable().max(255),
        address: yup.string().nullable().max(255),
        postcode: yup.string().nullable().max(255),
        city: yup.string().nullable().max(255),
        // country: yup.string().nullable().oneOf(vatCountryCodes, t('Invalid country')).max(255),
        tos: yup.mixed().required().oneOf([true],t('It is required to accept the terms and conditions')),
    }).required();

    useEffect(() => {
        if(searchParams.has('package')){
            getQrPackageAPI(searchParams.get('package')).then((res) => {
                setQrPackage(res.package);
            });
        } else {
            getQrPackageAPI(1).then((res) => {
                setQrPackage(res.package);
            });
        }
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues:{
            map: null,
            vat: null,
            name: null,
            address: null,
            postcode: null,
            city: null,
            // country: null,
            tos: null,
        }
    });

    const handleSubmitData = async (data) => {
        const transformedForAPI = {
            map: data?.map,
            isCompany: isCompany,
            package: qrPackage?.id
        }

        if (isCompany) {
            transformedForAPI.companyName = data.name;
            transformedForAPI.vatNumber = `${vatCountryCode}${data.vat}`;
            transformedForAPI.address = data.address;
            transformedForAPI.postcode = data.postcode;
            transformedForAPI.city = data.city;
            // transformedForAPI.country = data.country;
        }

        if (promocodeValidated !== null && promocodeValidated.hasOwnProperty('id')) {
            transformedForAPI.promocode = promocodeValidated.id;
        }

        const res = await fetchPaymentUrl(transformedForAPI);

        if (res.hasOwnProperty('url')) {
            window.location = res.url;
        }
    }

    const calculatePrice = () => {
        const originalPrice = (qrPackage?.price / 100);

        if(promocodeValidated !== null && promocodeValidated.hasOwnProperty('percentage')){
            return originalPrice - (originalPrice * (promocodeValidated?.percentage/100))
        }

        return originalPrice;
    }

    return (
        <div className="relative mx-auto w-full bg-[#FFF8EC]">
            <div className="grid min-h-screen grid-cols-10">
                <div className="col-span-full py-6 px-4 sm:py-12 lg:col-span-6 lg:py-24">
                    <div className="mx-auto w-full max-w-lg">
                        <h1 className="relative text-2xl font-medium text-gray-700 sm:text-3xl">{t('qr.checkout_title')}<span
                            className="mt-2 block h-1 w-10 bg-primary sm:w-20"></span></h1>
                        <form onSubmit={handleSubmit(handleSubmitData)} className="mt-10 flex flex-col space-y-4">
                            <div>
                                <label
                                    htmlFor="map"
                                    className="text-xs font-semibold text-gray-500"
                                >
                                    {t('qr.name_event')}
                                </label>
                                <input type="text"
                                       id="map"
                                       {...register('map')}
                                       name="map"
                                       className="mt-1 block w-full rounded border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                                />

                                {errors?.map && <p className={"text-red-600 text-sm"}>{errors.map.message}</p>}

                            </div>

                            <div>
                                <label
                                    htmlFor="isCompany"
                                    className="text-xs font-semibold text-gray-500"
                                >
                                    <input type="checkbox"
                                           id="isCompany"
                                           name="isCompany"
                                           onChange={(e) => {
                                               setIsCompany(e.target.checked)
                                           }}
                                           className="mr-4 rounded border-gray-300 bg-gray-50 outline-none transition"
                                    />
                                    {t('qr.company_purchase')}
                                </label>

                            </div>

                            {
                                isCompany && (
                                    <>
                                        <div>
                                            <label
                                                htmlFor="name"
                                                className="text-xs font-semibold text-gray-500"
                                            >
                                                {t('qr.company_name')}
                                            </label>
                                            <input type="text"
                                                   id="name"
                                                   {...register('name')}
                                                   name="name"
                                                   className="mt-1 block w-full rounded border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                                            />
                                        </div>

                                        <div>
                                            <label
                                                htmlFor="vat"
                                                className="text-xs font-semibold text-gray-500"
                                            >
                                                {t('qr.vat_number')}
                                            </label>
                                            <div className="mt-1 flex">
                                                <select
                                                    value={vatCountryCode}
                                                    onChange={(e) => setVatCountryCode(e.target.value)}
                                                    className="block w-20 rounded-l border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                                                >
                                                    {vatCountryCodes.map((code) => (
                                                        <option key={code} value={code}>
                                                            {code}
                                                        </option>
                                                    ))}
                                                </select>
                                                <input
                                                    type="text"
                                                    id="vat"
                                                    {...register('vat')}
                                                    name="vat"
                                                    className="block w-full rounded-r border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                                                />
                                            </div>
                                            {errors?.vat && <p className={"text-red-600 text-sm"}>{errors.vat.message}</p>}
                                        </div>


                                        <div>
                                            <label
                                                htmlFor="address"
                                                className="text-xs font-semibold text-gray-500"
                                            >
                                                {t('qr.address_number')}
                                            </label>
                                            <input type="text"
                                                   id="address"
                                                   {...register('address')}
                                                   name="address"
                                                   className="mt-1 block w-full rounded border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                                            />
                                        </div>

                                        <div>
                                            <label
                                                htmlFor="postcode"
                                                className="text-xs font-semibold text-gray-500"
                                            >
                                                {t('qr.postal_code')}
                                            </label>
                                            <input type="text"
                                                   id="postcode"
                                                   {...register('postcode')}
                                                   name="postcode"
                                                   className="mt-1 block w-full rounded border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                                            />
                                        </div>

                                        <div>
                                            <label
                                                htmlFor="city"
                                                className="text-xs font-semibold text-gray-500"
                                            >
                                                {t('qr.city')}
                                            </label>
                                            <input type="text"
                                                   id="city"
                                                   {...register('city')}
                                                   name="city"
                                                   className="mt-1 block w-full rounded border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                                            />
                                        </div>
                                    </>
                                )
                            }

                            <div>
                                <label
                                    htmlFor="tos"
                                    className="text-xs font-semibold text-gray-500"
                                >
                                    <input type="checkbox"
                                           id="tos"
                                           name="tos"
                                           {...register('tos')}
                                           className="mr-4 rounded border-gray-300 bg-gray-50 outline-none transition"
                                    />
                                    {t('qr.by_placing_agree')}
                                    <a
                                       href="https://beta.exidofficial.com/tos" target={'_blank'}
                                       className="whitespace-nowrap text-primary underline hover:text-secondary"
                                    >
                                        {t('qr.tos')}
                                    </a>
                                </label>

                                {errors?.tos && <p className={"text-red-600 text-sm"}>{errors.tos.message}</p>}
                            </div>

                            <button type="submit"
                                    className="mt-4 inline-flex w-full items-center justify-center rounded bg-primary py-2.5 px-4 text-base font-semibold tracking-wide text-white text-opacity-80 outline-none ring-offset-2 transition hover:text-opacity-100 focus:ring-2 focus:ring-primary sm:text-lg">
                                {t('qr.place_order_button')}
                            </button>
                        </form>
                    </div>
                </div>
                <div className="relative col-span-full flex flex-col py-6 pl-8 pr-4 sm:py-12 lg:col-span-4 lg:py-24">
                    <img src={'/img/nav-logo.png'} className={"mx-auto h-10 z-50 mb-8"} alt={"Logo exid"}/>
                    <div className={'z-50 absolute top-2 right-2'}>
                        <LocaleSwitcher bgColor={'bg-[#FFF8EC]'}/>
                    </div>
                    <div>
                        <img
                            src="https://images.unsplash.com/photo-1533158307587-828f0a76ef46?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            alt="" className="absolute inset-0 h-full w-full object-cover"/>
                        <div
                            className="absolute inset-0 h-full w-full bg-gradient-to-t from-input to-primary opacity-95"></div>
                    </div>
                    <div>

                    </div>
                    <div className="relative">
                        <ul className="space-y-5">
                            <li className="flex justify-between">
                                <div className="inline-flex">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className={'h-24 text-white'}
                                         fill="#333333" version="1.1" id="Capa_1" viewBox="0 0 64 64">
                                        <g>
                                            <rect x="38" y="23" width="2" height="5"/>
                                            <rect x="42" y="23" width="2" height="5"/>
                                            <rect x="38" y="30" width="2" height="2"/>
                                            <rect x="42" y="30" width="2" height="2"/>
                                            <rect x="31" y="34" width="2" height="2"/>
                                            <rect x="26" y="54" width="2" height="2"/>
                                            <rect x="42" y="34" width="2" height="2"/>
                                            <rect x="38" y="38" width="2" height="2"/>
                                            <rect x="42" y="38" width="2" height="2"/>
                                            <path d="M2,2h3V0H1C0.448,0,0,0.448,0,1v4h2V2z"/>
                                            <path d="M63,0h-4v2h3v3h2V1C64,0.448,63.552,0,63,0z"/>
                                            <path d="M2,59H0v4c0,0.552,0.448,1,1,1h4v-2H2V59z"/>
                                            <path d="M62,62h-3v2h4c0.552,0,1-0.448,1-1v-4h-2V62z"/>
                                            <path
                                                d="M4,5v12c0,0.552,0.448,1,1,1h12c0.552,0,1-0.448,1-1V5c0-0.552-0.448-1-1-1H5C4.448,4,4,4.448,4,5z M6,6h10v10H6V6z"/>
                                            <path
                                                d="M13,8H9C8.448,8,8,8.448,8,9v4c0,0.552,0.448,1,1,1h4c0.552,0,1-0.448,1-1V9C14,8.448,13.552,8,13,8z M12,12h-2v-2h2V12z"/>
                                            <path
                                                d="M47,18h12c0.552,0,1-0.448,1-1V5c0-0.552-0.448-1-1-1H47c-0.552,0-1,0.448-1,1v12C46,17.552,46.448,18,47,18z M48,6h10v10   H48V6z"/>
                                            <path
                                                d="M55,8h-4c-0.552,0-1,0.448-1,1v4c0,0.552,0.448,1,1,1h4c0.552,0,1-0.448,1-1V9C56,8.448,55.552,8,55,8z M54,12h-2v-2h2V12z   "/>
                                            <path
                                                d="M17,46H5c-0.552,0-1,0.448-1,1v12c0,0.552,0.448,1,1,1h12c0.552,0,1-0.448,1-1V47C18,46.448,17.552,46,17,46z M16,58H6V48   h10V58z"/>
                                            <path
                                                d="M9,56h4c0.552,0,1-0.448,1-1v-4c0-0.552-0.448-1-1-1H9c-0.552,0-1,0.448-1,1v4C8,55.552,8.448,56,9,56z M10,52h2v2h-2V52z"/>
                                            <path d="M44,19h-7V7h-2v13c0,0.552,0.448,1,1,1h8V19z"/>
                                            <rect x="22" y="12" width="7" height="2"/>
                                            <rect x="26" y="8" width="7" height="2"/>
                                            <rect x="4" y="19" width="2" height="9"/>
                                            <path d="M18,25H8v2h9v4h2v-5C19,25.448,18.552,25,18,25z"/>
                                            <rect x="9" y="20" width="2" height="2"/>
                                            <rect x="21" y="18" width="2" height="8"/>
                                            <rect x="21" y="28" width="9" height="2"/>
                                            <path d="M61,29h-2v10h-3v2h4c0.552,0,1-0.448,1-1V29z"/>
                                            <rect x="26" y="18" width="2" height="2"/>
                                            <rect x="4" y="33" width="2" height="8"/>
                                            <rect x="4" y="42" width="9" height="2"/>
                                            <path d="M50,28h2v-4c0-0.552-0.448-1-1-1h-5v2h4V28z"/>
                                            <rect x="9" y="33" width="2" height="2"/>
                                            <rect x="20" y="32" width="2" height="8"/>
                                            <rect x="20" y="42" width="9" height="2"/>
                                            <rect x="8" y="38" width="9" height="2"/>
                                            <rect x="21" y="52" width="2" height="4"/>
                                            <rect x="20" y="48" width="9" height="2"/>
                                            <path d="M36,44v-5c0-0.552-0.448-1-1-1H25v2h9v4H36z"/>
                                            <rect x="26" y="32" width="2" height="2"/>
                                            <rect x="15" y="34" width="2" height="2"/>
                                            <rect x="59" y="53" width="2" height="7"/>
                                            <rect x="54" y="58" width="3" height="2"/>
                                            <rect x="54" y="52" width="2" height="4"/>
                                            <path d="M61,44c0-0.552-0.448-1-1-1H49v2h10v6h2V44z"/>
                                            <path d="M48,56v-5c0-0.552-0.448-1-1-1H36v2h10v4H48z"/>
                                            <rect x="50" y="47" width="7" height="2"/>
                                            <rect x="20" y="58" width="7" height="2"/>
                                            <rect x="30" y="51" width="2" height="9"/>
                                            <rect x="32" y="46" width="4" height="2"/>
                                            <path d="M47,36h5v-2h-4v-6h-2v7C46,35.552,46.448,36,47,36z"/>
                                            <rect x="50" y="30" width="5" height="2"/>
                                            <rect x="54" y="20" width="3" height="2"/>
                                            <rect x="59" y="20" width="2" height="5"/>
                                            <rect x="54" y="25" width="2" height="2"/>
                                            <path d="M39,17h4c0.552,0,1-0.448,1-1V3h-2v12h-3V17z"/>
                                            <path d="M24,5h15V3H23c-0.552,0-1,0.448-1,1v5h2V5z"/>
                                            <path d="M25,24h7c0.552,0,1-0.448,1-1v-8h-2v7h-6V24z"/>
                                            <rect x="34" y="23" width="2" height="4"/>
                                            <rect x="36" y="54" width="7" height="2"/>
                                            <rect x="36" y="58" width="9" height="2"/>
                                            <path d="M50,58h-2v2h3c0.552,0,1-0.448,1-1v-8h-2V58z"/>
                                            <rect x="16" y="42" width="2" height="2"/>
                                            <rect x="39" y="42" width="4" height="2"/>
                                            <rect x="46" y="38" width="2" height="3"/>
                                            <rect x="45" y="43" width="2" height="2"/>
                                            <rect x="39" y="46" width="3" height="2"/>
                                            <rect x="50" y="38" width="2" height="2"/>
                                            <rect x="54" y="34" width="3" height="2"/>
                                            <rect x="35" y="34" width="5" height="2"/>
                                            <rect x="34" y="29" width="2" height="3"/>
                                        </g>
                                    </svg>

                                    <div className="ml-3">
                                        <p className="text-base font-semibold text-white">{qrPackage?.name}</p>
                                        <p className="text-sm text-left font-medium text-white text-opacity-80">{qrPackage?.description}</p>
                                    </div>
                                </div>
                                <p className="text-sm font-semibold text-white">€{qrPackage?.price / 100} ({t('qr.incl_tax')})</p>
                            </li>
                        </ul>

                        <div className={'mt-4'}>
                            <label
                                htmlFor="map"
                                className="text-xs font-semibold text-white text-left"
                            >
                                {t('qr.promo_code')}
                            </label>
                            <input type="text"
                                   id="promocode"
                                   name="promocode"
                                   onChange={(e) => {
                                       setPromocode(e.target.value);
                                   }}
                                   className="mt-1 block w-full rounded border-gray-300 bg-gray-50 py-3 px-4 text-sm
                                   placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                            />
                            <button
                                onClick={(e) => {
                                    validatePromocode(promocode).then((res) => {
                                        if (!res.isValid) {
                                            toast.error(t('qr.code_node_valid'))
                                            setPromocode(null);
                                            setPromocodeValidated(null);
                                        } else {
                                            toast.success(t('qr.code_is_applied'))
                                            setPromocodeValidated(res.codeDetail)
                                        }
                                    })
                                }}
                                className="mt-4 inline-flex w-full items-center justify-center rounded bg-primary py-2.5 px-4 text-base font-semibold tracking-wide text-white text-opacity-80 outline-none ring-offset-2 transition hover:text-opacity-100 focus:ring-2 focus:ring-primary sm:text-lg">
                                {t('qr.apply_code_button')}
                            </button>
                        </div>


                        <div className="my-5 h-0.5 w-full bg-white bg-opacity-30"></div>
                        <div className="space-y-2">
                            {
                                promocodeValidated !== null && promocodeValidated.hasOwnProperty('percentage') && (
                                    <p className="flex justify-between text-lg font-bold text-white">
                                        <span>{promocodeValidated?.code}</span><span>- €{(qrPackage?.price / 100) * (promocodeValidated?.percentage / 100)}</span>
                                    </p>
                                )
                            }

                            <p className="flex justify-between text-lg font-bold text-white">
                                <span>{t('qr.total_price')}:</span><span>€{calculatePrice()}</span></p>
                        </div>
                    </div>
                    <div className="relative mt-10 text-white">
                        <h3 className="mb-5 text-lg font-bold">{t('qr.contact_title')}</h3>
                        <p className="text-sm font-semibold">info@exidofficial.com</p>
                    </div>
                </div>
            </div>
        </div>
    );
}