import HomePage from "../Views/HomePage";
import TermsOfService from "../Views/TermsOfservice"
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import Home from "../Views/Admin/Home";
import UsersIndex from "../Views/Admin/Users/UsersIndex";
import UsersDetail from "../Views/Admin/Users/UsersDetail";
import CategoryUpdate from "../Views/Admin/Categories/CategoryUpdate";
import CategoryCreate from "../Views/Admin/Categories/CategoryCreate";
import CategoryIndex from "../Views/Admin/Categories/CategoryIndex";
import CategoryDetail from "../Views/Admin/Categories/CategoryDetail";
import TagDetail from "../Views/Admin/Tags/TagDetail";
import TagUpdate from "../Views/Admin/Tags/TagUpdate";
import TagCreate from "../Views/Admin/Tags/TagCreate";
import TagIndex from "../Views/Admin/Tags/TagIndex";
import PromocodeIndex from "../Views/Admin/Promocodes/PromocodeIndex";
import PromocodeCreate from "../Views/Admin/Promocodes/PromocodeCreate";
import PromocodeUpdate from "../Views/Admin/Promocodes/PromocodeUpdate";
import PromocodeDetail from "../Views/Admin/Promocodes/PromocodeDetail";
import ProtectedRoutes from "../Helpers/ProtectedRoutes";
import TemplateUpdate from "../Views/Admin/Templates/TemplateUpdate";
import TemplateDetail from "../Views/Admin/Templates/TemplateDetail";
import TemplateCreate from "../Views/Admin/Templates/TemplateCreate";
import TemplateIndex from "../Views/Admin/Templates/TemplateIndex";
import LicenseTermPage from "../Views/LicenseTermPage";
import PrivacyPolicyPage from "../Views/PrivacyPolicyPage";
import CookiePolicyPage from "../Views/CookiePolicyPage";
import ReturnPolicyPage from "../Views/ReturnPolicyPage";
import EditorParent from "../Views/EditorParent";
import SubcategoryIndex from "../Views/Admin/Subategories/SubcategoryIndex";
import SubcategoryCreate from "../Views/Admin/Subategories/SubcategoryCreate";
import SubcategoryUpdate from "../Views/Admin/Subategories/SubcategoryUpdate";
import SubcategoryDetail from "../Views/Admin/Subategories/SubcategoryDetail";
import DemoPage from "../Views/DemoPage";
import B2bCompaniesIndex from "../Views/Admin/B2bCompanies/B2bCompaniesIndex";
import {VideoResultsIndex} from "../Views/Admin/VideoResults/VideoResultsIndex";
import {Olf} from "../Views/B2bPages/Olf";
import B2bCompaniesDetail from "../Views/Admin/B2bCompanies/B2bCompaniesDetail";
import B2bTermsOfservice from "../Views/B2bTermsOfservice";
import {SecretGarden} from "../Views/B2bPages/SecretGarden";
import {Strandfuif} from "../Views/B2bPages/Strandfuif";
import B2bCompanyTemplateStatistics from "../Views/Admin/B2bCompanies/B2bCompanyTemplateStatistics";
import {BuyQr} from "../Views/QrCodeTool/BuyQr";
import {Register} from "../Views/QrCodeTool/Register";
import {Login} from "../Views/QrCodeTool/Login";
import {UploadFiles} from "../Views/QrCodeTool/UploadFiles";
import {FileListPage} from "../Views/QrCodeTool/FileListPage";
import {ForgotPassword} from "../Views/QrCodeTool/ForgotPassword";

export function AppRouter(){
    const host = window.location.host;
    const subdomain = host.split('.')[0];
    const b2bSubDomains = ['openluchtfuif','strandfuif', '10yearsofsecretgarden'];

    return (
        <>
            <Router>
                <Routes>
                    {
                        b2bSubDomains.includes(subdomain) && (
                            <>
                                {
                                    subdomain === 'openluchtfuif' && (
                                        <>
                                            <Route exact path="/b2b-tos" element={<B2bTermsOfservice/>}></Route>
                                            <Route path="*" element={<Olf />} />
                                        </>
                                    )
                                }

                                {
                                    subdomain === '10yearsofsecretgarden' && (
                                        <>
                                            <Route exact path="/b2b-tos" element={<B2bTermsOfservice/>}></Route>
                                            <Route path="*" element={<SecretGarden />} />
                                        </>
                                    )
                                }

                                {
                                    subdomain === 'strandfuif' && (
                                        <>
                                            <Route exact path="/b2b-tos" element={<B2bTermsOfservice/>}></Route>
                                            <Route path="*" element={<Strandfuif />} />
                                        </>
                                    )
                                }
                            </>
                        )
                    }

                    {
                        !b2bSubDomains.includes(subdomain) && (
                            <>
                                <Route exact path="/" element={<HomePage/>}></Route>

                                <Route exact path="/festivaldemo" element={<DemoPage/>}></Route>
                                <Route exact path="/demo" element={<DemoPage/>}></Route>

                                <Route exact path="/olf" element={<Olf/>}></Route>

                                <Route exact path="/tos" element={<TermsOfService/>}></Route>
                                <Route exact path="/license-term" element={<LicenseTermPage/>}></Route>
                                <Route exact path="/privacy-policy" element={<PrivacyPolicyPage/>}></Route>
                                <Route exact path="/cookie-policy" element={<CookiePolicyPage/>}></Route>
                                <Route exact path="/return-policy" element={<ReturnPolicyPage/>}></Route>

                                <Route element={<ProtectedRoutes roles={['admin', 'b2b_admin']}/>}>
                                    <Route path="/admin" element={<Home/>}>

                                        <Route element={<ProtectedRoutes roles={['admin']}/>}>
                                            <Route path="users" element={<UsersIndex/>}></Route>
                                            <Route path="users/:id" element={<UsersDetail/>}></Route>

                                            <Route path="categories" element={<CategoryIndex/>}></Route>
                                            <Route path="categories/create" element={<CategoryCreate/>}></Route>
                                            <Route path="categories/:id/update" element={<CategoryUpdate/>}></Route>
                                            <Route path="categories/:id" element={<CategoryDetail/>}></Route>

                                            <Route path="subcategories" element={<SubcategoryIndex/>}></Route>
                                            <Route path="subcategories/create" element={<SubcategoryCreate/>}></Route>
                                            <Route path="subcategories/:id/update" element={<SubcategoryUpdate/>}></Route>
                                            <Route path="subcategories/:id" element={<SubcategoryDetail/>}></Route>

                                            <Route path="tags" element={<TagIndex/>}></Route>
                                            <Route path="tags/create" element={<TagCreate/>}></Route>
                                            <Route path="tags/:id/update" element={<TagUpdate/>}></Route>
                                            <Route path="tags/:id" element={<TagDetail/>}></Route>

                                            <Route path="templates" element={<TemplateIndex/>}></Route>
                                            <Route path="templates/create" element={<TemplateCreate/>}></Route>
                                            <Route path="templates/:id/update" element={<TemplateUpdate/>}></Route>
                                            <Route path="templates/:id" element={<TemplateDetail/>}></Route>

                                            <Route path="promo-codes" element={<PromocodeIndex/>}></Route>
                                            <Route path="promo-codes/create" element={<PromocodeCreate/>}></Route>
                                            <Route path="promo-codes/:id/update" element={<PromocodeUpdate/>}></Route>
                                            <Route path="promo-codes/:id" element={<PromocodeDetail/>}></Route>

                                            <Route path="b2b-companies" element={<B2bCompaniesIndex/>}></Route>
                                        </Route>

                                        <Route path="b2b-companies/:id" element={<B2bCompaniesDetail/>}></Route>
                                        <Route path="b2b-companies/:id/templates/:templateId/statistics" element={<B2bCompanyTemplateStatistics/>}></Route>

                                        <Route path="video-results" element={<VideoResultsIndex />}/>
                                    </Route>
                                </Route>


                                <Route exact path="/qr">
                                    <Route exact path="login" element={<Login/>}></Route>
                                    <Route exact path="register" element={<Register/>}></Route>
                                    <Route exact path="forgot-password" element={<ForgotPassword/>}></Route>

                                    <Route exact path="upload/:key" element={<UploadFiles/>}></Route>
                                    <Route exact path=":key/files" element={<FileListPage/>}></Route>
                                </Route>

                                <Route element={<ProtectedRoutes roles={['user','admin', 'b2b_admin']} isQr={true}/>}>
                                    <Route exact path="/qr">
                                        <Route exact path="buy" element={<BuyQr/>}></Route>
                                    </Route>
                                </Route>

                                <Route exact path="/editor/:templateId" element={<EditorParent/>}></Route>
                            </>
                        )
                    }

                </Routes>
            </Router>
        </>
    )
}