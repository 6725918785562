import {
    downloadAllFilesDropboxAPI,
    getAllFilesDropbxoAPI,
    getPaymentUrlAPI,
    uploadMediaAPI,
    validatePromocodeAPI
} from "../../Api/qrApi";
import {AuthClient} from "../../Api/HttpClient";

export const createQrSlice = (set) => ({
    fetchPaymentUrl: async (data) => {
        return await getPaymentUrlAPI(data);
    },
    downloadZip: async (id) => {
        try {
            await AuthClient().get(`dropbox/${id}/download`);
        } catch(e) {
            console.error(e.response);
        }
    },
    downloadSingleFile: async (key, id, filename) => {
        try {
            const response = await AuthClient().get(`dropbox/${key}/files/${id}/download`, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click()
        } catch(e) {
            console.error(e.response);
        }
    },
    fetchAllFilesForDropbox: async (id) => {
        return await getAllFilesDropbxoAPI(id);
    },
    downloadAllFilesForDropbox: async (id) => {
        return await downloadAllFilesDropboxAPI(id);
    },
    uploadQrToolMedia: async (key, data, reference) => {
        return await uploadMediaAPI(key, data, reference);
    },
    validatePromocode: async (code) => {
        return await validatePromocodeAPI(code);
    },
    deleteFileFromDropbox: async (key, id) => {
        try {
            await AuthClient().delete(`dropbox/${key}/files/${id}/delete`);
        } catch(e) {
            console.error(e.response);
        }
    },
});