export default function Banner() {
    return (
        <>
            <div className="h-[80vh] flex flex-col justify-center text-white gap-1 relative" style={{zIndex:-1}}>
                <video autoPlay muted loop id="videoExidWinter" className={"absolute -z-1 w-full top-0 object-cover h-[80vh]"}>
                    <source src="img/exid-banner.mp4" type="video/mp4" />
                </video>

                <div className={"z-10"}>
                    <h5 className="text-center text-5xl font-bold uppercase">make great memories <br/> treasure them forever</h5>
                    <div className="flex flex-row justify-center my-4">
                        <form className="flex flex-row text-black h-full container">
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}