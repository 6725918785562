import {useEffect, useState} from "react";
import {getAllCategories} from "../../Api/categoriesApi";
import { useTranslation } from 'react-i18next';
import {getAllSubCategories} from "../../Api/subCategoriesApi";

export default function HomeSidebarNavigation({selectedCategories, onCategorySelected, setCategoryNames}) {
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    let [subCategoryHeights, setSubCategoryHeights] = useState([]);
    let [subCategoryDisplay, setSubCategoryDisplay] = useState([]);
    const { t, i18n } = useTranslation();

    function addCategoryToFilter(categoryId) {
        if(!selectedCategories.includes(categoryId)) {
            selectedCategories.push(categoryId);
            onCategorySelected(selectedCategories);
        }
    }

    useEffect(() => {
        (async function() {
            setCategories(await getAllCategories());

            getAllSubCategories().then(res => {
                setCategoryNames(res);
                setSubCategories(res);
                initialiseHeights(res);
                initialiseDisplays(res);
            });
        })()

    }, [setCategoryNames])

    const initialiseHeights = (subCats) => {
        const list = [];
        subCats.map(subCat => list[subCat.id] = 0)
        setSubCategoryHeights(list);
    }

    const initialiseDisplays = (subCats) => {
        const list = [];
        subCats.map(subCat => list[subCat.id] = 'none')
        setSubCategoryDisplay(list);
    }

    const setSubCategoriesVisible = (subCategoryList) => {
        const subCategoryHeightList = [...subCategoryHeights]
        const subCategoryDisplayList = [...subCategoryDisplay]

        subCategoryList.map(subCategory => {
            if(subCategoryHeightList[subCategory.id] === 0) {
                subCategoryHeightList[subCategory.id] = '100%'
                subCategoryDisplayList[subCategory.id] = 'block'
            } else {
                subCategoryHeightList[subCategory.id] = 0;
                subCategoryDisplayList[subCategory.id] = 'none';
            }
            return null;
        })

        setSubCategoryHeights(subCategoryHeightList)
        setSubCategoryDisplay(subCategoryDisplayList)
    }

    const setSubCategoriesVisibleForCategory = (categoryId) => {
        const list = subCategories.filter(function(subCategory)
        {
            return subCategory.category_id === categoryId;
        });

        setSubCategoriesVisible(list);
    }

    const getSubcategoryLanguageIndex = () => {
        const language = i18n.language;

        if(language.toLowerCase().includes('en')) return 'en';
        if(language.toLowerCase().includes('fr')) return 'fr';
        if(language.toLowerCase().includes('nl')) return 'nl';

        return 'en'
    }

    return (
        <>
            <div className={"w-full flex flex-col justify-center align-middle gap-4 sticky"}>
                <div>
                    <p className="text-primary text-3xl">{t('home.filter_tags')}</p>
                    <div className={"border-b border-primary p-1 pt-2 w-full"}></div>
                </div>

                    { categories.length > 0 &&
                        categories.map(
                            (category, i) => {
                                return (
                                    <div key={`sidebar_cat_${category.id}`}>
                                        <div className={"text-left text-xl ml-4 text-gray-400 cursor-pointer hover:text-primary"} key={`category_${category.id}`} onClick={() => {setSubCategoriesVisibleForCategory(category.id)}}>{category.allTranslationsName[getSubcategoryLanguageIndex()]}</div>
                                            <div className={"flex flex-wrap justify-between w-[90%] m-auto"}>
                                            {category.subcategories.map(
                                                    (subCategory, i) => {
                                                        return (
                                                            <div className={"m-1"} key={`sidebar_sub_${subCategory.id}`}>
                                                                <div style={{height: subCategoryHeights[subCategory.id], display: subCategoryDisplay[subCategory.id]}} key={`mobile_sub_category_${subCategory.id}`} onClick={() => addCategoryToFilter(subCategory.id)} className={"text-white cursor-pointer hidden w-full h-full p-2 bg-primary rounded"}>
                                                                    {subCategory.allTranslationsName
                                                                        [getSubcategoryLanguageIndex()]
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                )}
                                            </div>
                                    </div>
                                )
                            }
                        )}
            </div>

        </>
    )
}