import Footer from "../Components/General/Footer";
import BareHomeNavigation from "../Components/Navigation/BareHomeNavigation";
import TermsOfService from "../Components/legal/TermsOfService";

export default function TermsOfservice() {
    return (
        <>
            <BareHomeNavigation />
            <TermsOfService />
            <Footer />
        </>
    );
}