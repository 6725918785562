import {useEffect, useState} from "react";
import {useBoundStore} from "../../../Store/store";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {LineChart} from "../../../Components/Charts/LineChart";
import {
    getChartDataFromStatisticsForIndexes,
    getLabelForIndex,
    possibleIndex
} from "../../../Helpers/PlausibleDataHelper";
import {deepCloneObject} from "../../../Helpers/GeneralHelpers";
import dayjs from "dayjs";
import toast from "react-hot-toast";

export default function B2bCompanyTemplateStatistics() {
    const {id, templateId} = useParams();

    const getB2bCompany = useBoundStore((state) => state.getB2bCompany);
    const fetchStatisticsForCompany = useBoundStore((state) => state.fetchStatisticsForCompany);
    const statistics = useBoundStore((state) => state.statistics);
    const [company, setCompany] = useState(getB2bCompany(id));
    const [selectedData, setSelectedData] = useState(['visits']);
    const [fromRange, setFromRange] = useState(dayjs().subtract(30, 'day'))
    const [toRange, setToRange] = useState(dayjs())

    useEffect(() => {
        fetchStatisticsForCompany({
            companyId: id,
            templateId: templateId,
            from: fromRange.format('YYYY-MM-DD'),
            to: toRange.format('YYYY-MM-DD')
        }).then(() => {})
    }, [fetchStatisticsForCompany, id, templateId, fromRange, toRange])

    useEffect(() => {
        setCompany(getB2bCompany(id));
    },[getB2bCompany, id]);

    const getFilteredStatsDataForPlausible = () => {
        const cloned = deepCloneObject(statistics);
        delete cloned['countUploaded'];
        delete cloned['countDenied'];
        delete cloned['countRendered'];
        return Object.values(cloned);
    }

    const addDataToChart = (index) => {
        let cloned = deepCloneObject(selectedData);
        if(cloned.includes(index)){
            cloned.splice(cloned.indexOf(index), 1);
        } else {
            cloned.push(index);
        }
        setSelectedData(cloned);
    }

    const getConversionRatio = () => {
        if(
            statistics?.countUploaded?.length === undefined ||
            statistics?.countUploaded?.length === null ||
            statistics?.countUploaded?.length === 0 ||
            statistics?.countUploaded?.length === "0" ||
            statistics?.countRendered?.length === undefined ||
            statistics?.countRendered?.length === null ||
            statistics?.countRendered?.length === 0 ||
            statistics?.countRendered?.length === "0"
        ) return '0 %'

        return ((statistics.countRendered.length / statistics.countUploaded.length) * 100).toFixed(2) + ' %'
    }

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>Statistics</h1>
                <Link to={`/admin/b2b-companies/${id}`} className={"py-2 px-4 ease-in-out duration-300 text-secondary hover:text-primary cursor-pointer transition-all"}>
                    <FontAwesomeIcon icon={icon({name: 'arrow-left'})} className={"mr-2"}/>
                    Back to B2b companies
                </Link>
            </div>

            <div className={"grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4"}>
                <div className={"shadow rounded p-6 relative text-right m-4"}>
                    <div className={"rounded p-4 absolute -top-2 left-3 bg-primary shadow flex flex-col justify-center"}>
                        <FontAwesomeIcon icon={icon({name: 'upload'})} className={'text-sm text-white'}/>
                    </div>
                    <p className={"m-0 p-0 text-md font-light w-full"}>Amount uploaded</p>
                    <p className={"m-0 p-0 font-bold"}>{statistics?.countUploaded?.length}</p>
                </div>
                <div className={"shadow rounded p-6 relative text-right m-4"}>
                    <div className={"rounded p-4 absolute -top-2 left-3 bg-primary shadow flex flex-col justify-center"}>
                        <FontAwesomeIcon icon={icon({name: 'ban'})} className={'text-sm text-white'}/>
                    </div>
                    <p className={"m-0 p-0 text-md font-light w-full"}>Amount denied</p>
                    <p className={"m-0 p-0 font-bold"}>{statistics?.countDenied?.length}</p>
                </div>
                <div className={"shadow rounded p-6 relative text-right m-4"}>
                    <div className={"rounded p-4 absolute -top-2 left-3 bg-primary shadow flex flex-col justify-center"}>
                        <FontAwesomeIcon icon={icon({name: 'film'})} className={'text-sm text-white'}/>
                    </div>
                    <p className={"m-0 p-0 text-md font-light w-full"}>Amount rendered</p>
                    <p className={"m-0 p-0 font-bold"}>{statistics?.countRendered?.length}</p>
                </div>
                <div className={"shadow rounded p-6 relative text-right m-4"}>
                    <div className={"rounded p-4 absolute -top-2 left-3 bg-primary shadow flex flex-col justify-center"}>
                        <FontAwesomeIcon icon={icon({name: 'upload'})} className={'text-sm text-white'}/>
                    </div>
                    <p className={"m-0 p-0 text-md font-light w-full"}>% Rend. - Upload </p>
                    <p className={"m-0 p-0 font-bold"}>{getConversionRatio()}</p>
                </div>
            </div>

            <div className={"my-3 shadow rounded p-6 relative text-right m-4"}>
                <div className={"rounded p-4 absolute -top-2 left-3 bg-primary shadow flex flex-col justify-center"}>
                    <FontAwesomeIcon icon={icon({name: 'chart-line'})} className={'text-sm text-white'}/>
                </div>

                <div className={'w-full flex flex-row space-x-4 justify-end'}>
                    <input
                        className="
                        text-black w-fit
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        id={"fromRange"}
                        name={"fromRange"}
                        value={fromRange.format('YYYY-MM-DD')}
                        onChange={(e) => {
                            if(dayjs(e.target.value).isBefore(toRange)) {
                                setFromRange(dayjs(e.target.value));
                            } else {
                                toast.error('From date should be smaller than to date')
                            }
                        }}
                        type={"date"}
                    />
                    <p className={"flex flex-col justify-center"}>-</p>
                    <input
                        className="
                        text-black w-fit
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        id={"toRange"}
                        value={toRange.format('YYYY-MM-DD')}
                        onChange={(e) => {
                            if(fromRange.isBefore(dayjs(e.target.value))) {
                                setToRange(dayjs(e.target.value));
                            } else {
                                toast.error('To date should be bigger than from date')
                            }
                        }}
                        name={"toRange"}
                        type={"date"}
                    />
                </div>

                <div className={"flex flex-wrap justify-evenly mb-1 mt-4"}>
                    {
                        possibleIndex.map((item) => {
                            return <div className={"shadow w-fit m-1 p-2 flex flex-row justify-between cursor-pointer"} onClick={() => addDataToChart(item)}>
                                <div className={"m-auto"}>
                                    {getLabelForIndex(item)}
                                </div>
                                <div className={"m-auto ml-4"}>
                                    <input type={'checkbox'} checked={selectedData.includes(item)} />
                                </div>
                            </div>
                        })
                    }
                </div>
                {
                    Object.keys(getFilteredStatsDataForPlausible())?.length > 0 && (
                        <LineChart
                            labels={getChartDataFromStatisticsForIndexes(selectedData, getFilteredStatsDataForPlausible())?.labels}
                            data={getChartDataFromStatisticsForIndexes(selectedData, getFilteredStatsDataForPlausible())?.datasets}
                        />
                    )
                }
            </div>
        </>
    )
}