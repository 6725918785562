import {AuthClient} from "./HttpClient";

export function getAllCategoriesPaginated(page = 1) {
    return AuthClient()
        .get(`categories?page=${page}`).then((res) => {
            return res.data
        });
}

export function getAllCategories() {
    return AuthClient()
        .get('categories-all').then((res) => {
            return res.data
        });
}

export function getCategory(id) {
    return AuthClient()
        .get(`categories/${id}`).then((res) => {
            return res.data
        })
}

export function deleteCategory(id){
    return AuthClient()
        .delete(`categories/${id}`).then((res) => {
            return res.data
        })
}

export function updateCategory(id, params){
    return AuthClient()
        .patch(`categories/${id}`, params).then((res)=>{
            return res.data
        })
}

export function createCategory(params){
    return AuthClient()
        .post(`categories`, params).then((res)=>{
            return res.data
        })
}