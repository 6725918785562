import React from 'react';
import './css/demoeditor.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowRotateLeft, faPause, faPlay} from '@fortawesome/free-solid-svg-icons'
import {Slider} from "@mui/material";
import {Translation} from "react-i18next";

class DemoEditorSource extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMuted: false,
            timings: [0, this.props.duration],
            playing: false,
            currently_grabbed: {"index": 0, "type": "none"},
            difference: 0.2,
            deletingGrabber: false,
            current_warning: null,
            imageUrl: "",
        }
        this.playVideo = React.createRef();
        this.progressBar = React.createRef();
        this.playBackBar = React.createRef();
    }

    warnings = {
        "delete_grabber": (<div>Please click on the grabber (either start or end) to delete it</div>)
    }

    componentDidMount = () => {
        const videoElement = document.getElementById(this.props.namez);
        videoElement.pause();
        videoElement.load();
    }

    reset = () => {
        this.playVideo.current.pause()
        this.setState({
            isMuted: false,
            timings: [0,  this.props.duration],
            playing: false,
            currently_grabbed: {"index": 0, "type": "none"},
            difference: 0.2,
            deletingGrabber: false,
            current_warning: null,
            imageUrl: "",
            range: [0, this.props.duration]
        }, () => {
            this.playVideo.current.currentTime = this.state.timings[0];
        })

        this.props.VideoHelper.setTimingForVideo(this.props.index,0);

    }

    play_pause = () => {
        var self = this
        if(this.state.playing){
            this.playVideo.current.pause()
        }
        else{
            if ((self.playVideo.current.currentTime >= self.state.timings[self.state.timings.length-1].end)){
                self.playVideo.current.pause()
                self.setState({playing: false, currently_grabbed: {"index": 0, "type": "start"}}, () => {
                    self.playVideo.current.currentTime = self.state.timings[0].start;
                    self.progressBar.current.style.left = `${self.state.timings[0].start / self.playVideo.current.duration * 100}%`;
                    self.progressBar.current.style.width = "0%";
                })
            }
            this.playVideo.current.play()
        }
        this.setState({playing: !this.state.playing})
    }

    setTimingsForVideo = (event, value) => {
        this.props.VideoHelper.setTimingForVideo(this.props.index,value[0]);
    }

    onChangeVideoTimings = (
        event,
        newValue,
        activeThumb
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb !== 0) {
            const to = Math.min(newValue[1], this?.playVideo?.current?.duration);
            if((to - this.props.duration) >= 0){
                this.setState({timings: [to - this.props.duration, to]});
                this.playVideo.current.currentTime = to - this.props.duration;
            }
        } else {
            const from = Math.max(newValue[0], 0);
            if((from + this.props.duration) <= this?.playVideo?.current?.duration){
                this.setState({timings: [from, from + this.props.duration]});
                this.playVideo.current.currentTime = from;
            }
        }
    }

    render = () => {
        return(
            <div className={`wrapper ${this.props.namez} m-auto relative`}>

                <div
                    className={`h-full w-full absolute top-0 left-0 p-4 transition-all`}
                    id={`block_video_ui_${this.props.index}`}
                >
                </div>

                <div
                    className={"h-full w-full absolute top-0 left-0 p-4 transition-all"}
                >
                    <div
                        className={`h-full bg-black z-150 rounded-2xl transition-all flex flex-col justify-center items-center z-50`}
                        style={{'z-index':"999 !important"}}
                        id={`loading_screen_video_${this.props.index}`}
                    >
                        <svg className={"mx-auto max-h-32"} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="234px" height="234px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <text x="40" y="55" fill="#FFF" fontSize={"12px"} id={`prep_video_percentage_${this.props.index}`}>0%</text>

                            <g transform="translate(50,50)">
                                <g transform="scale(0.86)">
                                    <g transform="translate(-50,-50)">
                                        <g>
                                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="360 50 50;0 50 50" keyTimes="0;1" dur="1.923076923076923s" keySplines="0.7 0 0.3 1" calcMode="spline"></animateTransform>
                                            <path fill="#ee9922" d="M54.3,28.1h34.2c-4.5-9.3-12.4-16.7-21.9-20.8L45.7,28.1L54.3,28.1L54.3,28.1z"></path>
                                            <path fill="#4e4e4e" d="M61.7,7.3C51.9,4,41.1,4.2,31.5,8.1v29.5l6.1-6.1L61.7,7.3C61.7,7.3,61.7,7.3,61.7,7.3z"></path>
                                            <path fill="#124fc9" d="M28.1,11.6c-9.3,4.5-16.7,12.4-20.8,21.9l20.8,20.8v-8.6L28.1,11.6C28.1,11.6,28.1,11.6,28.1,11.6z"></path>
                                            <path fill="#2299ee" d="M31.5,62.4L7.3,38.3c0,0,0,0,0,0C4,48.1,4.2,58.9,8.1,68.5h29.5L31.5,62.4z"></path>
                                            <path fill="#ee9922" d="M45.7,71.9H11.5c0,0,0,0,0,0c4.5,9.3,12.4,16.7,21.9,20.8l20.8-20.8H45.7z"></path>
                                            <path fill="#4e4e4e" d="M62.4,68.5L38.3,92.6c0,0,0,0,0,0c9.8,3.4,20.6,3.1,30.2-0.8V62.4L62.4,68.5z"></path>
                                            <path fill="#124fc9" d="M71.9,45.7v8.6v34.2c0,0,0,0,0,0c9.3-4.5,16.7-12.4,20.8-21.9L71.9,45.7z"></path>
                                            <path fill="#2299ee" d="M91.9,31.5C91.9,31.5,91.9,31.5,91.9,31.5l-29.5,0l0,0l6.1,6.1l24.1,24.1c0,0,0,0,0,0 C96,51.9,95.8,41.1,91.9,31.5z"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>

                        <Translation>
                            {
                                t => <p className={"text-white"}>{t('editor.preparing_video')}</p>
                            }
                        </Translation>
                    </div>
                </div>

                <video className="video" id={this.props.namez} muted={this.state.isMuted} ref={this.playVideo} onClick={this.play_pause.bind(this)} >
                    <source src={this.props.videoUrl} type="video/mp4" />
                </video>

                <div className="playback" id={`video_playback_${this.props.index}`}>
                    <Slider
                        getAriaLabel={() => 'Minimum distance'}
                        value={this.state.timings}
                        onChange={this.onChangeVideoTimings}
                        disableSwap
                        className={'w-full mt-2'}
                        max={this?.playVideo?.current?.duration}
                        onChangeCommitted={this.setTimingsForVideo}
                        step={0.1}
                        thumbColorPrimary={'#fff'}
                        ref={`slider-timings-${this.props.index}`}
                    />
                </div>

                <div className="controls relative z-1" id={`video_controls_${this.props.index}`}>
                    <div className="player-controls z-1">
                        <button className="settings-control" title="Reset Video" onClick={this.reset}><FontAwesomeIcon icon={faArrowRotateLeft} /></button>
                        <button id={`play_button_${this.props.index}`} className="play-control" title="Play/Pause" onClick={this.play_pause.bind(this)} >{this.state.playing ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} /> }</button>
                    </div>
                </div>
                {this.state.current_warning !== null ? <div className={"warning"}>{this.warnings[this.state.current_warning]}</div> : ""}
            </div>
        )
    }
}

export default DemoEditorSource