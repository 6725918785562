import {AuthClient} from "./HttpClient";
import {useBoundStore} from "../Store/store";
export function uploadMedia(file, index, user = null, companyId = null){
    const setUploadProgress = useBoundStore.getState().setUploadProgress;

    const form = new FormData();
    form.append('file', file);

    if(companyId !== null) {
        form.append('b2b_company_id', companyId);
    }

    if(user?.user?.id){
        return AuthClient()
            .post(`/users/${user.user.id}/files`, form,{
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                    setUploadProgress(index,progress);
                }
            }).then((res) => {
                return res.data
            }).catch((err) => {
                return err.response.data;
            })
    } else {
        return AuthClient()
            .post(`upload`, form,{
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                    setUploadProgress(index,progress);
                }
            }).then((res) => {
                return res.data
            }).catch((err) => {
                return err.response.data;
            })
    }
}