import React, {useCallback, useEffect, useRef, useState} from "react";
import {Slider} from "@mui/material";
import {useBoundStore} from "../../../Store/store";

export const TimelineSlider = (props) => {
    const {
        index,
        videoLength,
        videoRef
    } = props;

    const sliderRef = useRef(null);
    const videoTimings = useBoundStore((state) => state.videoTimings);
    const videoTotalTimes = useBoundStore((state) => state.videoTotalTimes);
    const setTimingForVideo = useBoundStore((state) => state.setTimingForVideo);

    let [totalVideoDuration,setTotalVideoDuration] = useState(null);

    useEffect(() => {
        if(videoTotalTimes[index] !== undefined && videoTotalTimes[index] !== null){
            setTotalVideoDuration(videoTotalTimes[index]);
        }
    }, [index, videoTotalTimes]);

    const onChangeVideoTimings = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb !== 0) {
            const to = Math.min(newValue[1], totalVideoDuration);
            if((to - videoLength) >= 0){
                setTimingsForVideo(null, [to - videoLength, to])
                videoRef.current.currentTime = (to - videoLength) / 1000;
            }
        } else {
            const from = Math.max(newValue[0], 0);
            if((from + videoLength) <= totalVideoDuration) {
                setTimingsForVideo(null, [from, from + videoLength])
                videoRef.current.currentTime = from / 1000;
            }
        }
    }

    const setTimingsForVideo = (event, value) => {
        setTimingForVideo(index, value[0], value[1]);
    }

    const getTransformedTimings = () => {
        return [videoTimings[index].start, (videoTimings[index].end !== null ? videoTimings[index].end : videoLength)];
    }

    return (
        <div>
            <Slider
                getAriaLabel={() => 'Minimum distance'}
                value={getTransformedTimings()}
                onChange={onChangeVideoTimings}
                disableSwap
                className={'w-full mt-2'}
                onChangeCommitted={setTimingsForVideo}
                max={totalVideoDuration}
                step={0.1}
                ref={sliderRef}
            />
        </div>
    )
}