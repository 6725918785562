import Button from "../../Forms/Button";
import {useTranslation} from "react-i18next";
import {useContext, useState} from "react";
import {login, register} from "../../../Api/authenticationApi";
import toast from "react-hot-toast";
import AuthContext from "../../../Helpers/AuthenticationHelper";

export default function RegisterForm(props) {
    const {
        handleCloseClick,
        changePage
    } = props;
    const { t } = useTranslation();
    const {setLoggedInUser} = useContext(AuthContext);
    const [errors, setErrors] = useState([]);
    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [passwordRepeat, setPasswordRepeat] = useState();
    const [approvedTos, setApprovedTos] = useState(false);

    const handleRegister = async () => {
        if(!approvedTos) {
            setErrors({
                'approved_tos': 'You need to accpet the terms in order to create an account'
            });
            return;
        }

        const newUser = {
            firstname: firstname,
            lastname: lastname,
            email:email,
            password:password,
            password_confirmation: passwordRepeat,
            approved_tos: approvedTos === true ? 1 : 0
        }

        const response = await register(newUser);
        if('errors' in response){
            setErrors(response.errors);

            toast.error('Not all data has been filled in correctly',{
                position: "top-right"
            });
            return false;
        }else{
            handleCloseClick();

            const loginResponse = await login(email,password);
            await setLoggedInUser(loginResponse.access_token, loginResponse.refresh_token);

            toast.success(`Welcome to EXID ${firstname}`,{
                position: "top-right"
            });
        }
    }

    return (
        <div>
            <div className="flex flex-row justify-between bg-primary py-3 px-4 border-b border-white rounded-tl-lg rounded-tr-lg">
                <h3 className="text-xl font-medium text-white my-auto">{t('authentication.register_title')}</h3>
                <button type="button" onClick={handleCloseClick}
                        className="text-white bg-transparent hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule={"evenodd"} d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule={"evenodd"}></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>


            <div className="py-6 px-6 lg:px-8 bg-secondary">
                <form className="space-y-6" action="#">
                    <div>
                        <label htmlFor="firstname" className="block mb-2 text-sm font-medium text-white text-left">{t('form_labels.firstname')}</label>
                        <input type="text" name="firstname" id="firstname" value={firstname}
                               onChange={(e) => {setFirstname(e.target.value)}}
                               className="bg-inputsecondary text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                        {
                            'firstname' in errors &&
                            <span className={"text-red-600 text-left"}>{errors['firstname']}</span>
                        }
                    </div>
                    <div>
                        <label htmlFor="lastname" className="block mb-2 text-sm font-medium text-white text-left">{t('form_labels.lastname')}</label>
                        <input type="text" name="lastname" id="lastname" value={lastname}
                               onChange={(e) => {setLastname(e.target.value)}}
                               className="bg-inputsecondary text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                        {
                            'lastname' in errors &&
                            <span className={"text-red-600 text-left"}>{errors['lastname']}</span>
                        }
                    </div>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-white text-left">{t('form_labels.email')}</label>
                        <input type="text" name="email" id="email" value={email}
                               onChange={(e) => {setEmail(e.target.value)}}
                               className="bg-inputsecondary text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                        {
                            'email' in errors &&
                            <span className={"text-red-600 text-left"}>{errors['email']}</span>
                        }
                    </div>
                    <div>
                        <label htmlFor="password"
                               className="block mb-2 text-sm font-medium text-white text-left">{t('form_labels.password')}</label>
                        <input type="password" name="password" id="password" value={password}
                               onChange={(e) => {setPassword(e.target.value)}}
                               className="bg-inputsecondary text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                               required />
                        {
                            'password' in errors &&
                            <span className={"text-red-600 text-left"}>{errors['password']}</span>
                        }
                    </div>
                    <div>
                        <label htmlFor="passwordRepeat"
                               className="block mb-2 text-sm font-medium text-white text-left">{t('form_labels.password_repeat')}</label>
                        <input type="password" name="passwordRepeat" id="passwordRepeat" value={passwordRepeat}
                               onChange={(e) => {setPasswordRepeat(e.target.value)}}
                               className="bg-inputsecondary text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                               required />
                        {
                            'password_confirmation' in errors &&
                            <span className={"text-red-600 text-left"}>{errors['password_confirmation']}</span>
                        }
                    </div>
                    <div>
                        <label htmlFor="approvedTos"
                               className="block mb-2 text-sm font-medium text-white text-left">{t('form_labels.approved_tos')}</label>
                        <input type="checkbox" name="approvedTos" id="approvedTos" value={approvedTos}
                               onChange={(e) => {setApprovedTos(e.target.checked)}}
                               className="bg-inputsecondary text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                               required />
                        {
                            'approved_tos' in errors &&
                            <span className={"text-red-600 text-left"}>{errors['approved_tos']}</span>
                        }
                    </div>

                    <Button
                        buttonText={t('authentication.register_button')}
                        clickEvent={handleRegister}
                    >
                    </Button>

                    <div className="text-sm font-medium text-gray-500 dark:text-gray-300 cursor-pointer" onClick={() => {
                        changePage('login')
                    }}>
                        {t('authentication.back_to_login')}
                    </div>
                </form>
            </div>
        </div>
    )
}