import {axiosClient} from "../Api/HttpClient";
import {useState, createContext} from "react";

const AuthContext = createContext({});

export const AuthenticationHelper = ({children}) => {
    const getLoggedInUser = () => {
        const userFromStorage = JSON.parse(localStorage.getItem('exid_user'));
        const accessToken = localStorage.getItem('exid_user_access_token');
        const refreshToken = localStorage.getItem('exid_user_refresh_token');

        if(
            userFromStorage !== undefined && userFromStorage !== null &&
            accessToken !== undefined && accessToken !== null &&
            refreshToken !== undefined && refreshToken !== null
        ){
            return {
                user: userFromStorage,
                accessToken: accessToken,
                refreshToken: refreshToken
            };
        } else {
            return null;
        }
    }

    const setLoggedInUser = async (accessToken, refreshToken) => {
        const loggedInUser = await axiosClient().get('me',{
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        });

        localStorage.setItem('exid_user', JSON.stringify(loggedInUser.data));
        localStorage.setItem('exid_user_access_token', accessToken);
        localStorage.setItem('exid_user_refresh_token', refreshToken);

        setUser({
            user: loggedInUser.data,
            accessToken: accessToken,
            refreshToken: refreshToken
        });
    }

    const logout = async () => {
        localStorage.removeItem('exid_user');
        localStorage.removeItem('exid_user_access_token');
        localStorage.removeItem('exid_user_refresh_token');

        setUser(getEmptyUser());
    }

    const getEmptyUser = () => {
        return null;
    }

    const hasRole = (role) => {
        return user?.user?.roles?.includes(role);
    }

    const [user, setUser] = useState(getLoggedInUser());

    return (
        <AuthContext.Provider value={{ user, getLoggedInUser, setLoggedInUser,logout,getEmptyUser, hasRole }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
