import {AuthClient} from "./HttpClient";
export function getB2bVideoResults(page = 1) {
    return AuthClient()
        .get(`videoresults?page=${page}`)
        .then((res) => {
            return res.data
        });
}

export function renderVideo(params) {
    return AuthClient()
        .post(`render`, params)
        .then((res) => {
            return res.data
        });
}

export function denyVideoResult(resultId) {
    return AuthClient()
        .delete(`videoresults/${resultId}/deny`)
        .then((res) => {
            return res?.data
        });
}

export function approveVideoResult(resultId) {
    return AuthClient()
        .post(`videoresults/${resultId}/approve`)
        .then((res) => {
            return res.data
        });
}
