import Button from "../Forms/Button";
import { useTranslation } from 'react-i18next';

export default function ProductCard(props){
    const {product} = props;
    const {t} = useTranslation();

    return (
      <>
          <div className="rounded-md bg-third pb-4">
              <iframe title='youtube-preview' allowFullScreen src={`${product.youtubePreviewUrl}`} className="rounded-t-md"></iframe>
              <p className="text-white mt-4 text-left pl-4">{product.title}</p>
              <p className="text-white mt-4 text-left pl-4">{product.artistName}</p>
              <div className="px-4 mt-4" >
                  <Button clickEvent={() => window.location.href= `/editor/${product.id}`}  buttonText={t('home.use_video')}></Button>
              </div>
          </div>
      </>
    );
}