import Button from "../../Forms/Button";
import {useTranslation} from "react-i18next";
import {useContext, useState} from "react";
import AuthContext from "../../../Helpers/AuthenticationHelper";
import {login} from "../../../Api/authenticationApi";

export default function LoginForm(props) {
    const {
        handleCloseClick,
        changePage
    } = props;
    const { t } = useTranslation();
    const {setLoggedInUser} = useContext(AuthContext);
    const [errors, setErrors] = useState([]);
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const handleLogin = async () => {
        const res = await login(username, password);
        if("error" in res) {
            setErrors([{
                code: res.error,
                message: res.error_description
            }]);
        }else{
            setErrors([]);

            await setLoggedInUser(res.access_token, res.refresh_token);

            handleCloseClick()
        }
    }

    return (
        <div>
            <div className="flex flex-row justify-between bg-primary py-3 px-4 border-b border-white rounded-tl-lg rounded-tr-lg">
                <h3 className="text-xl font-medium text-white my-auto">{t('authentication.login_title')}</h3>
                <button type="button" onClick={handleCloseClick}
                        className="text-white bg-transparent hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule={"evenodd"} d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule={"evenodd"}></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>


            <div className="py-6 px-6 lg:px-8 bg-secondary">
                {errors.length > 0 &&
                    <>
                        <div className={"w-full bg-red-500 border border-red-900 mb-2"}>
                            <p className={"m-auto py-2 text-red-900"}>{errors[0].message}</p>
                        </div>
                    </>
                }

                <form className="space-y-6" action="#">
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-white text-left">{t('form_labels.email')}</label>
                        <input type="email" name="email" id="email" value={username}
                               onChange={(e) => {setUsername(e.target.value)}}
                               className="bg-inputsecondary text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                    </div>
                    <div>
                        <label htmlFor="password"
                               className="block mb-2 text-sm font-medium text-white text-left">{t('form_labels.password')}</label>
                        <input type="password" name="password" id="password" value={password}
                               onChange={(e) => {setPassword(e.target.value)}}
                               className="bg-inputsecondary text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                               required />
                    </div>
                    <div className="flex justify-between">
                        <p
                           onClick={() => {changePage('forgot-password')}}
                           className="text-sm text-primary hover:underline cursor-pointer">
                            {t('authentication.lost_password')}
                        </p>
                    </div>
                    <Button
                        buttonText={t('authentication.login_button')}
                        clickEvent={handleLogin}
                    >
                    </Button>

                    <div className="text-sm font-medium text-gray-500 dark:text-gray-300" onClick={() => {changePage('register')}}>
                        {t('authentication.not_registered')}
                        <span className="text-primary hover:underline ml-1 cursor-pointer">
                            {t('authentication.create_account')}
                        </span>
                    </div>
                </form>
            </div>
        </div>
    )
}