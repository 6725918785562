import {useTranslation} from "react-i18next";

export default function PrivacyPolicy() {
	const {t} = useTranslation();

    return (
        <>
			<div className={"flex flex-row relative"}>
				<div className={"text-left text-white p-8 mt-12 gap-8"}>

					<p className={"text-primary text-2xl pb-4"}><b><u>{t('legal.privacy_policy.title')}</u></b></p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.privacy_policy.title_1')}</h1>
					<p>{t('legal.privacy_policy.body_1')}</p>
					<ul className={"m-auto list-disc pl-4 marker:text-primary"}>
						<li>{t('legal.privacy_policy.list_1_1')}</li>
						<li>{t('legal.privacy_policy.list_1_2')}</li>
						<li>{t('legal.privacy_policy.list_1_3')}</li>
						<li>{t('legal.privacy_policy.list_1_4')}</li>
					</ul>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.privacy_policy.title_2')}</h1>
					<p>{t('legal.privacy_policy.body_2')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.privacy_policy.title_3')}</h1>
					<p>{t('legal.privacy_policy.body_3')}</p>
					<ul className={"m-auto list-disc pl-4 marker:text-primary"}>
						<li>{t('legal.privacy_policy.list_3_1')}</li>
						<li>{t('legal.privacy_policy.list_3_2')}</li>
						<li>{t('legal.privacy_policy.list_3_3')}</li>
						<li>{t('legal.privacy_policy.list_3_4')}</li>
						<li>{t('legal.privacy_policy.list_3_5')}</li>
						<li>{t('legal.privacy_policy.list_3_6')}</li>
						<li>{t('legal.privacy_policy.list_3_7')}</li>
						<li>{t('legal.privacy_policy.list_3_8')}</li>
						<li>{t('legal.privacy_policy.list_3_9')}</li>
					</ul>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.privacy_policy.title_4')}</h1>
					<p>{t('legal.privacy_policy.body_4')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.privacy_policy.title_5')}</h1>
					<p>{t('legal.privacy_policy.body_5')}</p>
					<ul className={"m-auto list-disc pl-4 marker:text-primary"}>
						<li>{t('legal.privacy_policy.list_5_1')}</li>
						<li>{t('legal.privacy_policy.list_5_2')}</li>
						<li>{t('legal.privacy_policy.list_5_3')}</li>
						<li>{t('legal.privacy_policy.list_5_4')}</li>
					</ul>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.privacy_policy.title_6')}</h1>
					<p>{t('legal.privacy_policy.body_6')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.privacy_policy.title_7')}</h1>
					<p>{t('legal.privacy_policy.body_7')}</p>
					<ul className={"m-auto list-disc pl-4 marker:text-primary"}>
						<li>{t('legal.privacy_policy.list_7_1')}</li>
						<li>{t('legal.privacy_policy.list_7_2')}</li>
						<li>{t('legal.privacy_policy.list_7_3')}</li>
						<li>{t('legal.privacy_policy.list_7_4')}</li>
					</ul>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.privacy_policy.title_8')}</h1>
					<p>{t('legal.privacy_policy.body_8')}</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>{t('legal.privacy_policy.title_9')}</h1>
					<p>{t('legal.privacy_policy.body_9')}</p>

					<p>{t('legal.privacy_policy.body_10')}</p>
				</div>
			</div>
        </>
    );
}