import {LocaleSwitcher} from "./LocaleSwitcher";

export default function EditorTopNavigation() {

    return (
        <>
            <div className="px-6 py-4 bg-brandblue">
                <nav className="navbar navbar-expand-lg ">
                    <div className="flex flex-row justify-between">
                        <div>
                            <a href="/" >
                                <img
                                    src="/img/nav-logo.png"
                                    className="navbar-brand navbar_logo"
                                    alt="random"
                                />
                            </a>
                        </div>

                        <LocaleSwitcher />
                    </div>
                </nav>
            </div>
        </>
    )
}