import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import Button from "../../../Components/Forms/Button";
import toast from 'react-hot-toast';
import {getTag, updateTag} from "../../../Api/tagsApi";

export default function TagUpdate() {
    const [tag, setTag] = useState();
    const { id } = useParams();

    useEffect(() => {
        (async function() {
            setTag(await getTag(id));
        })()
    }, [id])

    const save = async () => {
        const response = await updateTag(tag.id, tag);
        setTag(response);

        toast.success('Tag has been successfully updated',{
            position: "top-right"
        });
    }

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>Tag update</h1>
                <Link to={'/admin/tags'} className={"py-2 px-4 ease-in-out duration-300 text-secondary hover:text-primary cursor-pointer transition-all"}>
                    <FontAwesomeIcon icon={icon({name: 'arrow-left'})} className={"mr-2"}/>
                    Back to tags
                </Link>
            </div>

            <div>
                <div className={"text-left"}>
                    <label htmlFor={"name"} className={"text-left pl-4"}>Name</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        defaultValue={tag?.name}
                        onChange={(e) => {
                            tag.name = e.target.value;
                        }}
                        id={"name"}
                        name={"name"}
                        type={"text"}
                        required
                    />
                </div>

                <div className={"flex flex-row justify-end mt-2"}>
                    <Button
                        buttonText={"Save"}
                        clickEvent={save}
                    >
                    </Button>
                </div>
            </div>
        </>
    )
}