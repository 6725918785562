/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState} from "react";
import LoginForm from "./PopupContent/LoginForm";
import RegisterForm from "./PopupContent/RegisterForm";
import ForgotPasswordForm from "./PopupContent/ForgotPasswordForm";

export default function LoginPopup(props) {
    const {isShown, closePopup} = props;
    const [currentPage, setCurrentPage] = useState('login');

    const handleCloseClick = () => {
        closePopup();
    }

    const changePage = (newPage) => {
        setCurrentPage(newPage);
    }

    return (
        <>
            { isShown &&
                <>
                    <div className={"h-screen z-10 w-screen h-screen bg-gray-800 fixed top-0 left-0 bg-opacity-60"}></div>
                    <div tabIndex="-1" aria-hidden={!isShown} aria-modal={true} role={"dialog"} aria-labelledby={"loginModal"}
                         className={"transition-all ease-in-out overflow-y-auto overflow-x-hidden flex flex-col justify-center fixed top-0 right-0 left-0 z-50 w-full md:inset-0 md:h-full" + (!isShown ? 'hidden' : '')}>
                        <div className="relative p-4 w-full max-w-md m-auto">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                {
                                    currentPage === 'login' &&
                                    <LoginForm
                                        handleCloseClick={handleCloseClick}
                                        changePage={changePage}
                                    />
                                }

                                {
                                    currentPage === 'register' &&
                                    <RegisterForm
                                        handleCloseClick={handleCloseClick}
                                        changePage={changePage}
                                    />
                                }

                                {
                                    currentPage === 'forgot-password' &&
                                    <ForgotPasswordForm
                                        handleCloseClick={handleCloseClick}
                                        changePage={changePage}
                                    />
                                }


                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}