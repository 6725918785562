import HomeNavigation from "../Components/Navigation/HomeNavigation";
import Banner from "../Components/General/Banner";
import ProductOverview from "../Components/Product/ProductOverview";
import Footer from "../Components/General/Footer";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";

export default function HomePage() {
    const [searchValue, setSearchValue] = useState('');
    const [filterCategories, setFilterCategories] = useState([]);

    function updateSearchValue(value) {
        if(value !== searchValue) {
            setSearchValue(value);
        }
    }

    function categoriesChanged(categories) {
        console.log(categories);
    }

    useEffect(() => {
        (async function() {
            // getQueryParams();
        })()
    });

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Make great memories, treasure them forever"
                />
            </Helmet>

            <HomeNavigation searchValue={searchValue} setSearchValue={updateSearchValue} />
            <Banner />
            <ProductOverview searchValue={searchValue} filterCategories={filterCategories} setFilterCategories={setFilterCategories} categoriesChanged={categoriesChanged} />
            <Footer />
        </>
    );
}