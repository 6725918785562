import Button from "../Forms/Button";
import {useTranslation} from "react-i18next";

export default function EditorActionsNavigation(props) {
    const {startVideoDownload} = props;

    const {t} = useTranslation();

    const downloadVideo = (e) => {
        e.preventDefault();
        startVideoDownload();
    }

    return (
        <>
            <div className="px-6 py-4 bg-primary">
                <nav className="navbar navbar-expand-lg ">
                    <div className="flex flex-row justify-end">
                        <div>
                            <Button
                                buttonText={t('editor.download_video_button')}
                                clickEvent={downloadVideo}
                                customClass={'only-white'}
                                disabled={false}
                                id={'download_video_button'}
                            />
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}