import {AuthClient} from "./HttpClient";

export function getAllSubCategoriesPaginated(page = 1) {
    return AuthClient()
        .get(`sub-categories?page=${page}`).then((res) => {
            return res.data
        });
}

export function getAllSubCategories() {
    return AuthClient()
        .get('sub-categories-notpaginated').then((res) => {
            return res.data
        });
}

export function getSubCategory(id) {
    return AuthClient()
        .get(`sub-categories/${id}`).then((res) => {
            return res.data
        })
}

export function deleteSubCategory(id){
    return AuthClient()
        .delete(`sub-categories/${id}`).then((res) => {
            return res.data
        })
}

export function updateSubCategory(id, params){
    return AuthClient()
        .patch(`sub-categories/${id}`, params).then((res)=>{
            return res.data
        }).catch((res) => {
            return res.response.data;
        })
}

export function createSubCategory(params){
    return AuthClient()
        .post(`sub-categories`, params).then((res)=>{
            return res.data
        }).catch((res) => {
            return res.response.data;
        })
}