export default function ReturnPolicy() {
    return (
        <>
			<div className={"flex flex-row relative"}>
				<div className={"text-left text-white p-8 mt-12 gap-8"}>

					<p className={"text-primary text-2xl pb-4"}><b><u><span>General
						Terms and Conditions/User Agreement EXID</span></u></b>
					</p>
					<p className={"py-4"}><span>[if
						user agreement - state: By completing the registration process and clicking on
						the 'I accept' button, you expressly declare that you have read and expressly
						agree to everything in this agreement].</span>
					</p>
					<p className={"py-4"}><span>By accessing or using
						the EXID Platform, you agree to comply with and be bound by these Terms and
						Conditions (&quot;Conditions&quot;). Please read these Terms and Conditions
						carefully as they contain important information about your legal rights, remedies
						and obligations.</span>
					</p>
					<p className={"py-4"}><span
					>These Conditions
						constitute a legally binding agreement (&quot;Agreement&quot;) between you and
						EXID (as defined below) with respect to your access to and use of the EXID
						website and the EXID Platform, including all subdomains thereof, and all other
						websites through which EXID makes its services available (collectively,
											&quot;Site&quot;) and application programme interfaces (collectively,
											&quot;Application&quot;) and all related services (collectively, &quot;EXID
						Services&quot;). The Site, Application and EXID Services together are
						hereinafter collectively referred to as the &quot;EXID Platform&quot;. </span>
					</p>
					<p className={"py-4"}><span
					>Where reference is
						made in these Conditions to &quot;EXID,&quot; &quot;we,&quot; &quot;us,&quot;
											or &quot;our,&quot; this refers to the company Point Of View or Mr Jeroen
						Olaerts, located at 3600 GENK, Sledderloweg 19 and registered with the C.B.E.
						under number BE0649.570.002</span>
					</p>
					<p className={"py-4"}><span>Table of Contents </span>
					</p>
					<p><span>1. Scope of EXID
						Services </span>
					</p>
					<p><span>2. Access, use of the
						EXID Platform, user verification </span>
					</p>
					<p><span
					>3. Amendment of these
						Conditions </span>
					</p>
					<p><span
					>4. Account
						registration </span>
					</p>
					<p><span
					>5. Use of the EXID
						Platform and rights granted </span>
					</p>
					<p><span
					>6. Remunerations </span>
					</p>
					<p><span
					>7. Revocations and
						cancellations</span>
					</p>
					<p><span
					>8. Content,
						(intellectual) property rights, non-assignment </span>
					</p>
					<p><span
					>9. Warranties and
						disclaimer </span>
					</p>
					<p><span
					>10. Liability</span>
					</p>
					<p><span
					>11. Compensation</span>
					</p>
					<p><span
					>12. Applicable law
						and competent courts</span>
					</p>
					<p><span
					>13. Feedback</span>
					</p>
					<p><span
					>14. General
						provisions</span>
					</p>
					<h1 className={"text-primary text-xl py-4"}><span
					>1.<span
					>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span><span>Scope of EXID Services </span>
					</h1>
					<p><span
					>1.1 The EXID Platform
						provides templates for video montages which are easy to use by users of the
						Platform (&quot;Customer&quot;).  </span>
					</p>
					<p><span
					>1.2 As a provider of
						the EXID Platform, EXID does not offer finalised videos but templates of video
						montages (including music) that can be used by customers to easily, quickly and
						inexpensively create their own finished and edited video. </span>
					</p>
					<p><span
					>1.3 EXID is not
	responsible for disruptions or outages of the internet and telecommunications
	infrastructure that are beyond our control and may result in interruptions in
	the availability of the EXID Platform. EXID may, temporarily and subject to the
	Customer's legitimate interests (e.g. by prior notice), limit the availability
	of the EXID Platform or certain areas or features of it, if necessary for capacity
	constraints, the security or integrity of our servers, or to carry out
	maintenance measures that ensure the proper or improved operation of the EXID
	Platform. EXID may improve, expand and modify the EXID Platform and introduce
	new EXID Services or Third Party Services from time to time. EXID will notify
	members and Customers of any changes to the EXID Platform, unless such changes
	are minor in nature and do not materially affect the parties' contractual
	obligations. </span>
					</p>
					<h1 className={"text-primary text-xl py-4"}><span
					>2.<span
					>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span><span>Access, use of the EXID Platform, user
	verification</span>
					</h1>
					<p><span
					>2.1 To access and use
	the EXID Platform or register an EXID Account, you must be a legal or natural
	person at least 18 years old or a duly organised, validly existing company,
	organisation or other legal entity under the laws of the country where you are
	registered and able to enter into legally binding contracts. </span>
					</p>
					<p><span
					>2.2 You will comply
	with all applicable export control laws in your local jurisdiction.</span>
					</p>
					<p><span
					>2.3 EXID may make
	access to and use of the EXID Platform, or certain areas or features of the
	EXID Platform, subject to certain conditions or requirements, such as
	completion of a verification process. </span>
					</p>
					<p><span
					>2.4 User
	authentication on the Internet is difficult and we take no responsibility for
	confirming the identity of any Customer. Notwithstanding the above, for
	transparency and fraud prevention purposes, and as permitted by applicable law,
	we may, but are not obliged to, ask customers to provide some form of
	government identification or other information or perform additional checks to
	help verify the identity or background of customers.</span>
					</p>
					<p><span
					>2.5 Access to or use
	of certain areas and features of the EXID Platform may be subject to separate
	policies, standards or guidelines, or may require you to accept additional
	terms and conditions, before accessing the relevant areas or features of the
	EXID Platform. If there is any conflict between these Conditions and the
	conditions applicable to a specific area or part of the EXID Platform, the
	latter conditions shall prevail with respect to your access to or use of that
	area or part, unless otherwise specified in the latter conditions. </span>
					</p>
					<h1 className={"text-primary text-xl py-4"}><span
					>3.<span
					>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span><span>Amendment of these Conditions </span>
					</h1>
					<p><span
					>3.1 EXID reserves the
	right to modify these Conditions at any time in accordance with this provision.
	If we make any changes to these Conditions, we will post the revised Conditions
	on the EXID Platform and update the &quot;Last updated&quot; date at the top of
	these Conditions. </span>
					</p>
					<p><span
					>3.2 We will also
	notify you of the changes by e-mail at least thirty (30) days before the date
	on which they take effect (or: again, expressly request permission from
	Customer when using the EXID Platform). If you disagree with the revised
	Conditions, you may terminate this Agreement with immediate effect. We will
	inform you of your right to terminate the Agreement in the notification e-mail.
	If you do not terminate your Agreement before the effective date of the revised
	Conditions, your continued access to or use of the EXID Platform will
	constitute acceptance of the revised Conditions. </span>
					</p>
					<h1 className={"text-primary text-xl py-4"}><span
					>4.<span
					>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span><span>Account registration</span>
					</h1>
					<p><span
					>4.1 Each Customer
	must register an account (&quot;EXID Account&quot;) to access and use the
	features of the EXID Platform. If You register an EXID Account for a company,
	organisation or other legal entity, You represent and warrant that you are
	authorised to legally bind that entity and grant us all permissions and
	licences provided in these Conditions.</span>
					</p>
					<p><span
					>4.2 You must provide
	accurate, current and complete information during the registration process and
	keep your EXID Account up to date at all times. </span>
					</p>
					<p><span
					>4.3 You may not
	assign or otherwise transfer your EXID Account to another party. </span>
					</p>
					<p><span
					>4.4 You are
	responsible for maintaining the confidentiality and security of your EXID
	Account data and must not disclose your data to any third party. You must
	notify EXID immediately if you know or have reason to suspect that your login
	credentials have been lost, stolen, misappropriated or otherwise compromised or
	in the event of any actual or suspected unauthorised use of your EXID Account.
	You are liable for all activities conducted through your EXID Account, unless
	such activities are not authorised by you and you are not otherwise negligent
	(such as failure to report unauthorised use or loss of your login credentials).
	</span>
					</p>
					<p><span
					>4.5 EXID may enable
	features that allow you to authorise other Customers or certain third parties
	to perform certain actions that affect your EXID Account. For example, we may
	enable Customers to link their EXID Accounts to businesses and take actions on
	behalf of those businesses, we may enable eligible Customers or certain third
	parties to order EXID Services on behalf of other Customers, or we may enable
	Customers to add other Customers as Co-Customers (as defined below) to help
	manage their orders. No third party is authorised by EXID to request your login
	credentials, and you shall not ask for another Customer's login credentials. </span>
					</p>
					<h1 className={"text-primary text-xl py-4"}><span
					>5.<span
					>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span><span>Use of the EXID Platform and rights granted</span>
					</h1>
					<p><span
					>5.1 For a fee, the
	Customer can download a video template on the EXID Platform, which the Customer
	can then further edit into a finalised video that the Customer can publish on
	his or her social media channels. </span>
					</p>
					<p><span
					>5.2 The EXID
	Platform, the content of the EXID Platform and the templates available on the
	EXID Platform are protected in whole or in part by copyright and/or other
	(intellectual) property rights. The Customer acknowledges and agrees that the
	EXID Platform and the templates available on the EXID Platform are the
	exclusive property of EXID and/or its licensors or authorised third parties.
	You will not remove, alter or make illegible any copyright, trademark or other
	proprietary notices contained in or belonging to the EXID Platform or the
	templates. All trademarks, logos, trade names, and any other source
	designations of EXID used on or in connection with the EXID Platform are the
	exclusive property of EXID and/or its licensors or authorised third parties.
	Trademarks, logos, trade names and any other third-party proprietary
	designations used on or in connection with the EXID Platform are used for
	identification purposes only and may be the property of their respective
	owners. </span>
					</p>
					<p><span
					>5.3 Subject to your
	compliance with these Conditions and the payment of the fee, EXID grants you a
	limited, non-exclusive, non-sublicensable, revocable, non-transferable licence
	to (i) use the EXID Platform (and any software) on your personal device(s); and
	(ii) use the provided templates within the limits of the concluded licence as
	stipulated on the EXID Platform.</span>
					</p>
					<p><span>1.<span
					>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span><span>The
	Customer shall not use, copy, adapt, modify, prepare derivative works from,
	distribute, licence, sell, transfer, publicly display, publicly perform,
	transmit, broadcast or otherwise exploit the provided templates except to the
	extent expressly permitted in these Conditions and in the licence entered into
	by the Customer. No licences or rights are granted to you except those expressly
	granted in these Conditions. </span>
					</p>
					<p><span>2.<span
					>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span><span>The Customer may not: (i)</span><b><span
					> </span></b><span
					>sell,
	transfer, sublicense, give away or otherwise assign to any other party the
	template or the works performed through use of this template. (ii) resell the
	template itself or as part of a package. (iii) resell the template (or
	otherwise make it available) in any way that enables another party to download
	the template or any part of the template as a separate file. (iv) file a claim
	that you are the creator or copyright owner of the template, any part of the
	template or any derivative work created based on the template.</span>
					</p>
					<h1 className={"text-primary text-xl py-4"}><span
					>6.<span
					>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span><span>Remunerations </span>
					</h1>
					<p><span
					>6.1 EXID charges a
	licence fee for downloading and using the templates made available on the EXID
	Platform. By paying the licence fee, the Customer acquires a licence to the
	template in accordance with the modalities defined on the EXID Platform. The
	Customer can choose between different licence types and the associated licence
	fee.</span>
					</p>
					<p><span
					>6.2 The licence fee
	is payable in advance by the Customer. The template is available for download
	only after payment is received. Following the receipt of an order confirmation
	from EXID, a legally binding contract is established between EXID and the
	Customer. </span>
					</p>
					<p><span
					>6.3 Following the
	receipt of the payment, EXID will also transfer a (digital) invoice to the
	Customer. The invoice is always made out in the Customer's name and stating the
	details provided by the Customer (such as name, address, VAT number, etc.).</span>
					</p>
					<p><span
					>6.4 If EXID
	determines that the Customer is using the templates beyond the limitations of
	the licence granted to the Customer, EXID shall be entitled, at its discretion,
	either to charge the Customer additional licence fees or to prohibit the
	Customer (judicially or otherwise with reservation for any damages) from
	further use of the templates in question.</span>
					</p>
					<h1 className={"text-primary text-xl py-4"}><span
					>7.<span
					>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span><span>Revocations and cancellations </span>
					</h1>
					<p><span
					>7.1 Customers cannot
	invoke a right of withdrawal as the downloaded content is not returnable. An
	executed order cannot be cancelled.</span>
					</p>
					<p><span
					>7.2 In certain
	circumstances, EXID may cancel or not execute a confirmed order. This may be to
	prevent or hinder fraud or other illegal activities or to protect the
	legitimate interests of EXID or third parties. In that case, the Customer may
	not charge EXID a fee.</span>
					</p>
					<h1 className={"text-primary text-xl py-4"}><span
					>8.<span
					>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span><span>Content, (intellectual) property rights,
	non-assignment</span>
					</h1>
					<p><span
					>8.1 The EXID Platform
	and the Content available on the EXID Platform are protected in whole or in
	part by copyright, trademark and/or other (intellectual) property rights. The
	Customer acknowledges and agrees that the EXID Platform is the exclusive property
	of EXID and/or its licensors or third parties with whom EXID has an agreement.
	All trademarks, logos, trade names and other source identifiers of EXID used on
	or in connection with the EXID Platform are the exclusive property of EXID
	and/or its licensors or third parties with whom EXID has an agreement. Marks,
	logos, trade names and other third-party proprietary designations used on or in
	connection with the EXID Platform are used for identification purposes only.</span>
					</p>
					<p><span
					>8.2 Customer shall
	not use, copy, adapt, modify, create derivative works from, distribute,
	licence, sell, transfer, publicly display, publicly perform, transmit,
	broadcast or otherwise exploit the EXID Platform and the Content on the EXID
	Platform (whether or not downloaded by Customer), except to the extent that you
	are the legal owner of certain items included in the EXID Platform or as
	expressly permitted in these Conditions or through the licence purchased by
	Customer. No implied or tacit licences are granted. </span>
					</p>
					<p><span
					>8.3 The Customer
	expressly agrees, for a period of twelve (12) months after the placement of an
	order, not to approach or attempt to approach, directly or indirectly, any
	party that is a customer or supplier of EXID, or that was a customer or
	supplier of EXID at any time during the twelve (12) months immediately
	preceding date on which the order was placed.</span>
					</p>
					<h1 className={"text-primary text-xl py-4"}><span
					>9.<span
					>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span><span>Warranties and disclaimer</span>
					</h1>
					<p><span
					>9.1 Use of
	the EXID Platform is at the Customer's own risk. The EXID Platform is provided
	by EXID under these Terms of Use 'as is', without warranty of any kind, express
	or implied, legal or otherwise. </span>
					</p>
					<p><span
					>9.2 EXID
	expressly disclaims all warranties, whether express or implied, including, but
	not limited to, implied warranties of merchantability, fitness for a particular
	purpose and non-infringement and any other warranties that may arise under any
	law. Without limiting the above, EXID makes no warranty that: (i) the EXID
	Platform will meet your requirements; (ii) the quality of the EXID Platform or
	the Content available on the EXID Platform will meet your expectations; and
	(iii) any errors or defects on the EXID Platform, in the Services or materials
	will be corrected. </span>
					</p>
					<p><span
					>9.3 To the
	extent a disclaimer or limitation of liability does not apply, all applicable
	express, implied and legal warranties are of limited duration up to a period of
	thirty (30) days from the date you first used the EXID Platform and no
	warranties apply after this period.</span>
					</p>
					<h1 className={"text-primary text-xl py-4"}><span
					>10.<span
					>&nbsp;&nbsp;
	</span></span><span>Liability </span>
					</h1>
					<p><span
					>10.1 Any incorrect,
	incomplete, or otherwise inaccurate information added by the Customer or
	incorrect use of the EXID Platform is the sole responsibility of the Customer,
	without any liability on the part of EXID. </span>
					</p>
					<p><span
					>10.2 EXID can never
	be held liable in the following cases: (i) Technical or computer failure. The
	incompatibility of the EXID Platform with any hardware or software shall not
	give rise to any liability on the part of EXID. (ii) Direct or indirect,
	material or consequential, foreseeable or unforeseeable damage to the Customer
	arising from the use or difficulty in using the EXID Platform. (iii) The
	dissemination of any inaccurate, non-secure or unreliable information on the
	Internet.</span>
					</p>
					<p><span
					>10.3 The Customer is
	responsible for the protection of their equipment and data, for the use made of
	the EXID Platform or the EXID Services and for any breach of these Terms and Conditions.</span>
					</p>
					<p><span
					>10.4 EXID shall only
	be liable under legal provisions for wilful intent and gross negligence on the
	part of ourselves, our legal representatives or directors. </span>
					</p>
					<h1 className={"text-primary text-xl py-4"}><span
					>11.<span
					>&nbsp;&nbsp;
	</span></span><span>Compensation </span>
					</h1>
					<p><span
					>11.1 The
	Customer agrees to protect, indemnify and hold harmless EXID, its subsidiaries,
	affiliates, licensors, employees, representatives, third-party information
	providers, submitters and independent freelancers, if any, against any claims,
	damages, costs, liabilities and expenses (including, but not limited to
	reasonable attorneys' fees) arising out of or in connection with your conduct,
	your use of or inability to use the EXID Platform, your (alleged) failure to
	comply with the website Terms of Use or any representation or warranty
	contained herein, your unauthorised use of the EXID Platform Content or your
	infringement of any other rights.</span>
					</p>
					<p><span
					>&nbsp;</span>
					</p>
					<h1 className={"text-primary text-xl py-4"}><span
					>12.<span
					>&nbsp;&nbsp;
	</span></span><span>Applicable law and competent courts</span>
					</h1>
					<p><span
					>12.1 These Terms and
	Conditions shall be governed by and construed in accordance with Belgian law. </span>
					</p>
					<p><span
					>12.2 The parties
	mutually agree that the courts of the district of Antwerp, Hasselt division
	shall have exclusive jurisdiction to settle disputes arising out of or in
	connection with these Terms and Conditions or the applicability, breach,
	termination, validity, enforcement or interpretation thereof, or the use of the
	EXID Platform (collectively, &quot;Disputes&quot;). </span>
					</p>
					<p><span
					>12.3 This Article 12
	shall survive any termination of these Terms and Conditions and shall remain
	applicable even if you stop using the EXID Platform or terminate your EXID
	Account. </span>
					</p>
					<h1 className={"text-primary text-xl py-4"}><span>13.<span>&nbsp;&nbsp;
					</span></span><span>Feedback </span>
					</h1>
					<p><span>We invite you to
						provide feedback, comments and suggestions for improvements to the EXID
						Platform (&quot;Feedback&quot;). You can provide Feedback by sending us an
						e-mail, via the &quot;Contact Us&quot; section of the EXID Platform, or by
						other means of communication. Any feedback you submit to us will be considered
						non-confidential and non-proprietary to you. By submitting feedback to us, you
						grant us a non-exclusive, worldwide, royalty-free, irrevocable, sublicensable,
						perpetual licence to use and publish these ideas and materials for any purpose
						whatsoever, without compensation to you.
					</span>
					</p>
					<h1 className={"text-primary text-xl py-4"}><span
					>14.<span
					>&nbsp;&nbsp;
	</span></span><span>General provisions </span>
					</h1>
					<p><span
					>14.1 Unless
	supplemented by additional terms and conditions, policies, guidelines or
	standards, these Terms and Conditions constitute the entire agreement between
	EXID and the Customer and supersede all previous oral or written understandings
	or agreements between EXID and the Customer relating to the access to and use
	of the EXID Platform as well as to the content of the EXID Platform and orders
	placed by the Customer. </span>
					</p>
					<p><span
					>14.2 No joint
	venture, partnership, employment or agency relationship exists between the
	Customer and EXID as a result of this Agreement or your use of the EXID
	Platform. </span>
					</p>
					<p><span
					>14.3 If any provision
	of these Terms and Conditions is deemed invalid or unenforceable, such
	provision shall be scrapped and shall not affect the validity and
	enforceability of the remaining provisions. </span>
					</p>
					<p><span
					>14.4 EXID's failure
	to enforce any right or provision in these Terms and Conditions shall not
	constitute a waiver of such right or provision unless acknowledged and agreed
	in writing by us. Except as expressly set out in these Terms and Conditions,
	the exercise by a party of any of its legal remedies under these Terms and
	Conditions shall not affect its other legal remedies under these Terms and
	Conditions or otherwise permitted under law. </span>
					</p>
					<p><span
					>14.5 The Customer may
	not assign, transfer or delegate this Agreement and the rights and obligations
	thereunder without EXID's prior written consent. EXID may assign, transfer or
	delegate this Agreement and all rights and obligations hereunder without
	limitation at its sole discretion. The Customer's right to terminate this
	Agreement at any time remains unaffected. </span>
					</p>
					<p><span
					>14.6 Unless otherwise
	specified, any notices or other communications to Customers permitted or required
	under this Agreement will be provided electronically by EXID via e-mail, EXID
	Platform notification, or messaging service.</span>
					</p>
				</div>
			</div>
        </>
    );
}