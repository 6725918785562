import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Link} from "react-router-dom";
import ConfirmDeleteModal from "../../../Components/Popups/ConfirmDeleteModal";
import toast from "react-hot-toast";
import {deleteTag} from "../../../Api/tagsApi";
import Pagination from "../../../Components/General/Pagination";
import {useBoundStore} from "../../../Store/store";

export default function TagIndex() {
    const [isConfirmDeleteShown, setIsConfirmDeleteShown] = useState(false);
    const [deletingTagID, setDeletingTagID] = useState(null);
    const [page, setPage] = useState(1);

    const tags = useBoundStore((state) => state.tags);
    const metaData = useBoundStore((state) => state.tagsMetaData);
    const fetchTags = useBoundStore((state) => state.fetchTags);

    useEffect(() => {
        fetchTags(page).then(() => {});
    }, [fetchTags, page])

    const changePage = async (newPage) => {
        setPage(newPage);
    }

    const handleCloseConfirmDelete = () => {
        setIsConfirmDeleteShown(false);
        setDeletingTagID(null);
    }

    const handleOpenConfirmDelete = (id) => {
        setIsConfirmDeleteShown(true);
        setDeletingTagID(id);
    }

    const deleteTagAction = async () => {
        if(deletingTagID === null) return false;

        await deleteTag(deletingTagID);
        fetchTags(page);

        handleCloseConfirmDelete();

        toast.success('Tag has been successfully deleted',{
            position: "top-right"
        });
    }

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>Tags</h1>
                <Link to={'/admin/tags/create'} className={"py-2 px-4 ease-in-out duration-300 bg-secondary text-white cursor-pointer rounded-lg border border-transparent transition-all hover:bg-transparent hover:text-secondary hover:border hover:border-secondary"}>
                    Create a new Tag
                </Link>
            </div>

            <div className="grid grid-cols-3 text-left mb-2 text-white bg-primary uppercase rounded-tr rounded-tl">
                <div className={"p-2"}>ID</div>
                <div className={"p-2"}>Name</div>
                <div className={"p-2"}>Actions</div>
            </div>

            {
                tags.map((tag) => {
                    return (
                        <div key={`tags_${tag.id}`}>
                            <div className={"grid grid-cols-3 text-left border-b border-gray-500 border-opacity-30"} >
                                <Link to={`${tag.id}`}>
                                    <div className={"p-2"}>{tag.id}</div>
                                </Link>
                                <Link to={`${tag.id}`}>
                                    <div className={"p-2"}>{tag.name}</div>
                                </Link>
                                <div className={"grid grid-cols-3 p-2"}>
                                    <div>
                                        <FontAwesomeIcon onClick={() => {handleOpenConfirmDelete(tag.id)}} icon={icon({name:"trash"})} className={"text-red-600 cursor-pointer"}/>
                                    </div>
                                    <Link to={`${tag.id}/update`}>
                                        <div className={"text-right"}>
                                            <FontAwesomeIcon icon={icon({name:"edit"})} className={"cursor-pointer text-gray-500 text-right"}/>
                                        </div>
                                    </Link>

                                    <Link to={`${tag.id}`}>
                                        <div className={"text-right"}>
                                            <FontAwesomeIcon icon={icon({name:"angle-right"})} className={"cursor-pointer text-gray-500 text-right"}/>
                                        </div>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    )
                })
            }

            <div className={"w-full text-right mb-8 flex flex-row justify-end px-8"}>
                <Pagination metaData={{...metaData, current_page: page}} changePage={changePage} page={page}/>
            </div>

            <ConfirmDeleteModal
                isShown={isConfirmDeleteShown}
                closePopup={handleCloseConfirmDelete}
                clickConfirmed={deleteTagAction}
            ></ConfirmDeleteModal>
        </>
    )
}