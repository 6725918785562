import {useBoundStore} from "../../../Store/store";
import {useEffect, useState} from "react";
import Pagination from "../../../Components/General/Pagination";
import {VideoResult} from "../../../Components/VideoActions/VideoResult";

export const VideoResultsIndex = () => {
    const fetchVideoResults = useBoundStore((state) => state.fetchVideoResults)
    const b2bvideoresults = useBoundStore((state) => state.b2bvideoresults)
    const b2bvideoresultsMeta = useBoundStore((state) => state.b2bvideoresultsMeta)

    const [page, setPage] = useState(1);

    useEffect(() => {
        fetchVideoResults(page).then(() => {});
    }, [fetchVideoResults, page])

    const changePage = (newPage) => {
        setPage(newPage);
    }

    return (
        <>
            <div className={"grid grid-cols-1 md:grid-cols-3"}>
                {
                    b2bvideoresults.map((result, index) => {
                        return <VideoResult videoresult={result} key={`videoresult_${result.id}_${index}`}/>
                    })
                }
            </div>

            <div className={"w-full text-right mb-8 flex flex-row justify-end px-8"}>
                <Pagination metaData={{...b2bvideoresultsMeta, current_page: page}} changePage={changePage} page={page}/>
            </div>
        </>
    )
}