import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useLocation} from "react-router-dom";
import {HasCorrectRole} from "../Validation/HasCorrectRole";
import {useContext} from "react";
import AuthContext from "../../Helpers/AuthenticationHelper";

export default function AdminSideNavigation() {
    const location = useLocation();
    const {user} = useContext(AuthContext);

    const getUserCompany = () => {
        if(user?.user?.b2bCompany.length !== 0) return user?.user?.b2bCompany[0]?.id

        return null;
    }

    return (
        <>
            <div className={"bg-secondary min-h-screen overflow-hidden"}>
                <div className={"mt-4 mb-16"}>
                    <a href="/">
                        <img
                            src="/img/nav-logo.png"
                            className="navbar-brand navbar_logo m-auto"
                            alt="random"
                        />
                    </a>
                </div>

                <div className={"flex flex-col gap-y-4 text-left px-8 justify-center"}>
                    <HasCorrectRole roles={['admin']}>
                        <Link to={"/admin/users"} className={"cursor-pointer hover:text-primary " + (location.pathname.includes('/admin/users') ? 'text-primary' : 'text-white')}>
                            <FontAwesomeIcon icon={icon({name: 'users'})} className={"mr-2"}/>
                            Users
                        </Link>
                        <Link to={"/admin/tags"} className={"cursor-pointer hover:text-primary " + (location.pathname.includes('/admin/tags') ? 'text-primary' : 'text-white')}>
                            <FontAwesomeIcon icon={icon({name: 'tags'})} className={"mr-2"}/>
                            Tags
                        </Link>
                        <Link to={"/admin/categories"} className={"cursor-pointer hover:text-primary " + (location.pathname.includes('/admin/categories') ? 'text-primary' : 'text-white')}>
                            <FontAwesomeIcon icon={icon({name: 'layer-group'})} className={"mr-2"}/>
                            Categories
                        </Link>
                        <Link to={"/admin/subcategories"} className={"cursor-pointer hover:text-primary " + (location.pathname.includes('/admin/subcategories') ? 'text-primary' : 'text-white')}>
                            <FontAwesomeIcon icon={icon({name: 'subscript'})} className={"mr-2"}/>
                            Subcategories
                        </Link>
                        <Link to={"/admin/templates"} className={"cursor-pointer hover:text-primary " + (location.pathname.includes('/admin/templates') ? 'text-primary' : 'text-white')}>
                            <FontAwesomeIcon icon={icon({name: 'file'})} className={"mr-2"}/>
                            Templates
                        </Link>
                        <Link to={"/admin/promo-codes"} className={"cursor-pointer hover:text-primary " + (location.pathname.includes('/admin/promo-codes') ? 'text-primary' : 'text-white')}>
                            <FontAwesomeIcon icon={icon({name: 'percent'})} className={"mr-2"}/>
                            Promo codes
                        </Link>

                        <hr className={"border-b border-primary w-full my-2"} />

                        <p className={"text-gray-300"}>B2B</p>
                        <Link to={"/admin/b2b-companies"} className={"cursor-pointer hover:text-primary " + (location.pathname.includes('/admin/b2b-companies') ? 'text-primary' : 'text-white')}>
                            <FontAwesomeIcon icon={icon({name: 'building'})} className={"mr-2"}/>
                            Companies
                        </Link>
                    </HasCorrectRole>

                    <HasCorrectRole roles={['admin', 'b2b_admin']}>
                        <Link to={"/admin/video-results"} className={"cursor-pointer hover:text-primary " + (location.pathname.includes('/admin/video-results') ? 'text-primary' : 'text-white')}>
                            <FontAwesomeIcon icon={icon({name: 'video'})} className={"mr-2"}/>
                            VideoResults
                        </Link>
                    </HasCorrectRole>

                    <HasCorrectRole roles={['b2b_admin']}>
                        <Link to={`/admin/b2b-companies/${getUserCompany()}`} className={"cursor-pointer hover:text-primary " + (location.pathname.includes('/admin/b2b-companies') ? 'text-primary' : 'text-white')}>
                            <FontAwesomeIcon icon={icon({name: 'file'})} className={"mr-2"}/>
                            My templates
                        </Link>
                    </HasCorrectRole>
                </div>


            </div>
        </>
    )
}