import Footer from "../Components/General/Footer";
import BareHomeNavigation from "../Components/Navigation/BareHomeNavigation";
import PrivacyPolicy from "../Components/legal/PrivacyPolicy";

export default function PrivacyPolicyPage() {
    return (
        <>
            <BareHomeNavigation />
            <PrivacyPolicy />
            <Footer />
        </>
    );
}