import {useTranslation} from "react-i18next";
import {useState} from "react";
export default function OlfGuidelinePopup(props) {
    const {isShown, closePopup} = props;

    const {t} = useTranslation();

    const [activeTab, setActiveTab] = useState(1);

    const handleCloseClick = () => {
        closePopup();
    }

    return (
        <>
            { isShown &&
                <>
                    <div className={"h-screen w-screen bg-gray-800 fixed top-0 left-0 bg-opacity-60"} style={{zIndex:60}}></div>
                    <div tabIndex="-1" aria-hidden={!isShown} aria-modal={true} role={"dialog"} aria-labelledby={"ConfirmDeleteModal"}
                         className={"transition-all ease-in-out overflow-y-auto overflow-x-hidden flex flex-col justify-center fixed top-0 right-0 left-0 w-full md:inset-0 md:h-full" + (!isShown ? 'hidden' : '')} style={{zIndex:70}}>
                        <div className="relative p-4 w-full max-w-4xl m-auto ">

                            <div className="flex flex-row justify-between bg-gray-900 py-3 px-4 border-b border-white rounded-tl-lg rounded-tr-lg">
                                <h3 className="text-xl font-medium text-white my-auto uppercase">How to use this?</h3>
                                <button type="button" onClick={handleCloseClick}
                                        className="text-white bg-transparent hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule={"evenodd"} d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule={"evenodd"}></path>
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>


                            <div className="py-6 px-6 lg:px-8 bg-gray-900 text-white text-sm max-h-[90vh] overflow-y-scroll">
                                <ul className="relative m-0 flex list-none flex-wrap justify-between overflow-hidden p-0 transition-[height] duration-200 ease-in-out">
                                    <li className="w-[4.5rem] flex-auto" onClick={() => setActiveTab(1)}>
                                        <div
                                            className="flex cursor-pointer items-center pl-2 leading-[1.3rem] no-underline after:ml-2 after:h-px after:w-full after:flex-1 after:bg-[#e0e0e0] after:content-[''] hover:bg-[#f9f9f9] focus:outline-none dark:after:bg-neutral-600 dark:hover:bg-[#3b3b3b]">
                                              <span
                                                  className="my-6 mr-2 flex h-[1.938rem] w-[1.938rem] items-center justify-center rounded-full bg-[#ebedef] text-sm font-medium text-[#40464f]">
                                                1
                                              </span>
                                        </div>

                                    </li>

                                    <li className="w-[4.5rem] flex-auto" onClick={() => setActiveTab(2)}>
                                        <div className="flex cursor-pointer items-center leading-[1.3rem] no-underline before:mr-2 before:h-px before:w-full before:flex-1 before:bg-[#e0e0e0] before:content-[''] after:ml-2 after:h-px after:w-full after:flex-1 after:bg-[#e0e0e0] after:content-[''] hover:bg-[#f9f9f9] focus:outline-none dark:before:bg-neutral-600 dark:after:bg-neutral-600 dark:hover:bg-[#3b3b3b]">
                                          <span
                                              className="my-6 mr-2 flex h-[1.938rem] w-[1.938rem] items-center justify-center rounded-full bg-[#ebedef] text-sm font-medium text-[#40464f]">
                                            2
                                          </span>
                                        </div>

                                    </li>

                                    <li  className="w-[4.5rem] flex-auto" onClick={() => setActiveTab(3)}>
                                        <div
                                            
                                            className="flex cursor-pointer items-center pr-2 leading-[1.3rem] no-underline before:mr-2 before:h-px before:w-full before:flex-1 before:bg-[#e0e0e0] before:content-[''] hover:bg-[#f9f9f9] focus:outline-none dark:before:bg-neutral-600 dark:after:bg-neutral-600 dark:hover:bg-[#3b3b3b]">
                                          <span
                                              
                                              className="my-6 mr-2 flex h-[1.938rem] w-[1.938rem] items-center justify-center rounded-full bg-[#ebedef] text-sm font-medium text-[#40464f]">
                                            3
                                          </span>
                                        </div>
                                    </li>
                                </ul>

                                {
                                    activeTab === 1 && (
                                        <div
                                            className="w-full p-4 transition-all duration-500 ease-in-out text-left">
                                            Inside this tool, there are 3 elements: the first video, the second video (your own personal video), and the last one. The first and last videos are presets and cannot be changed. The middle video is where you can add your own video. <br/><br/>

                                            In order to start creating your personalized video, you first need to upload it using the white button located between the current videos. Select your desired video and click "Use."
                                        </div>
                                    )
                                }

                                {
                                    activeTab === 2 && (
                                        <div
                                            className="w-full p-4 transition-all duration-500 ease-in-out text-white text-left">
                                            Your personal video will start uploading, and the progress of this will be shown. Once your video is uploaded and ready, you will see a timeline and three buttons.<br/><br/>

                                            Button 1: Play - Click this button to play your uploaded video.<br/>
                                            Button 2: Return to starting point - Click this button to reset the timeline to the beginning.<br/>
                                            Button 3: Delete video - Click this button to delete the current video and upload another video to replace it.<br/><br/>

                                            Using the timeline, you can select a segment of your own video that will be included in the final result.
                                        </div>
                                    )
                                }

                                {
                                    activeTab === 3 && (
                                        <div
                                            className="w-full p-4 transition-all duration-500 ease-in-out text-white text-left">
                                            Once everything is as you want it to be, click the "Create my video" button. Enter your information, and your request will be processed.<br/><br/>

                                            Your video will then go through an approval process. Once approved, you will receive your video through email.
                                        </div>
                                    )
                                }

                                <div className={"flex flex-row justify-between mt-4"}>
                                    <div
                                        className={`border border-white rounded px-4 py-2 transition-all duration-300 select-none ${activeTab === 1 ? 'opacity-50' : 'cursor-pointer hover:bg-white hover:text-gray-900'}`}
                                        onClick={() => {
                                            if(activeTab !== 1) {
                                                setActiveTab(activeTab - 1);
                                            }
                                        }}
                                    >
                                        Previous
                                    </div>
                                    <div
                                        className={`border border-transparent text-gray-900 bg-white rounded transition-all select-none duration-300 px-4 py-2 ${activeTab === 3 ? 'opacity-50' : 'cursor-pointer hover:bg-transparent hover:border-white hover:text-white'}`}
                                        onClick={() => {
                                            if(activeTab !== 3) {
                                                setActiveTab(activeTab + 1);
                                            }
                                        }}
                                    >
                                        Next
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}