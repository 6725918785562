import {approveVideoResult, denyVideoResult, getB2bVideoResults, renderVideo} from "../../Api/b2bVideoResultsApi";
import {deepCloneObject} from "../../Helpers/GeneralHelpers";

export const createB2bVideoResultSlice = (set, get) => ({
    b2bvideoresults: [],
    b2bvideoresultsMeta: [],
    fetchVideoResults: async (page) => {
        const videoResults = await getB2bVideoResults(page);
        set({b2bvideoresults: videoResults.data, b2bvideoresultsMeta: videoResults.meta});
    },
    approveVideo: async (videoId) => {
        const res = await approveVideoResult(videoId);
        const cloned = deepCloneObject(get().b2bvideoresults);
        const found = cloned.find((obj) => obj.id === videoId);
        const index = cloned.indexOf(found);
        cloned[index] = {...cloned[index], isApproved: 1}
        set({b2bvideoresults: cloned});
        return res.data;
    },
    denyVideo: async (videoId) => {
        await denyVideoResult(videoId);
        const cloned = deepCloneObject(get().b2bvideoresults);
        const found = cloned.find((obj) => obj.id === videoId);
        const index = cloned.indexOf(found);
        cloned.splice(index,1);
        set({b2bvideoresults: cloned});
    },
    renderVideo: async (params) => {
        const res = await renderVideo(params);
        return true;
    }
});