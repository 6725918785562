import "/node_modules/flag-icons/css/flag-icons.min.css";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

function FlagIcon({countryCode = ""}) {

    if (countryCode.includes('en')) {
        countryCode = "gb";
    }

    if (countryCode.includes('nl')) {
        countryCode = "nl";
    }

    return (
        <span
            className={`fi fis rounded-full inline-block mr-2 fi-${countryCode}`}
        />
    );
}

export const LocaleSwitcher = (props) => {
    const {
        bgColor = null
    } = props;
    const {i18n} = useTranslation();
    const [languages] = useState([
        {
            key:"nl-BE",
        },
        {
            key:"en-GB",
        },
        {
            key:"fr",
        }
    ]);
    const selectedLanguage = languages.find(language => language?.key === i18n.language);
    const [isOpen, setIsOpen] = useState(false);

    const handleLanguageChange = async (language) => {
        await i18n.changeLanguage(language.key);
        setIsOpen(false);
    };

    const LANGUAGE_SELECTOR_ID = 'language-selector';
    useEffect(() => {
        const handleWindowClick = (event) => {
            const target = event.target.closest('button');
            if (target && target.id === LANGUAGE_SELECTOR_ID) {
                return;
            }
            setIsOpen(false);
        }
        window.addEventListener('click', handleWindowClick)
        return () => {
            window.removeEventListener('click', handleWindowClick);
        }
    }, []);



    return (
        <div>
            <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="inline-flex items-center justify-center w-full rounded-md border border-white px-4 py-2 text-white text-sm font-medium focus:outline-none"
                id={LANGUAGE_SELECTOR_ID}
                aria-expanded={isOpen}
            >
                <FlagIcon countryCode={selectedLanguage?.key ?? 'en'}/>
                <svg
                    className="-mr-1 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        fillRule="evenodd"
                        d="M10.293 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>

            {isOpen && <div
                className={"absolute mt-2 w-fit rounded-md shadow-lg " + (bgColor !== null ? bgColor : 'bg-brandblue')}
                style={{zIndex: 9999}}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby={LANGUAGE_SELECTOR_ID}
            >
                <div className="py-1 grid grid-cols-1 gap-2 justify-center" role="none">
                    {languages.map((language, index) => {
                        return (
                            <button
                                key={language.key}
                                onClick={() => handleLanguageChange(language)}
                                className={`${
                                    selectedLanguage?.key === language?.key
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700"
                                } block px-4 py-2 text-sm text-left items-center inline-flex mx-auto hover:bg-gray-100 ${index % 2 === 0 ? 'rounded-r' : 'rounded-l'}`}
                                role="menuitem"
                            >
                                <FlagIcon countryCode={language.key}/>
                            </button>
                        );
                    })}
                </div>
            </div>}
        </div>
    )
}