import {Line} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    TimeScale,
    Tooltip,
    Legend,
} from 'chart.js';
import 'chartjs-adapter-moment';

export const LineChart = (props) => {
    const {
        data,
        labels
    } = props;

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        TimeScale,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        scales: {
            x: {
                type: 'time',
            }
        },
        plugins: {
            legend: {
                position: 'right',
                display: false
            },
            title: {display: false},
        },
    };

    const chartData = {
        labels: labels,
        datasets: data
    }

    return (
        <>
            <Line data={chartData} options={options}></Line>
        </>
    )
}