import {useEffect, useState} from "react";
import Pagination from "../../../Components/General/Pagination";
import {useBoundStore} from "../../../Store/store";
import {Link} from "react-router-dom";

export default function B2bCompaniesIndex() {
    const [page, setPage] = useState(1);

    const companies = useBoundStore((state) => state.b2bcompanies);
    const metaData = useBoundStore((state) => state.b2bCompaniesMetaData);
    const fetchB2bCompanies = useBoundStore((state) => state.fetchB2bCompanies);

    useEffect(() => {
        fetchB2bCompanies(page).then(r => {});
    },[fetchB2bCompanies, page]);

    const changePage = async (newPage) => {
        setPage(newPage);
    }

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>B2B Companies</h1>
            </div>

            <div className="grid grid-cols-3 text-left mb-2 text-white bg-primary uppercase rounded-tr rounded-tl">
                <div className={"p-2"}>ID</div>
                <div className={"p-2"}>Name</div>
                <div className={"p-2"}>Actions</div>
            </div>

            {
                companies.map((company) => {
                    return (
                        <div key={`b2b_companies_${company.id}`}>
                            <div className={"grid grid-cols-3 text-left border-b border-gray-500 border-opacity-30"} key={company.id}>
                                <Link to={`${company.id}`}>
                                    <div className={"p-2"}>{company.id}</div>
                                </Link>
                                <Link to={`${company.id}`}>
                                    <div className={"p-2"}>{company.name}</div>
                                </Link>
                                <div className={"p-2 text-gray-500 text-sm"}>Not available yet</div>
                            </div>
                        </div>
                    )
                })
            }

            <div className={"w-full text-right mb-8 flex flex-row justify-end px-8"}>
                <Pagination metaData={{...metaData, current_page: page}} changePage={changePage} page={page}/>
            </div>

        </>
    )
}