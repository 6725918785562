import {AuthClient} from "./HttpClient";

export function getAllUsers(page = 1) {
    return AuthClient()
        .get(`users?page=${page}`).then((res) => {
            return res.data
        });
}

export function getUser(id) {
    return AuthClient()
        .get(`users/${id}`).then((res) => {
            return res.data
        })
}

export function deleteUser(id){
    return AuthClient()
        .delete(`users/${id}`).then((res) => {
            return res.data
        })
}

