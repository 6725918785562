import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import Button from "../../../Components/Forms/Button";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {createSubCategory} from "../../../Api/subCategoriesApi";
import {getAllCategories} from "../../../Api/categoriesApi";

export default function SubcategoryCreate() {
    const [subcategory, setSubcategory] = useState({name: {en: null, fr:null, nl:null}, category_id: null});
    const [categories, setCategories] = useState([]);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        (async function() {
            const allCategories = await getAllCategories();
            setCategories(allCategories);
        })()
    }, [])

    const create = async () => {
        const response = await createSubCategory(subcategory);
        if('errors' in response){
            setErrors(response.errors);

            toast.error('Not all data has been filled in correctly',{
                position: "top-right"
            });
            return false;
        }else{
            setErrors([]);
        }

        setSubcategory(response.data);

        toast.success('Subcategory has been successfully created',{
            position: "top-right"
        });

        navigate('/admin/subcategories', {
            replace: true
        });
    }

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>Subcategory create</h1>
                <Link to={'/admin/subcategories'} className={"py-2 px-4 ease-in-out duration-300 text-secondary hover:text-primary cursor-pointer transition-all"}>
                    <FontAwesomeIcon icon={icon({name: 'arrow-left'})} className={"mr-2"}/>
                    Back to subcategories
                </Link>
            </div>

            <div>
                <p className={"text-xl text-left mb-2"}>Name</p>
                <div className={"text-left mt-4"}>
                    <label htmlFor={"name"} className={"text-left pl-4"}>NL</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        onChange={(e) => {
                            subcategory.name.nl = e.target.value;
                        }}
                        id={"nameNl"}
                        name={"nameNl"}
                        type={"text"}
                        required
                    />
                </div>

                {
                    'name.nl' in errors &&
                    <span className={"text-red-600 text-left"}>{errors['name.nl']}</span>
                }

                <div className={"text-left mt-4"}>
                    <label htmlFor={"name"} className={"text-left pl-4"}>FR</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        onChange={(e) => {
                            subcategory.name.fr = e.target.value;
                        }}
                        id={"nameFr"}
                        name={"nameFr"}
                        type={"text"}
                        required
                    />
                </div>

                {
                    'name.fr' in errors &&
                    <span className={"text-red-600 text-left"}>{errors['name.fr']}</span>
                }

                <div className={"text-left mt-4"}>
                    <label htmlFor={"name"} className={"text-left pl-4"}>EN</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        onChange={(e) => {
                            subcategory.name.en = e.target.value;
                        }}
                        id={"nameEn"}
                        name={"nameEn"}
                        type={"text"}
                        required
                    />
                </div>

                {
                    'name.en' in errors &&
                    <span className={"text-red-600 text-left"}>{errors['name.en']}</span>
                }

                <div className={"text-left mt-4"}>
                    <label htmlFor={"category"} className={"text-left pl-4"}>Category</label>
                    <select
                        name={"category"}
                        className={"w-full border border-secondary rounded py-2 mt-1 px-4"}
                        onChange={(e) => {
                            subcategory.category_id = e.target.value;
                        }}
                        defaultValue={subcategory?.category_id}
                    >

                        {
                            categories?.map((category) => {
                                return (
                                    <option
                                        value={category.id}
                                        key={`category_select_subcategory_${category.id}`}
                                        selected={category.id === subcategory?.category_id}
                                    >
                                        {category.name}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>

                {
                    'category_id' in errors &&
                    <span className={"text-red-600 text-left"}>{errors['category_id']}</span>
                }

                <div className={"flex flex-row justify-end mt-2"}>
                    <Button
                        buttonText={"Create subcategory"}
                        clickEvent={create}
                    >
                    </Button>
                </div>
            </div>
        </>
    )
}