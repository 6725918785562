import '../../Assets/css/b2b/secretgarden/index.css';
import {useBoundStore} from "../../Store/store";
import React, {useEffect, useState} from "react";
import {VideoBlock} from "../../Components/Video_Editor/VideoBlock";
import {PresetVideoLoaded} from "../../Components/Video_Editor/EditorComponents/PresetVideoLoaded";
import toast from "react-hot-toast";
import {faCheck, faMultiply, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";
import SecretGardenContactInfoPopup from "../../Components/Popups/secretgarden/SecretGardenContactInfoPopup";
import {Helmet} from "react-helmet";

export const SecretGarden = () => {
    const fetchB2bTemplate = useBoundStore((state) => state.fetchB2bTemplate);
    const templateDetails = useBoundStore((state) => state.b2btemplate);
    const videos = useBoundStore((state) => state.videos);
    const renderVideo = useBoundStore((state) => state.renderVideo);
    const videoTimings = useBoundStore(state => state.videoTimings);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchB2bTemplate('10-years-secret-garden').then(() => {});
    }, [fetchB2bTemplate])

    const getPresetVideoAtIndex = (index) => {
        if(templateDetails.jsonConfig?.presetVideos !== null && templateDetails.jsonConfig?.presetVideos !== undefined)
        {
            const found = templateDetails.jsonConfig.presetVideos.find((item) => item.position === index + 1);
            if(found){
                return templateDetails.presetVideos.find((video) => video.fileName === found.video);
            }
        }

        return null;
    }

    const handleDataSubmitted = (data) => {
        setShowModal(false);
        const params = {
            contactInformation: {
                firstname: data.firstname,
                lastname: data.lastname,
                email: data.email,
            },
            b2bTemplateId: templateDetails.id,
            b2bClient: 4,
            videoTimings: videoTimings.map((timing) => [timing.start, timing.end]),
            videos: videos.filter((value) => value != null).map((vid) => vid.files.id)
        }

        renderVideo(params).then(() => {
            toast.custom((t) => (
                <div
                    className={`${
                        t.visible ? 'animate-enter' : 'animate-leave'
                    } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex flex-col md:flex-row ring-1 ring-black ring-opacity-5 items-center`}
                >
                    <div className="flex-shrink-0 w-[50px] pt-0.5 m-auto text-center text-2xl m-1 mt-2 p-2">
                        <FontAwesomeIcon icon={faCheck} className={"text-green-500 m-auto"} />
                    </div>
                    <div className="ml-3 flex-1 m-1 p-2">
                        <p className="text-sm font-medium text-gray-900">
                            Your video request has been sent! Once your video is ready, you will receive it by email. Please make sure to check your spam folder to ensure you receive your result.
                        </p>
                    </div>
                    <div className="flex border-t md:border-t-0 md:border-l border-gray-200 m-1 p-2">
                        <button
                            onClick={() => toast.dismiss(t.id)}
                            className="w-full md:border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-grey-900 focus:outline-none"
                        >
                            <FontAwesomeIcon icon={faMultiply} className={''}/>
                        </button>
                    </div>
                </div>
            ),{duration: 15000})
        })
    }

    return (
        <div className={'secret-garden-page min-h-screen flex flex-col justify-center bg-white'}>

            <Helmet>
                <script defer data-domain="10yearsofsecretgarden.exidofficial.com" src="https://analytics.devium.dev/js/script.js"></script>
            </Helmet>

            <div className={"w-full border-b border-gray-200 select-none"}>
                <img src={'img/b2bimages/secretgarden/logo.png'} className={"h-24 mx-auto"} alt={'Logo Secret garden'}/>
            </div>

            <div className={" w-full flex flex-col md:flex-row justify-evenly justify-center md:border-b border-gray-200 overflow-hidden"}>

                <div className={"min-h-[30vh] md:min-h-[60vh] w-full md:w-[50vw]"} style={{
                    backgroundImage: 'url("img/b2bimages/secretgarden/banner.jpg")',
                    backgroundSize: 'cover',
                    backgroundPosition:'center',
                    backgroundRepeat:'no-repeat'
                }}>
                </div>
                <div className={'min-h-[30vh] md:min-h-[60vh] border-r grid grid-cols-1 content-end justify-end w-16 hidden md:grid'}>
                    <div className={"border-t border-gray-200 p-2 py-4 flex flex-col justify-center"}>
                        <a href={'https://www.instagram.com/secretgardenoutdoor/'} target={'_blank'}>
                            <FontAwesomeIcon icon={faInstagram} className={"cursor-pointer h-8 m-auto purpleColor"}/>
                        </a>
                    </div>
                    <div className={"border-t border-gray-200 p-2 py-4 flex flex-col justify-center"}>
                        <a href={'https://www.facebook.com/secretgardenoutdoor/'} target={'_blank'}>
                            <FontAwesomeIcon icon={faFacebook} className={"cursor-pointer h-8 m-auto purpleColor"}/>
                        </a>
                    </div>
                </div>
                <div className={"flex flex-col justify-center m-auto relative flex-row mt-20 md:m-auto p-4 select-none"}>
                    <img src={"img/b2bimages/secretgarden/background-grass.png"} className={'w-full absolute -z-1'}/>
                    <div className={'h-full'}>
                        <p className={"uppercase text-xl md:text-4xl text-white text-left z-10 relative"}>create your personal<br/>secret garden memory</p>
                        <div className={"border-b border-black w-16 border-2 z-10 relative borderPurple mt-2"}></div>
                    </div>
                </div>

            </div>


            <div className={"p-4"}>
                <div className={"text-5xl text-white mt-16"}>
                    <div className={'flex container mx-auto flex-col md:flex-row'}>
                        {
                            templateDetails?.jsonConfig?.clips.map((detail, index) => {
                                const found = getPresetVideoAtIndex(index);
                                if(found === null) return <div className={"grid grid-col-1 justify-center"}>
                                    <VideoBlock
                                        classes={'max-w-[80vw] min-w-[40vw] md:w-full max-h-[40vh]'}
                                        index={index}
                                        videoLength={detail}
                                        key={`videoblock_${index}`}
                                        showIndexNumber={false}
                                        backgroundColor={'rgba(0,0,0,0)'}
                                        icon={faUpload}
                                        showSupportTextClickUpload={true}
                                        innerTextColor={'#0e0e0f'}
                                        buttonclasses={"text-black m-2 text-[20px] rounded cursor-pointer"}
                                        blockBorder={'1px solid #e5e7eb'}
                                        roundedBorder={false}
                                    />
                                </div>
                                else return <PresetVideoLoaded key={`preset_video_${index}`} fileUrl={found.url} classes={"h-[300px] md:max-h-[40vh] mt-4"} heightClass={'h-[250px] md:h-full'} buttonclasses={"bg-grey-900 text-black m-2 text-[20px] rounded cursor-pointer"} />
                            })
                        }
                    </div>

                    {
                        !(videos.length === 0 || videos[1] === undefined || videos[1] === null) && (
                            <div className={"flex flex-col w-full justify-center mt-4 select-none"}>
                                <div className={"py-2 px-4 border borderPurple cursor-pointer w-fit bgPurple text-white text-xl mx-auto hover:bg-transparent hover:colorPurple duration-300 transition-all"} onClick={() => {setShowModal(true)}}>Create my video</div>
                            </div>
                        )
                    }
                </div>

                <div className={"text-5xl text-white my-4"}>
                {
                    (videos.length === 0 || videos[1] === undefined || videos[1] === null) && (
                        <div className={"flex flex-col w-full justify-center select-none"}>
                            <div className={"py-2 px-4 border borderPurple w-fit bgPurple text-white text-xl mx-auto duration-300 transition-all opacity-70"} onClick={() => {}}>Create my video</div>
                        </div>
                    )
                }
                </div>

                <SecretGardenContactInfoPopup
                    isShown={showModal} closePopup={() => setShowModal(false)} clickConfirmed={handleDataSubmitted}
                />
            </div>

            <div className={"bgPurple p-4"}>
                <p className={"text-2xl mb-6 mt-4"} style={{color:'white'}}>Sponsors</p>
                <div className={"grid grid-cols-1 md:grid-cols-3 container mx-auto justify-evenly space-y-6 md:space-y-0 items-center"}>
                    <a href={"https://www.secretgardenoutdoor.be/"} target={"_blank"} rel="noreferrer">
                        <img src={'img/b2bimages/secretgarden/logo-white.png'} className={"grayscale max-h-16 w-auto mx-auto"} alt={'Logo of secret garden'}/>
                    </a>
                    <a href={"https://exidofficial.com"} target={"_blank"} rel="noreferrer">
                        <img src={'img/b2bimages/olf/exid-logo.png'} className={"max-h-16 w-auto mx-auto"} alt={"Logo of EXID"}/>
                    </a>
                    <a href={"https://devium.be"} target={"_blank"} rel="noreferrer">
                        <img src={'img/b2bimages/olf/devium-logo.png'} className={"max-h-16 w-auto mx-auto"} alt={"Logo of Devium"}/>
                    </a>
                </div>
            </div>

        </div>
    );
}