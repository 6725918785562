import Footer from "../Components/General/Footer";
import BareHomeNavigation from "../Components/Navigation/BareHomeNavigation";
import B2bTermsOfServiceContent from "../Components/legal/B2bTermsOfServiceContent";

export default function B2bTermsOfservice() {
    return (
        <>
            <BareHomeNavigation />
            <B2bTermsOfServiceContent />
        </>
    );
}