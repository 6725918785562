import React from "react";
import {useBoundStore} from "../../../Store/store";

export const VideoUploadingProgress = (props) => {
    const {
        index,
        backgroundColor,
        showIndexNumber,
        innerTextColor,
        blockBorder,
        roundedBorder
    } = props;

    const uploadingProgress = useBoundStore((state) => state.uploadingProgress)

    return (
        <div className={`w-full relative ${roundedBorder ? 'rounded-xl' : ''} flex flex-col justify-center min-h-[150px] h-full m-2`} style={{backgroundColor:backgroundColor, border:blockBorder}}>

            {
                showIndexNumber && (
                    <div className={"absolute bg-secondary h-8 w-8 rounded-full -right-2 -top-3 flex flex-col justify-center items-center"}>
                        <div className={"bg-[#025182] rounded-full w-6 h-6 text-white text-sm m-auto flex flex-col justify-center items-center"}>{index +1}</div>
                    </div>
                )
            }

            <div className={"text-5xl mx-auto"}>
                <span style={{ color: innerTextColor }}>{uploadingProgress[index]?.progress} % </span>
            </div>
        </div>
    )
}