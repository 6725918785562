import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Link} from "react-router-dom";
import ConfirmDeleteModal from "../../../Components/Popups/ConfirmDeleteModal";
import toast from "react-hot-toast";
import {getAllPromocodes, deletePromocodes} from "../../../Api/promocodesApi";
import moment from 'moment';
import Pagination from "../../../Components/General/Pagination";
import {useBoundStore} from "../../../Store/store";

export default function PromocodeIndex() {
    const [isConfirmDeleteShown, setIsConfirmDeleteShown] = useState(false);
    const [deletingPromocodeID, setDeletingPromocodeID] = useState(null);
    const [page, setPage] = useState(1);

    const promocodes = useBoundStore((state) => state.promoCodes);
    const metaData = useBoundStore((state) => state.promoCodesMetaData);
    const fetchPromoCodes = useBoundStore((state) => state.fetchPromoCodes);

    useEffect(() => {
        fetchPromoCodes(page).then(r => {});
    },[fetchPromoCodes, page]);

    const changePage = async (newPage) => {
        setPage(newPage);
    }

    const handleCloseConfirmDelete = () => {
        setIsConfirmDeleteShown(false);
        setDeletingPromocodeID(null);
    }

    const handleOpenConfirmDelete = (id) => {
        setIsConfirmDeleteShown(true);
        setDeletingPromocodeID(id);
    }

    const deletePromocodeAction = async () => {
        if(deletingPromocodeID === null) return false;

        await deletePromocodes(deletingPromocodeID);
        fetchPromoCodes(page).then(r => {});

        handleCloseConfirmDelete();

        toast.success('Promo code has been successfully deleted',{
            position: "top-right"
        });
    }

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>Promo codes</h1>
                <Link to={'/admin/promo-codes/create'} className={"py-2 px-4 ease-in-out duration-300 bg-secondary text-white cursor-pointer rounded-lg border border-transparent transition-all hover:bg-transparent hover:text-secondary hover:border hover:border-secondary"}>
                    Create a new Promo code
                </Link>
            </div>

            <div className="grid grid-cols-7 text-left mb-2 text-white bg-primary uppercase rounded-tr rounded-tl">
                <div className={"p-2"}>ID</div>
                <div className={"p-2"}>Code</div>
                <div className={"p-2"}>Percentage</div>
                <div className={"p-2"}>Valid until</div>
                <div className={"p-2"}>Valid usages</div>
                <div className={"p-2"}>Times used</div>
                <div className={"p-2"}>Actions</div>
            </div>

            {
                promocodes.map((promocode) => {
                    return (
                        <div key={`tags_${promocode.id}`}>
                            <div className={"grid grid-cols-7 text-left border-b border-gray-500 border-opacity-30"} >
                                <Link to={`${promocode.id}`}>
                                    <div className={"p-2"}>{promocode.id}</div>
                                </Link>
                                <Link to={`${promocode.id}`}>
                                    <div className={"p-2"}>{promocode.code}</div>
                                </Link>
                                <Link to={`${promocode.id}`}>
                                    <div className={"p-2"}>{promocode.percentage} %</div>
                                </Link>
                                <Link to={`${promocode.id}`}>
                                    <div className={"p-2"}>{promocode?.validUntil ? moment(promocode?.validUntil).format('DD/MM/YYYY HH:mm') : ''}</div>
                                </Link>
                                <Link to={`${promocode.id}`}>
                                    <div className={"p-2"}>{promocode?.validUsagesLeft}</div>
                                </Link>
                                <Link to={`${promocode.id}`}>
                                    <div className={"p-2"}>{promocode?.amountUsed}</div>
                                </Link>

                                <div className={"grid grid-cols-3 p-2"}>
                                    <div>
                                        <FontAwesomeIcon onClick={() => {handleOpenConfirmDelete(promocode.id)}} icon={icon({name:"trash"})} className={"text-red-600 cursor-pointer"}/>
                                    </div>
                                    <Link to={`${promocode.id}/update`}>
                                        <div className={"text-right"}>
                                            <FontAwesomeIcon icon={icon({name:"edit"})} className={"cursor-pointer text-gray-500 text-right"}/>
                                        </div>
                                    </Link>

                                    <Link to={`${promocode.id}`}>
                                        <div className={"text-right"}>
                                            <FontAwesomeIcon icon={icon({name:"angle-right"})} className={"cursor-pointer text-gray-500 text-right"}/>
                                        </div>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    )
                })
            }

            <div className={"w-full text-right mb-8 flex flex-row justify-end px-8"}>
                <Pagination metaData={{...metaData, current_page: page}} changePage={changePage} page={page}/>
            </div>

            <ConfirmDeleteModal
                isShown={isConfirmDeleteShown}
                closePopup={handleCloseConfirmDelete}
                clickConfirmed={deletePromocodeAction}
            ></ConfirmDeleteModal>
        </>
    )
}