import {useContext} from "react";
import AuthContext from "./AuthenticationHelper";
import {Navigate, Outlet} from "react-router-dom";

export default function ProtectedRoutes(props) {
    const {hasRole} = useContext(AuthContext);
    const { roles, complyToAll = false, isQr = false } = props;

    if(complyToAll){
        let compliedToAll = true;
        roles.map((role) => {
            if(!hasRole(role)) compliedToAll = false;
        });

        if(compliedToAll){
            return <Outlet />
        } else {
            return <Navigate to={`/${isQr ? 'qr/login' : ''}`}/>
        }

    } else {
        let hasHitArole = false;
        roles.map((role) => {
            if(hasRole(role)) hasHitArole = true;
        });

        if(hasHitArole){
            return <Outlet />
        } else {
            return <Navigate to={`/${isQr ? 'qr/login' : ''}`} />
        }
    }
}