import axios from "axios";
import i18n from "../Translations/i18n";

export function AuthClient(base = false) {
    const accessToken = localStorage.getItem('exid_user_access_token');
    if(accessToken === null || accessToken === undefined) return axiosClient();

    return axios.create({
        baseURL: base ? process.env.REACT_APP_BASE_API_URL : process.env.REACT_APP_API_URL,
        headers:{
            Accept: 'application/json',
            Authorization: 'Bearer ' + accessToken,
            "Accept-Language": i18n.language
        }
    })
}

export function axiosClient(base = false){
    return axios.create({
        baseURL: base ? process.env.REACT_APP_BASE_API_URL : process.env.REACT_APP_API_URL,
        headers:{
            Accept: 'application/json',
            "Accept-Language": i18n.language
        }
    })
}