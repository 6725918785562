import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import Button from "../../../Components/Forms/Button";
import {useState} from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {createPromocodes} from "../../../Api/promocodesApi";
import moment from 'moment';

export default function PromocodeCreate() {
    const [promocode, setPromocode] = useState({
        code: null,
        percentage: 0,
        validUntil: null,
        validUsagesLeft: null
    });
    const navigate = useNavigate();

    const create = async () => {
        const response = await createPromocodes(promocode);
        setPromocode(response);

        toast.success('Promo code has been successfully created',{
            position: "top-right"
        });

        navigate('/admin/promo-codes', {
            replace: true
        });
    }

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>Promo code create</h1>
                <Link to={'/admin/promo-codes'} className={"py-2 px-4 ease-in-out duration-300 text-secondary hover:text-primary cursor-pointer transition-all"}>
                    <FontAwesomeIcon icon={icon({name: 'arrow-left'})} className={"mr-2"}/>
                    Back to promo codes
                </Link>
            </div>

            <div className={"flex flex-col gap-y-8"}>
                <div className={"text-left"}>
                    <label htmlFor={"code"} className={"text-left pl-4"}>Code *</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        onChange={(e) => {
                            promocode.code = e.target.value;
                        }}
                        id={"code"}
                        name={"code"}
                        type={"text"}
                        required
                    />
                </div>

                <div className={"text-left"}>
                    <label htmlFor={"percentage"} className={"text-left pl-4"}>Percentage *</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        onChange={(e) => {
                            promocode.percentage = e.target.value;
                        }}
                        id={"percentage"}
                        name={"percentage"}
                        type={"number"}
                        min={0}
                        max={100}
                        required
                    />
                </div>

                <div className={"text-left"}>
                    <label htmlFor={"validUntil"} className={"text-left pl-4 mt-2"}>Valid until</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        onChange={(e) => {
                            promocode.validUntil = moment(e.target.value).format("YYYY-MM-DDTHH:mm:ssZ");
                        }}
                        id={"validUntil"}
                        name={"validUntil"}
                        type={"datetime-local"}
                    />
                </div>

                <div className={"text-left"}>
                    <label htmlFor={"validUsagesLeft"} className={"text-left pl-4 mt-2"}>Valid usages</label>
                    <input
                        className="
                        text-black w-full
                        rounded-md bg-transparent
                        placeholder:text-gray-500
                        border-b
                        outline-0 p-4"
                        onChange={(e) => {
                            promocode.validUsagesLeft = e.target.value;
                        }}
                        id={"validUsagesLeft"}
                        name={"validUsagesLeft"}
                        type={"number"}
                        min={0}
                    />
                </div>

                <div className={"flex flex-row justify-end mt-2"}>
                    <Button
                        buttonText={"Create promo code"}
                        clickEvent={create}
                    >
                    </Button>
                </div>
            </div>
        </>
    )
}