import {getAllCategories, getAllCategoriesPaginated} from "../../Api/categoriesApi";

export const createCategorySlice = (set) => ({
    categories: [],
    categoriesMetaData: [],
    fetchCategories: async () => {
        const categories = await getAllCategories();
        set({categories: categories});
    },
    fetchCategoriesPaginated: async (page=1) => {
        const categories = await getAllCategoriesPaginated(page);
        set({categories: categories.data, categoriesMetaData: categories.meta});
    }
});