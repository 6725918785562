import AdminTopNavigation from "../../Components/Navigation/AdminTopNavigation";
import AdminSideNavigation from "../../Components/Navigation/AdminSideNavigation";

export default function AdminLayout({ children }) {
    return (
        <>
            <div className={"w-full flex flex-row"}>
                <div className={"w-1/5"}>
                    <AdminSideNavigation></AdminSideNavigation>
                </div>

                <div className={"w-4/5 bg-white"}>
                    <AdminTopNavigation></AdminTopNavigation>
                    <div className={"p-4"}>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}