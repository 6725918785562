import {useTranslation} from "react-i18next";
import {axiosClient} from "../../Api/HttpClient";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";

export default function SettingsDropboxModal(props) {
    const {isShown, closePopup, settings, dropboxKey} = props;
    const [settingsState, setSettingsState] = useState(null);
    const {t} = useTranslation();

    const handleCloseClick = () => {
        closePopup(null);
    }

    useEffect(() => {
        setSettingsState(settings);
    }, []);

    const setPrivateValue = (value) => {
        if(settingsState.only_private) return;

        axiosClient().post(`/dropbox/${dropboxKey}/private-setting`, {
            private: value ? 1 : 0
        },{
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('exid_user_access_token')}`
            },
        }).then((res) => {
            if(res.data){
                setSettingsState(prevState => ({
                    ...prevState,
                    private: res.data.private
                }));
                toast.success(t('qr.settings_updated_successfully'));
            }
        })
    }

    return (
        <>
            { isShown &&
                <>
                    <div className={"h-screen z-50 w-screen bg-gray-800 fixed top-0 left-0 bg-opacity-60"}></div>
                    <div tabIndex="-1" aria-hidden={!isShown} aria-modal={true} role={"dialog"} aria-labelledby={"SettingsDropboxModal"}
                         className={"transition-all ease-in-out overflow-y-auto overflow-x-hidden flex flex-col justify-center fixed top-0 right-0 left-0 z-50 w-full md:inset-0 md:h-full" + (!isShown ? 'hidden' : '')}>
                        <div className="relative p-4 w-full max-w-lg m-auto">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div className="flex flex-row justify-between bg-primary py-3 px-4 border-b border-white rounded-tl-lg rounded-tr-lg">
                                    <h3 className="text-xl font-medium text-white my-auto">{t('qr.settings')}</h3>
                                    <button type="button" onClick={handleCloseClick}
                                            className="text-white bg-transparent hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule={"evenodd"} d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule={"evenodd"}></path>
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>


                                <div className="py-6 px-6 lg:px-8 bg-[#FFF8EC] text-gray-600">

                                    <div className={'my-4'}>
                                        <label
                                            htmlFor="isPrivate"
                                            className="text-xs font-semibold text-gray-500"
                                        >
                                            <input type="checkbox"
                                                   id="isPrivate"
                                                   name="isPrivate"
                                                   disabled={settingsState.only_private === 1 || settingsState.only_private === true}
                                                   checked={settingsState.private === 1}
                                                   onChange={(e) => {
                                                       setPrivateValue(e.target.checked)
                                                   }}
                                                   className="mr-4 rounded border-gray-300 bg-gray-50 outline-none transition"
                                            />
                                            {t('qr.is_private_gallery')}
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}