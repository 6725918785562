import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {getPromocodes} from "../../../Api/promocodesApi";
import moment from "moment/moment";

export default function PromocodeDetail() {
    const [promocode, setPromocode] = useState();
    const { id } = useParams()

    useEffect(() => {
        (async function() {
            setPromocode(await getPromocodes(id));
        })()
    }, [id])

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>Promo code detail</h1>
                <Link to={'/admin/promo-codes'} className={"py-2 px-4 ease-in-out duration-300 text-secondary hover:text-primary cursor-pointer transition-all"}>
                    <FontAwesomeIcon icon={icon({name: 'arrow-left'})} className={"mr-2"}/>
                    Back to promo codes
                </Link>
            </div>

            <div className={"grid grid-cols-3 gap-y-8"}>
                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>ID</p>
                    <p className={"text-left"}>{promocode?.id}</p>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>CODE</p>
                    <p className={"text-left"}>{promocode?.code}</p>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>PERCENTAGE</p>
                    <p className={"text-left"}>{promocode?.percentage} %</p>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>VALID UNTIL</p>
                    <p className={"text-left"}>{promocode?.validUntil ? moment(promocode?.validUntil).format('DD/MM/YYYY HH:mm') : '/'}</p>
                </div>

                <div>
                    <p className={"text-inputsecondary uppercase text-md text-left mb-1"}>USAGES LEFT</p>
                    <p className={"text-left"}>{promocode?.validUsagesLeft}</p>
                </div>
            </div>
        </>
    )
}