import {AuthClient} from "./HttpClient";

export function getAllTemplates(page = 1) {
    return AuthClient()
        .get(`templates?page=${page}`)
        .then((res) => {
        return res.data
    });
}

export function getFilteredTemplates(search = "", categories = [], page = 1) {
    const searchParams = new URLSearchParams();
    searchParams.append('page', page);
    if(search && search !== "") {
        searchParams.append("search", search);
    }

    if(categories && categories.length > 0) {
        categories.forEach(category_id => {
            searchParams.append("subcategories[]", category_id);
        });
    }

    return AuthClient()
        .get(`templates-filtered?${searchParams.toString()}`).then((res) => {
            return res.data;
        });
}

export function getTemplate(id) {
    return AuthClient()
        .get(`templates/${id}`)
        .then((res) => {
            return res.data
        });
}

export function deleteTemplate(id){
    return AuthClient()
        .delete(`templates/${id}`).then((res) => {
            return res.data
        })
}

export function updateTemplate(id, params){
    const data = new FormData();
    if(params.songFile.size !== undefined) {
        data.append('songFile', params.songFile ,params.songFile.name);
    }

    for (const key in params) {
        if(key === 'id' || key === 'songFile') continue;

        data.append(key, params[key]);
    }

    return AuthClient()
        .post(`templates/${id}`, data).then((res)=>{
            return res.data
        })
}

export function createTemplate(params){
    const data = new FormData();
    if(params?.songFile?.size !== undefined) {
        data.append('songFile', params.songFile ,params.songFile.name);
    }

    for (const key in params) {
        if(key === 'id' || key === 'songFile') continue;

        data.append(key, params[key]);
    }

    return AuthClient()
        .post(`templates`, data).then((res)=>{
            return res.data
        }).catch((res) => {
            return res.response.data;
        })
}