import Button from "../../Forms/Button";
import {useTranslation} from "react-i18next";
import { useState} from "react";
import {passwordReset} from "../../../Api/authenticationApi";
import toast from "react-hot-toast";

export default function ForgotPasswordForm(props) {
    const {
        handleCloseClick,
        changePage
    } = props;
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [email, setEmail] = useState();

    const handlePasswordReset = async () => {
        const res = await passwordReset({"email": email});
        if("errors" in res) {
            setErrors([{
                code: res.errors,
                message: res.message
            }]);
        }else{
            setErrors([]);

            toast.success('An email to reset your password has been send',{
                position: "top-right"
            });

            handleCloseClick()
        }
    }

    return (
        <div>
            <div className="flex flex-row justify-between bg-primary py-3 px-4 border-b border-white rounded-tl-lg rounded-tr-lg">
                <h3 className="text-xl font-medium text-white my-auto">Forgot password</h3>
                <button type="button" onClick={handleCloseClick}
                        className="text-white bg-transparent hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule={"evenodd"} d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule={"evenodd"}></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>


            <div className="py-6 px-6 lg:px-8 bg-secondary">
                {errors.length > 0 &&
                    <>
                        <div className={"w-full bg-red-500 border border-red-900 mb-2"}>
                            <p className={"m-auto py-2 text-red-900"}>{errors[0].message}</p>
                        </div>
                    </>
                }

                <form className="space-y-6" action="#">
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-white text-left">{t('form_labels.email')}</label>
                        <input type="email" name="email" id="email" value={email}
                               onChange={(e) => {setEmail(e.target.value)}}
                               className="bg-inputsecondary text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                    </div>

                    <Button
                        buttonText={t('authentication.reset_password_button')}
                        clickEvent={handlePasswordReset}
                    >
                    </Button>

                    <div className="text-sm font-medium text-gray-500 dark:text-gray-300 cursor-pointer" onClick={() => {changePage('login')}}>
                        {t('authentication.back_to_login')}
                    </div>
                </form>
            </div>
        </div>
    )
}