import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPause, faPlay, faRotateLeft, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useRef, useState} from "react";
import {useBoundStore} from "../../../Store/store";
import {TimelineSlider} from "./TimelineSlider";

export const VideoLoaded = (props) => {
    const {
        index,
        video,
        videoLength,
        buttonclasses,
    } = props;

    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);
    const deleteVideo = useBoundStore((state) => state.deleteVideo);
    const setTotalVideoTimeForVideo = useBoundStore((state) => state.setTotalVideoTimeForVideo);
    const getVideoTimingsForIndex = useBoundStore((state) => state.getVideoTimingsForIndex);

    const handleDeleteVideo = () => {
        deleteVideo(index);
    }

    const playVideo = (ignoreCheck = false) => {
        const timings = getVideoTimingsForIndex(index);
        if(videoRef.current.currentTime * 1000 >= timings.end){
            return;
        }

        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    }

    const resetVideo = () => {
        const timings = getVideoTimingsForIndex(index);
        videoRef.current.currentTime = timings.start / 1000;
    }

    const handleLoad = (e) => {
        setTotalVideoTimeForVideo(index, e.target.duration * 1000);
    }

    const getButtonClass = () => {
        if(buttonclasses !== ''){
            return 'w-8 h-8 rounded bg-gray-400 flex flex-col justify-center m-1 cursor-pointer'
        } else {
            return `w-8 h-8 rounded flex flex-col justify-center m-1 cursor-pointer ${buttonclasses}`;
        }
    }

    const handleTimeUpdate = (e) => {
        const currentTime = e.target.currentTime * 1000;
        const timings = getVideoTimingsForIndex(index);
        if(currentTime >= timings.end){
            videoRef.current.pause();
            setIsPlaying(false);
        }
    }

    return (
        <div className={"w-full relative rounded-xl flex flex-col justify-center min-h-[150px] m-2"}>
            <video ref={videoRef} muted={true} className={"rounded-md max-h-[30vh]"} onLoadedData={handleLoad} onTimeUpdate={handleTimeUpdate}>
                <source src={`${video?.files?.url}#t=0.001`} type="video/mp4"/>
            </video>

            <TimelineSlider index={index} videoLength={videoLength?.duration} videoRef={videoRef}/>

            <div className={"flex flex-row justify-center"}>
                <div className={buttonclasses} onClick={playVideo}>
                    {isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
                </div>
                <div className={buttonclasses} onClick={resetVideo}>
                    <FontAwesomeIcon icon={faRotateLeft} />
                </div>
                <div className={buttonclasses} onClick={handleDeleteVideo}>
                    <FontAwesomeIcon icon={faTrash} />
                </div>
            </div>
        </div>
    )
}