import {useEffect, useState} from "react";
import {useBoundStore} from "../../../Store/store";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {HasCorrectRole} from "../../../Components/Validation/HasCorrectRole";
import {deepCloneObject} from "../../../Helpers/GeneralHelpers";

export default function B2bCompaniesDetail() {
    const {id} = useParams();

    const getB2bCompany = useBoundStore((state) => state.getB2bCompany);
    const fetchB2bCompanies = useBoundStore((state) => state.fetchB2bCompanies);
    const fetchB2bCompanyTemplates = useBoundStore((state) => state.fetchB2bCompanyTemplates);
    const b2bCompanyTemplates = useBoundStore((state) => state.b2bCompanyTemplates);
    const [company, setCompany] = useState({});

    useEffect(() => {
        fetchB2bCompanies().then(() => {
            fetchB2bCompanyTemplates(id).then(() => {})
            setCompany(getB2bCompany(id));
        })
    },[fetchB2bCompanies, id, fetchB2bCompanyTemplates]);

    const getTotalVisitos = (data) => {
        if(data.analytics.length === 0) return 0

        const cloned = deepCloneObject(data.analytics);

        delete cloned['countUploaded'];
        delete cloned['countDenied'];
        delete cloned['countRendered'];

        let sumCount = 0;
        Object.values(cloned).map((item) => sumCount += item.visitors);

        return sumCount
    }

    const getUploadVsRendered = (data) => {
        if(
            data.analytics?.countUploaded?.length === undefined ||
            data.analytics?.countUploaded?.length === null ||
            data.analytics?.countUploaded?.length === 0 ||
            data.analytics?.countUploaded?.length === "0" ||
            data.analytics?.countRendered?.length === undefined ||
            data.analytics?.countRendered?.length === null ||
            data.analytics?.countRendered?.length === 0 ||
            data.analytics?.countRendered?.length === "0"
        ) return '0 %'
        return ((data.analytics.countRendered.length / data.analytics.countUploaded.length) * 100).toFixed(2) + ' %'
    }

    const getTotalRenders = (data) => {
        return data.analytics.countRendered?.length ?? 0
    }

    return (
        <>
            <div className={"w-full flex flex-row justify-between mb-4 mt-4"}>
                <h1 className={"text-primary text-3xl"}>{company?.name} templates</h1>
                <HasCorrectRole roles={['admin']}>
                    <Link to={'/admin/b2b-companies'} className={"py-2 px-4 ease-in-out duration-300 text-secondary hover:text-primary cursor-pointer transition-all"}>
                        <FontAwesomeIcon icon={icon({name: 'arrow-left'})} className={"mr-2"}/>
                        Back to B2b companies
                    </Link>
                </HasCorrectRole>
            </div>

            <div className="grid grid-cols-6 text-left mb-2 text-white bg-primary uppercase rounded-tr rounded-tl">
                <div className={"p-2"}>ID</div>
                <div className={"p-2"}>Name</div>
                <div className={"p-2"}>Bezoekers</div>
                <div className={"p-2"}>Rend vs upload</div>
                <div className={"p-2"}>Totaal renders</div>
                <div className={"p-2"}>Actions</div>
            </div>

            {
                b2bCompanyTemplates !== null &&
                b2bCompanyTemplates !== undefined &&
                b2bCompanyTemplates.length > 0 &&
                b2bCompanyTemplates.map((template) => {
                    return (
                        <div key={`b2b_companies_${company.id}_template_${template.id}`}>
                            <div className={"grid grid-cols-6 text-left border-b border-gray-500 border-opacity-30"}>
                                <div className={"p-2"}>{template.template.id}</div>
                                <div className={"p-2"}>{template.template.slug}</div>
                                <div className={"p-2"}>{getTotalVisitos(template)}</div>
                                <div className={"p-2"}>{getUploadVsRendered(template)}</div>
                                <div className={"p-2"}>{getTotalRenders(template)}</div>
                                <div className={"p-2 text-gray-500 text-sm"}>
                                    <Link to={`/admin/b2b-companies/${id}/templates/${template.template.id}/statistics`} className={"py-2 px-4 ease-in-out duration-300 text-secondary hover:text-primary cursor-pointer transition-all"}>
                                        Statistieken
                                    </Link>
                                </div>
                            </div>
                        </div>

                    )
                })
            }

        </>
    )
}