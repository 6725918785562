import '../../Assets/css/b2b/olf/index.css';
import {useBoundStore} from "../../Store/store";
import React, {useEffect, useState} from "react";
import {VideoBlock} from "../../Components/Video_Editor/VideoBlock";
import {PresetVideoLoaded} from "../../Components/Video_Editor/EditorComponents/PresetVideoLoaded";
import OlfContactInfoPopup from "../../Components/Popups/olf/OlfContactInfoPopup";
import toast from "react-hot-toast";
import {faCheck, faMultiply, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OlfGuidelinePopup from "../../Components/Popups/olf/OlfGuidelinePopup";
import {Helmet} from "react-helmet";

export const Olf = () => {
    const fetchB2bTemplate = useBoundStore((state) => state.fetchB2bTemplate);
    const templateDetails = useBoundStore((state) => state.b2btemplate);
    const videos = useBoundStore((state) => state.videos);
    const renderVideo = useBoundStore((state) => state.renderVideo);
    const videoTimings = useBoundStore(state => state.videoTimings);

    const [showModal, setShowModal] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);

    useEffect(() => {
        fetchB2bTemplate('openluchtfuif-alken').then(() => {});
    }, [fetchB2bTemplate])

    const getPresetVideoAtIndex = (index) => {
        if(templateDetails.jsonConfig?.presetVideos !== null && templateDetails.jsonConfig?.presetVideos !== undefined)
        {
            const found = templateDetails.jsonConfig.presetVideos.find((item) => item.position === index + 1);
            if(found){
                return templateDetails.presetVideos.find((video) => video.fileName === found.video);
            }
        }

        return null;
    }

    const handleDataSubmitted = (data) => {
        setShowModal(false);
        const params = {
            contactInformation: {
                firstname: data.firstname,
                lastname: data.lastname,
                email: data.email,
            },
            b2bTemplateId: templateDetails.id,
            b2bClient: 2,
            videoTimings: videoTimings.map((timing) => [timing.start, timing.end]),
            videos: videos.filter((value) => value != null).map((vid) => vid.files.id)
        }

        renderVideo(params).then(() => {
            toast.custom((t) => (
                <div
                    className={`${
                        t.visible ? 'animate-enter' : 'animate-leave'
                    } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex flex-col md:flex-row ring-1 ring-black ring-opacity-5 items-center`}
                >
                    <div className="flex-shrink-0 w-[50px] pt-0.5 m-auto text-center text-2xl m-1 mt-2 p-2">
                        <FontAwesomeIcon icon={faCheck} className={"text-green-500 m-auto"} />
                    </div>
                    <div className="ml-3 flex-1 m-1 p-2">
                        <p className="text-sm font-medium text-gray-900">
                            Your video request has been sent! Once your video is approved and ready, you will receive it by email. Please make sure to check your spam folder to ensure you receive your result.
                        </p>
                    </div>
                    <div className="flex border-t md:border-t-0 md:border-l border-gray-200 m-1 p-2">
                        <button
                            onClick={() => toast.dismiss(t.id)}
                            className="w-full md:border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-grey-900 focus:outline-none"
                        >
                            <FontAwesomeIcon icon={faMultiply} className={''}/>
                        </button>
                    </div>
                </div>
            ),{duration: 15000})
        })
    }

    return (
        <div className={'olf-page min-h-screen p-4 flex flex-col justify-center'} style={{backgroundImage:"url('img/b2bimages/olf/bg.jpeg')", backgroundSize:"cover"}}>

            <Helmet>
                <script defer data-domain="openluchtfuif.exidofficial.com" src="https://analytics.devium.dev/js/script.js"></script>
            </Helmet>

            <img src={'img/b2bimages/olf/logo.png'} className={"h-32 w-32 mx-auto"} alt={'Logo olf'}/>

            <p className={"uppercase text-xl md:text-4xl text-white mt-8 mb-12"}>create your personal<br/>openluchtfuif alken memory</p>

            <div className={"text-5xl text-white mt-16 mx-auto"}>
                <div className={'flex container mx-auto flex-col md:flex-row'}>
                    {
                        templateDetails?.jsonConfig?.clips.map((detail, index) => {
                            const found = getPresetVideoAtIndex(index);
                            if(found === null) return <div className={"grid grid-col-1 justify-center"}>
                                <VideoBlock
                                    classes={'max-w-[80vw] min-w-[40vw] md:w-full max-h-[40vh]'}
                                    index={index}
                                    videoLength={detail}
                                    key={`videoblock_${index}`}
                                    showIndexNumber={false}
                                    backgroundColor={'rgba(255,255,255,0.3)'}
                                    icon={faUpload}
                                    companyId={2}
                                    showSupportTextClickUpload={true}
                                    buttonclasses={"bg-grey-900 text-white m-2 text-[20px] rounded cursor-pointer"}
                                />
                            </div>
                            // else return <PresetVideoLoaded key={`preset_video_${index}`} fileUrl={found.url} classes={"h-[300px] md:max-h-[40vh] mt-4"} heightClass={'h-[250px] md:h-full'} buttonclasses={"bg-grey-900 text-white m-2 text-[20px] rounded cursor-pointer"} />
                        })
                    }
                </div>

                {
                    !(videos.length === 0 || videos[1] === undefined || videos[1] === null) && (
                        <div className={"flex flex-col w-full justify-center mt-4 select-none"}>
                            <div className={"py-2 px-4 border border-white cursor-pointer w-fit bg-white text-black text-xl mx-auto hover:bg-transparent hover:text-white duration-300 transition-all"} onClick={() => {setShowModal(true)}}>Create my video</div>
                        </div>
                    )
                }
            </div>

            <div className={"text-5xl text-white my-4"}>
            {
                (videos.length === 0 || videos[1] === undefined || videos[1] === null) && (
                    <div className={"flex flex-col w-full justify-center select-none"}>
                        <div className={"py-2 px-4 border border-white w-fit bg-white text-black text-xl mx-auto duration-300 transition-all opacity-70"} onClick={() => {}}>Create my video</div>
                    </div>
                )
            }
            </div>

            <div className={"text-white text-center w-full underline my-4 cursor-pointer"} onClick={() => setShowInfoModal(true)}>
                How do I use this?
            </div>

            <p className={"text-2xl text-white mb-6 mt-4"}>Sponsors</p>
            <div className={"grid grid-cols-1 md:grid-cols-3 container mx-auto justify-evenly space-y-6 md:space-y-0 items-center"}>
                <a href={"https://www.openluchtfuif.com/"} target={"_blank"} rel="noreferrer">
                    <img src={'img/b2bimages/olf/logo.png'} className={"max-h-16 w-auto mx-auto"} alt={'Logo of OLF'}/>
                </a>
                <a href={"https://exidofficial.com"} target={"_blank"} rel="noreferrer">
                <img src={'img/b2bimages/olf/exid-logo.png'} className={"max-h-16 w-auto mx-auto"} alt={"Logo of EXID"}/>
                </a>
                <a href={"https://devium.be"} target={"_blank"} rel="noreferrer">
                <img src={'img/b2bimages/olf/devium-logo.png'} className={"max-h-16 w-auto mx-auto"} alt={"Logo of Devium"}/>
                </a>
            </div>

            <OlfContactInfoPopup
                isShown={showModal} closePopup={() => setShowModal(false)} clickConfirmed={handleDataSubmitted}
            />

            <OlfGuidelinePopup
                isShown={showInfoModal} closePopup={() => setShowInfoModal(false)}
            />
        </div>
    );
}