import Footer from "../Components/General/Footer";
import BareHomeNavigation from "../Components/Navigation/BareHomeNavigation";
import ReturnPolicy from "../Components/legal/ReturnPolicy";

export default function ReturnPolicyPage() {
    return (
        <>
            <BareHomeNavigation />
            <ReturnPolicy />
            <Footer />
        </>
    );
}