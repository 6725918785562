import {axiosClient} from "./HttpClient";
import {t} from "i18next";

export function login(username, password){
    return axiosClient(true).post('oauth/token', {
        grant_type: 'password',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        username: username,
        password: password,
        scope: "*"
    }, {
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data"
        }
    }).then((res) => {
        return res.data;
    }).catch((res) => {
        if(!res.response) {
            return {'error': true, 'error_description': t('qr.incorrect_login')}
        }
        return res.response.data
    })
}

export function register(data){
    return axiosClient().post('register', data)
        .then((res) => {
            return res.data
        }).catch((res) => {
            return res.response.data;
        })
}

export function passwordReset(data){
    return axiosClient().post('password-reset',data)
        .then((res) => {
            return res.data
        }).catch((res) => {
            return res.response.data;
        })
}