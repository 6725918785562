/* eslint-disable jsx-a11y/anchor-is-valid */
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";

export default function Footer(){
    const {t} = useTranslation();

    return (
        <>
            <div className={'h-full bg-primary px-6 py-8'}>
                <div className={'flex flex-col md:flex-row md:justify-between items-center gap-4 border-b border-b-white border-opacity-30 pb-6'}>
                    <div>
                        <img src={'/img/nav-logo.png'} alt={"Exid logo"}/>
                        <p className={"text-white mt-4 text-left"}>{t('home.footer_subtitle')}</p>
                        <div className={"flex flex-wrap justify-evenly gap-4 mt-4 mb-4"}>
                            <a href="https://www.facebook.com/EXIDofficialpage" target={"_blank"} className={"text-white text-3xl"} rel="noreferrer">
                                <FontAwesomeIcon icon={brands('square-facebook')} />
                            </a>
                            <a href="https://www.instagram.com/exidofficial.be/" target={"_blank"} className={"text-white text-3xl"} rel="noreferrer">
                                <FontAwesomeIcon icon={brands('square-instagram')} />
                            </a>
                            <a href="https://www.youtube.com/channel/UCdiP4v_7G_7Z7C_sLMwZRrA" target={"_blank"} className={"text-white text-3xl"} rel="noreferrer">
                                <FontAwesomeIcon icon={brands('square-youtube')} />
                            </a>
                            <a href="https://www.linkedin.com/company/exidofficial" target={"_blank"} className={"text-white text-3xl"} rel="noreferrer">
                                <FontAwesomeIcon icon={brands('linkedin')} />
                            </a>
                        </div>

                        <a href={"https://www.exidofficial.com/artiest/"} target={"_blank"}
                        className={"text-primary"} rel="noreferrer"
                        >{t('home.exid_for_artists')}</a>
                    </div>

                    <div className={"lg:max-w-1/2"}>
                        <h3 className={"text-2xl text-white uppercase text-left font-bold"}>{t('home.contact_us_title')}</h3>
                        <p className={"text-white text-left mt-4"}>{t('home.contact_us_description')}</p>
                        <div className={"w-fit mt-4"}>
                            <a href="https://www.exidofficial.com/home/hulp/contact/" className={"inline-flex items-center bg-primary text-white h-7 px-8 py-5 m-2 transition-colors duration-150 rounded"}
                               target={"_blank"}
                               rel="noreferrer"
                            >{t('home.get_in_touch')}</a>
                        </div>
                    </div>
                </div>

                <div className={"flex flex-col md:flex-row justify-between mt-2"}>
                    <p className={"text-gray-500"}>© 2022 , All Rights Reserved</p>
                    <div className={"flex flex-col md:flex-row justify-evenly gap-8"}>
                        <Link to="license-term" target={"_blank"} className={"text-gray-500 hover:text-white transition-all duration-300"}>{t('home.license_term')}</Link>
                        <Link to="tos" target={"_blank"} className={"text-gray-500 hover:text-white transition-all duration-300"}>{t('home.tos')}</Link>
                        <Link to="privacy-policy" target={"_blank"} className={"text-gray-500 hover:text-white transition-all duration-300"}>{t('home.privacy_policy')}</Link>
                        <Link to="cookie-policy" target={"_blank"} className={"text-gray-500 hover:text-white transition-all duration-300"}>{t('home.cookie_policy')}</Link>
                        {/*<Link to="return-policy" target={"_blank"} className={"text-textgrey hover:text-primary"}>{t('home.return_policy')}</Link>*/}
                    </div>
                </div>
            </div>
        </>
    )
}