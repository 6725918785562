import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {login} from "../../Api/authenticationApi";
import {useTranslation} from "react-i18next";
import {useContext, useState} from "react";
import AuthContext from "../../Helpers/AuthenticationHelper";
import toast from "react-hot-toast";
import {LocaleSwitcher} from "../../Components/Navigation/LocaleSwitcher";
import {getCurrentUserActiveDropbox} from "../../Api/qrApi";

export const Login = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { t } = useTranslation();
    const {setLoggedInUser} = useContext(AuthContext);
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const handleLogin = async () => {
        const res = await login(username, password);
        if("error" in res) {
            toast.error(res.error_description);
        }else{
            await setLoggedInUser(res.access_token, res.refresh_token);

            const dropbox = await getCurrentUserActiveDropbox();

            if(dropbox && dropbox.success && dropbox.dropbox_key) {
                navigate(`/qr/${dropbox.dropbox_key}/files`, { replace:true });
            } else {
                navigate(`/qr/buy?package=${searchParams.has('package') ? searchParams.get('package') : 1}`, {
                    replace: true
                });
            }
        }
    }

    return (
        <div className="relative mx-auto w-full bg-[#FFF8EC]">
            <div className="grid min-h-screen grid-cols-10">
                <div className="col-span-full py-6 px-4 sm:py-12 lg:col-span-6 lg:py-24">
                    <div className="mx-auto w-full max-w-lg">
                        <h1 className="relative text-2xl font-medium text-gray-700 sm:text-3xl"> {t('qr.login_title')}<span
                            className="mt-2 block h-1 w-10 bg-primary sm:w-20"></span></h1>
                        <form action="" className="mt-10 flex flex-col space-y-4">
                            <div>
                                <label
                                    htmlFor="email"
                                    className="text-xs font-semibold text-gray-500"
                                >
                                    {t('qr.email')}
                                </label>
                                <input type="email"
                                       id="email"
                                       value={username}
                                       onChange={(e) => {setUsername(e.target.value)}}
                                       name="email"
                                       placeholder="welcome@exidofficial.com"
                                       className="mt-1 block w-full rounded border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="password"
                                    className="text-xs font-semibold text-gray-500"
                                >
                                    {t('qr.password')}
                                </label>
                                <input type="password"
                                       id="password"
                                       value={password}
                                       onChange={(e) => {setPassword(e.target.value)}}
                                       name="password"
                                       className="mt-1 block w-full rounded border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-primary"
                                />
                            </div>
                        </form>

                        <p className="mt-10 text-center text-sm font-semibold text-gray-500">
                            {t('qr.dont_have_account')} <Link to={`/qr/register?package=${searchParams.has('package') ? searchParams.get('package') : 1}`}><span className={'text-primary'}> {t('qr.register_here')}</span></Link>
                        </p>

                        <p className="mt-4 text-center text-sm font-semibold text-gray-500">
                            {t('qr.forgot_password')} <Link to={`/qr/forgot-password?package=${searchParams.has('package') ? searchParams.get('package') : 1}`}><span className={'text-primary'}> {t('qr.reset_here')}</span></Link>
                        </p>

                        <button type="submit"
                                onClick={handleLogin}
                                className="mt-8 inline-flex w-full items-center justify-center rounded bg-primary py-2.5 px-4 text-base font-semibold tracking-wide text-white text-opacity-80 outline-none ring-offset-2 transition hover:text-opacity-100 focus:ring-2 focus:ring-primary sm:text-lg">
                            {t('qr.login_button')}
                        </button>
                    </div>
                </div>
                <div className="relative col-span-full flex flex-col py-6 pl-8 pr-4 sm:py-12 lg:col-span-4 lg:py-24">
                    <img src={'/img/nav-logo.png'} className={"mx-auto h-10 z-50"} alt={"Logo exid"}/>
                    <div className={'z-50 absolute top-2 right-2'}>
                        <LocaleSwitcher bgColor={'bg-[#FFF8EC]'}/>
                    </div>

                    <div>
                        <img
                            src="https://images.unsplash.com/photo-1533158307587-828f0a76ef46?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            alt="" className="absolute inset-0 h-full w-full object-cover"/>
                        <div
                            className="absolute inset-0 h-full w-full bg-gradient-to-t from-input to-primary opacity-95"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}