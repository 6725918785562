import React from 'react';
import { FileDrop } from 'react-file-drop'
import './css/demoeditor.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import DemoEditorSource from "./DemoEditorSource";

class DemoVideoEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isUpload: true,
            videoUrl: "",
        }
    }

    componentDidMount = () => {
        document.addEventListener('drop', function(e) {
            e.preventDefault();
            e.stopPropagation();
          });
    }

    render_uploader = () => {
        return(
            <div className={"flex flex-col md:flex-row h-full"}>
                <div className={`m-auto w-full relative h-full`}>
                    <input
                        onChange={(e) => this.upload_file(e.target.files)}
                        type="file"
                        className="hiddenz"
                        id="up_file"
                    />
                    <FileDrop
                        onDrop={(e) => this.upload_file(e)}
                        onTargetClick={() => document.getElementById("up_file").click()}
                    >
                        <FontAwesomeIcon icon={icon({name: "plus"})} className={"text-5xl bold text-demo"} />
                    </FileDrop>
                </div>

                {
                    ((!this.props.lastElement && !this.props.lastInRow) || ( window.innerWidth < 770 && !this.props.lastElement)) && (
                        <FontAwesomeIcon icon={icon({name: "chevron-right"})} className={`text-white text-5xl m-auto`}/>
                    )
                }
            </div>

        )
    }

    render_editor = () => {
        return(
            <DemoEditorSource
                duration={ this.props.duration }
                namez={ this.props.name }
                id={this.props.id}
                videoUrl={this.state.videoUrl}
                VideoHelper={this.props.VideoHelper}
                index={this.props.index}
                progress={this.props.progress}
                currentHandlingId={this.props.currentHandlingId}
                className={"h-full"}
            />
        )
    }

    upload_file = async (fileInput) => {
        let testBlob = await new Blob([fileInput[0]] ,  {type: "video/mp4", });

        const fileUrl = window.URL.createObjectURL(testBlob);
        this.setState({
            isUpload: false,
            videoUrl: fileUrl
        })

        this.props.VideoHelper.handlingVideoId = this.props.index;
        await this.props.VideoHelper.addVideoToList(fileInput[0], this.props.index);
        this.props.VideoHelper.handlingVideoId = null;

        this.props.VideoHelper.setTimingForVideo(this.props.index, 0);
    }

    render = () => {
        return(
            <div className={"w-full h-full"}>
                {this.state.isUpload ? this.render_uploader() : this.render_editor()}
            </div>
        )
    }
}

export default DemoVideoEditor