import {useTranslation} from "react-i18next";

export default function B2bTermsOfServiceContent() {
	const {t} = useTranslation();

	return (
        <>
			<div className={"flex flex-row relative"}>
				<div className={"text-left text-white p-8 mt-12 gap-8"}>

					<p className={"text-primary text-2xl pb-4"}><b><u>{t('legal.tos.title')}</u></b></p>

					<p>
						Door het EXID Platform te bezoeken of te gebruiken, gaat u ermee akkoord te voldoen aan en gebonden te zijn door deze Gebruikersvoorwaarden ("Voorwaarden"). Lees deze Voorwaarden zorgvuldig door aangezien ze belangrijke informatie bevatten over uw wettelijke rechten, rechtsmiddelen en verplichtingen.
					</p>
					<p>
						Deze Voorwaarden vormen een wettelijk bindende overeenkomst ("Overeenkomst") tussen u en EXID (zoals hieronder gedefinieerd) met betrekking tot uw toegang tot en gebruik van de EXID website en het EXID Platform, met inbegrip van alle subdomeinen daarvan, en alle andere websites via welke EXID haar diensten beschikbaar stelt (gezamenlijk, "Site") en applicatieprogramma-interfaces (gezamenlijk, "Applicatie") en alle bijbehorende diensten (gezamenlijk, "EXID Services"). De Site, Applicatie en EXID Services tezamen worden hierna gezamenlijk aangeduid als het "EXID Platform".
					</p>
					<p>Wanneer in deze Voorwaarden wordt gesproken over "EXID," "wij," "ons," of "onze," verwijst dit naar de onderneming Point Of View ofwel de heer Jeroen Olaerts, gevestigd te 3600 GENK, Sledderloweg 19 en ingeschreven in de K.B.O onder nummer BE0649.570.002</p>

					<ol className={"m-auto pl-4 marker:text-primary mt-2 list-decimal"}>
						<li>Omvang van de EXID Services</li>
						<li>Toegang, gebruik van het EXID Platform, verificatie van de gebruiker </li>
						<li>Wijziging van deze Voorwaarden </li>
						<li>Account Registratie</li>
						<li>Gebruik van het EXID Platform en toegekende rechten</li>
						<li>Content, (intellectuele) eigendomsrechte, niet-afwerving</li>
						<li>Garanties en disclaimer</li>
						<li>Aansprakelijkheid</li>
						<li>Schadeloosstelling</li>
						<li>Toepasselijk recht en bevoegde rechtbanken</li>
						<li>Feedback</li>
						<li>Algemene bepalingen</li>
					</ol>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>1.	Omvang van de EXID Services</h1>
					<p>1.1 Het EXID Platform biedt templates voor videomontages aan dewelke eenvoudig te gebruiken zijn door de gebruikers van het Platform ("Gebruiker(s)").  </p>
					<p>1.2 Als aanbieder van het EXID Platform, biedt EXID geen afgewerkte video’s aan maar templates van videomontages (inclusief muziek) die door de Gebruikers gebruikt kunnen worden om eenvoudig, snel en goedkoop zelf een afgewerkte en gemonteerde video te maken. </p>
					<p>1.3 EXID is niet verantwoordelijk voor storingen of onderbrekingen van de internet- en telecommunicatie-infrastructuur die buiten onze controle vallen en kunnen leiden tot onderbrekingen in de beschikbaarheid van het EXID Platform. EXID kan, tijdelijk en met inachtneming van de legitieme belangen van de Gebruiker (bv. door voorafgaande kennisgeving), de beschikbaarheid van het EXID Platform of bepaalde gebieden of functies ervan beperken, indien dit noodzakelijk is met het oog op capaciteitsbeperkingen, de veiligheid of integriteit van onze servers, of om onderhoudsmaatregelen uit te voeren die de goede of verbeterde werking van het EXID Platform verzekeren. EXID kan het EXID Platform verbeteren, uitbreiden en wijzigen en van tijd tot tijd nieuwe EXID Services of diensten van derden introduceren. EXID zal leden en Gebruikers op de hoogte brengen van enige wijzigingen aan het EXID Platform, tenzij dergelijke wijzigingen van geringe aard zijn en geen wezenlijke invloed hebben op de contractuele verplichtingen van de partijen.</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>2.	Toelating, gebruik van het EXID Platform, verificatie van de gebruiker</h1>
					<p>2.1 Om toegang te krijgen tot en gebruik te maken van het EXID Platform of een EXID Account te registreren dient u een (rechts)persoon te zijn van tenminste 18 jaar oud of een naar behoren georganiseerd, geldig bestaand bedrijf, organisatie of andere juridische entiteit onder de wetten van het land waar u bent geregistreerd en in staat om wettelijk bindende contracten af te sluiten. </p>
					<p>2.2 EXID kan de toegang tot en het gebruik van het EXID Platform, of bepaalde gebieden of functies van het EXID Platform, afhankelijk maken van bepaalde voorwaarden of vereisten, zoals het voltooien van een verificatieproces en/of het ontvangen van een toelating van een derde . </p>
					<p>2.3 Gebruikersverificatie op het Internet is moeilijk en wij nemen geen enkele verantwoordelijkheid voor de bevestiging van de identiteit van enige Gebruiker. Niettegenstaande het bovenstaande, voor transparantie en fraudepreventie doeleinden, en zoals toegestaan door de toepasselijke wetgeving, kunnen wij, maar zijn niet verplicht om Gebruikers te vragen om een vorm van overheidsidentificatie of andere informatie te verstrekken of aanvullende controles uit te voeren om de identiteit of achtergrond van Gebruikers te helpen verifiëren.</p>
					<p>2.4 De toegang tot of het gebruik van bepaalde gebieden en functies van het EXID Platform kan onderworpen zijn aan afzonderlijke beleidslijnen, normen of richtlijnen, of kan vereisen dat u aanvullende voorwaarden aanvaardt, voordat u toegang krijgt tot de relevante gebieden of functies van het EXID Platform. Indien er een conflict is tussen deze Voorwaarden en de voorwaarden die van toepassing zijn op een specifiek gebied of onderdeel van het EXID Platform, zullen de laatstgenoemde voorwaarden voorrang hebben met betrekking tot uw toegang tot of gebruik van dat gebied of onderdeel, tenzij anders vermeld in de laatstgenoemde voorwaarden. </p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>3.	Wijziging van deze Voorwaarden </h1>
					<p>3.1 EXID behoudt zich het recht voor deze Voorwaarden te allen tijde te wijzigen in overeenstemming met deze bepaling. Indien wij wijzigingen aanbrengen in deze Voorwaarden, zullen wij de herziene Voorwaarden op het EXID Platform plaatsen en de "Laatst bijgewerkt"-datum bovenaan deze Voorwaarden bijwerken. </p>
					<p>3.2 Wij zullen u ook op de hoogte stellen van de wijzigingen per e-mail ten minste dertig (30) dagen voor de datum waarop deze van kracht worden (of: opnieuw uitdrukkelijk toestemming vragen aan Gebruiker bij gebruik van het EXID Platform). Indien u het niet eens bent met de herziene Voorwaarden, kunt u deze Overeenkomst met onmiddellijke ingang beëindigen. Wij zullen u informeren over uw recht om de Overeenkomst te beëindigen in de kennisgevingse-mail. Indien u uw Overeenkomst niet beëindigt vóór de datum waarop de herziene Voorwaarden van kracht worden, houdt uw voortdurende toegang tot of gebruik van het EXID Platform aanvaarding van de herziene Voorwaarden in. </p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>4.	Account Registratie</h1>
					<p>4.1 Iedere Gebruiker dient een account ("EXID Account") te registreren om toegang te krijgen tot en gebruik te maken van de mogelijkheden van het EXID Platform. Indien U een EXID Account registreert voor een bedrijf, organisatie of andere juridische entiteit, verklaart en garandeert U dat U bevoegd bent die entiteit juridisch te binden en ons alle permissies en licenties te verlenen die in deze Voorwaarden zijn voorzien.</p>
					<p>4.2 U dient juiste, actuele en volledige informatie te verstrekken tijdens het registratieproces en uw EXID Account te allen tijde up-to-date te houden. </p>
					<p>4.3 U mag uw EXID Account niet toewijzen of anderszins overdragen aan een andere partij. </p>
					<p>4.4 U bent verantwoordelijk voor het handhaven van de vertrouwelijkheid en veiligheid van uw EXID Account gegevens en mag uw gegevens niet aan een derde verstrekken. U dient EXID onmiddellijk op de hoogte te stellen indien u weet of reden heeft om te vermoeden dat uw inloggegevens zijn verloren, gestolen, verduisterd of anderszins in gevaar zijn gebracht of in geval van enig daadwerkelijk of vermoed ongeautoriseerd gebruik van uw EXID Account. U bent aansprakelijk voor alle activiteiten die via uw EXID Account worden uitgevoerd, tenzij dergelijke activiteiten niet door u zijn geautoriseerd en u niet anderszins nalatig bent (zoals het niet melden van ongeautoriseerd gebruik of verlies van uw inloggegevens). </p>
					<p>4.5 EXID kan functies inschakelen die u in staat stellen andere Gebruikers of bepaalde derden te machtigen om bepaalde handelingen te verrichten die van invloed zijn op uw EXID Account. Wij kunnen bijvoorbeeld Gebruikers in staat stellen hun EXID Accounts te koppelen aan bedrijven en acties te ondernemen voor die bedrijven, wij kunnen in aanmerking komende Gebruikers of bepaalde derden in staat stellen EXID Services te bestellen namens andere Gebruikers, of wij kunnen Gebruikers in staat stellen andere Gebruikers toe te voegen als Co-Gebruiker (zoals hieronder gedefinieerd) om hun opdrachten te helpen beheren. Geen derde partij is door EXID gemachtigd om uw inloggegevens op te vragen, en u zult niet om de inloggegevens van een andere Gebruiker vragen. </p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>5.	Gebruik van het EXID platform en toegekende rechten</h1>
					<p>5.1 De Gebruiker kan op het EXID Platform  een video-template bewerken  tot een afgewerkte video en deze nadien downloaden om vervolgens te publiceren op zijn of haar social media kanalen. </p>
					<p>5.2 Het EXID Platform, de inhoud van het EXID Platform en de templates beschikbaar op het EXID Platform zijn geheel of gedeeltelijk beschermd zijn door auteursrecht en/of andere (intellectuele) eigendomsrechten. De Gebruiker erkent en gaat ermee akkoord dat het EXID Platform en de templates beschikbaar op het EXID platform de exclusieve eigendom zijn van EXID en/of haar licentiegevers of bevoegde derde partijen. U zult geen auteursrecht-, handelsmerk- of andere eigendomsrechtmededelingen opgenomen in of behorende bij het EXID Platform of de templates verwijderen, wijzigen of onleesbaar maken. Alle handelsmerken, logo's, handelsnamen, en enige andere bronaanduidingen van EXID gebruikt op of in verband met het EXID Platform zijn het exclusieve eigendom van EXID en/of haar licentiegevers of bevoegde derden. Handelsmerken, logo's, handelsnamen en enige andere eigendomsaanduidingen van derden gebruikt op of in verband met het EXID Platform worden enkel gebruikt voor identificatiedoeleinden en kunnen de eigendom zijn van hun respectievelijke eigenaars. </p>
					<p>5.3 Onder voorbehoud van uw naleving van deze Voorwaarden en de betaling van de vergoeding, verleent EXID u een beperkte, niet-exclusieve, niet-sublicentieerbare, herroepbare, niet-overdraagbare licentie om (i) het EXID Platform (en de eventuele software) te gebruiken op uw persoonlijke apparaat(en); en (ii) de aangeleverde templates te gebruiken binnen de grenzen van de afgesloten licentie zoals bepaald op het EXID Platform.</p>
					<p>5.4	De Gebruiker zal de aangeleverde templates niet gebruiken, kopiëren, aanpassen, wijzigen, er afgeleide werken van voorbereiden, distribueren, in licentie geven, verkopen, overdragen, publiekelijk tonen, publiekelijk uitvoeren, overbrengen, uitzenden of anderszins exploiteren, behalve in de mate zoals uitdrukkelijk toegestaan in deze Voorwaarden en in de door de Gebruiker afgesloten licentie. Geen licenties of rechten worden u verleend met uitzondering van de licenties en rechten die uitdrukkelijk worden verleend in deze Voorwaarden. </p>
					<p>5.5	Het is de Gebruiker niet toegestaan: (i) het aan eender welke andere partij verkopen, overdragen, in sublicentie verhuren, weggeven of anderszins toewijzen van de template of de werken uitgevoerd middels gebruik van deze template. (ii) het doorverkopen van de template zelf of als onderdeel van een pakket. (iii) het doorverkopen van de template (of anderszins beschikbaar maken) op eender welke manier waardoor een andere partij de mogelijkheid krijgt om de template of een deel van de template als afzonderlijk bestand te downloaden. (iv) het indienen van de claim dat u de maker of auteursrechteigenaar bent van de template, een deel van de template of eender welk afgeleide werk dat op basis van de template is gemaakt.</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>6.	Content, (intellectuele) eigendomsrechten, niet-afwerving</h1>
					<p>6.1 Het EXID Platform en de Content beschikbaar op het EXID Platform zijn geheel of gedeeltelijk beschermd zijn door auteursrecht, merkenrecht en/of andere (intellectuele) eigendomsrechten. De Gebruiker erkent en gaat ermee akkoord dat het EXID Platform het exclusieve eigendom is van EXID en/of haar licentiegevers of derden waarmee EXID een overeenkomst heeft. Alle merken, logo's, handelsnamen en andere bronaanduidingen van EXID die gebruikt worden op of in verband met het EXID Platform zijn exclusief eigendom van EXID en/of haar licentiegevers of derden waarmee EXID een overeenkomst heeft. Merken, logo's, handelsnamen en andere eigendomsaanduidingen van derde partijen die gebruikt worden op of in verband met het EXID Platform worden alleen gebruikt voor identificatiedoeleinden.</p>
					<p>6.2 De Gebruiker zal het EXID Platform en de Content op het EXID Platform (al dan niet door de Gebruiker gedownload) niet gebruiken, kopiëren, aanpassen, wijzigen, er afgeleide werken van maken, distribueren, in licentie geven, verkopen, overdragen, publiekelijk tonen, publiekelijk uitvoeren, overdragen, uitzenden of anderszins exploiteren, behalve voor zover u de wettelijke eigenaar bent van bepaalde zaken die deel uitmaken van het EXID Platform of zoals uitdrukkelijk is toegestaan in deze Voorwaarden of middels de door de Gebruiker aangekochte licentie. Er worden geen impliciete of stilzwijgende licenties verleend. </p>
					<p>6.3 De Gebruiker verleent middels het gebruik van het EXID Platform een niet-exclusieve en overdraagbare licentie op alle eigen video- en of fotomateriaal dat door de Gebruiker op het EXID Platform wordt opgeladen alsook op de afgewerkte video’s waarvoor de Gebruiker de op het EXID Platform beschikbare content heeft gebruikt.</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>7.	Garanties en disclaimer</h1>
					<p>7.1 Het gebruik van het EXID Platform is op eigen risico van de Gebruiker. Het EXID Platform wordt door EXID onder deze gebruiksvoorwaarden 'als zodanig' aangeboden, zonder enige vorm van garantie, expliciet noch impliciet, wettelijk of anderszins. </p>
					<p>7.2	EXID wijst uitdrukkelijk alle garanties van de hand, hetzij expliciet of impliciet, inclusief, maar niet beperkt tot impliciete garanties van de verkoopbaarheid, geschiktheid voor een bepaald doel en niet-inbreuk en alle andere garanties die kunnen voortvloeien uit een wet. Zonder het bovenstaande te beperken, biedt EXID geen garantie dat: (i) het EXID Platform voldoet aan uw vereisten; (ii) de kwaliteit van het EXID Platform of de Content beschikbaar op het EXID Platform aan uw verwachtingen zal voldoen; en (iii) eventuele fouten of defecten op het EXID Platform, in de diensten of materialen worden gecorrigeerd. </p>
					<p>7.3	Voor zover een afwijzing van aansprakelijkheid of beperking van aansprakelijkheid niet van toepassing is, zijn alle toepasselijke expliciete, impliciete en wettelijke garanties van beperkte duur tot een periode van dertig (30) dagen na de datum waarop u het EXID Platform voor het eerst hebt gebruikt en na deze periode zijn geen garanties van toepassing.</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>8.	Aansprakelijkheid </h1>
					<p>8.1 Alle onjuiste, onvolledige, of anderszins onjuiste informatie toegevoegd door de Gebruiker of onjuist gebruik van het EXID Platform is de exclusieve verantwoordelijkheid van de Gebruiker, zonder enige aansprakelijkheid in hoofde van EXID. </p>
					<p>8.2 EXID kan nooit aansprakelijk gesteld worden in de volgende gevallen: (i) Technische of computerstoring. De incompatibiliteit van het EXID Platform met enige hardware of software zal niet leiden tot enige aansprakelijkheid van de kant van EXID. (ii) Directe of indirecte, materiële of gevolgschade, voorzienbare of onvoorzienbare schade aan de Gebruiker die voortvloeit uit het gebruik of de moeilijkheid tot gebruik van het EXID Platform. (iii) De verspreiding van enige onnauwkeurige, niet-veilige of onbetrouwbare informatie op het internet.</p>
					<p>8.3 De Gebruiker is verantwoordelijk voor de bescherming van zijn apparatuur en gegevens, voor het gebruik dat wordt gemaakt van het EXID Platform of de EXID Services en voor enige inbreuk op deze Algemene Voorwaarden.</p>
					<p>8.4 EXID is op grond van wettelijke bepalingen slechts aansprakelijk voor opzet en grove schuld van ons, onze wettelijke vertegenwoordigers of bestuurders. </p>
					<p>8.5 EXID kan nooit aansprakelijk gesteld worden voor daden van derden waarover zij zelf geen controle uitoefent, zelfs niet in het geval de derden middels niet-foutieve tussenkomst van EXID schade berokkenen aan de Gebruiker.</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>9.	Schadeloosstelling </h1>
					<p>9.1 De Gebruiker gaat ermee akkoord om EXID, haar eventuele dochterondernemingen, verbonden ondernemingen, licentieverstrekkers, medewerkers, vertegenwoordigers, derde-informatieverstrekkers, indieners en onafhankelijke freelancers te verdedigen, schadeloos te stellen en te vrijwaren tegen vorderingen, schade, kosten, aansprakelijkheid en uitgaven (waaronder, maar niet beperkt tot redelijke advocaatkosten) die voortkomen uit of verband houden met uw handelswijze, uw gebruik van het EXID Platform of het niet in staat zijn daartoe, uw (vermeende) tekortkoming in de nakoming van de websitegebruiksvoorwaarden of van enige verklaring of garantie hierin opgenomen, uw ongeautoriseerde gebruik van de Inhoud van het EXID Platform of uw inbreuk op andere rechten.</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>10.	Toepasselijk recht en bevoegde rechtbanken</h1>
					<p>10.1 Deze Voorwaarden worden beheerst door en geïnterpreteerd in overeenstemming met het Belgisch recht. </p>
					<p>10.2 Partijen komen wederzijds overeen dat de rechtbanken van het arrondissement Antwerpen, afdeling Hasselt exclusief bevoegd zijn om geschillen te beslechten die voortvloeien uit of verband houden met deze Voorwaarden of de toepasselijkheid, schending, beëindiging, geldigheid, handhaving of interpretatie ervan, of het gebruik van het EXID Platform (gezamenlijk, "Geschillen"). </p>
					<p>10.3 Dit Artikel 10 zal van kracht blijven na enige beëindiging van deze Voorwaarden en zal van toepassing blijven zelfs indien u stopt met het gebruik van het EXID Platform of uw EXID Account opzegt. </p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>11.	Feedback </h1>
					<p>Wij nodigen u uit om feedback, commentaar en suggesties voor verbeteringen aan het EXID Platform ("Feedback") te geven. U kunt Feedback geven door ons te e-mailen, via de "Contact" sectie van het EXID Platform, of via andere communicatiemiddelen. Alle feedback die u aan ons verstrekt wordt beschouwd als niet-vertrouwelijk en zonder eigendomsrecht voor u. Door het indienen van feedback aan ons, verleent u ons een niet-exclusieve, wereldwijde, royalty-vrije, onherroepelijke, sublicentieerbare, eeuwigdurende licentie om deze ideeën en materialen te gebruiken en te publiceren voor welk doel dan ook, zonder vergoeding aan u.</p>

					<h1 className={"text-primary text-xl pt-4 pb-2"}>12.	Algemene Bepalingen </h1>
					<p>12.1 Tenzij aangevuld door aanvullende voorwaarden, beleid, richtlijnen of standaarden, vormen deze Voorwaarden de gehele overeenkomst tussen EXID en de Gebruiker en vervangen zij alle voorgaande mondelinge of schriftelijke afspraken of overeenkomsten tussen EXID en de Gebruiker met betrekking tot de toegang tot en het gebruik van het EXID Platform alsook met betrekking tot de inhoud van het EXID Platform en de orders geplaatst door de Gebruiker. </p>
					<p>12.2 Er bestaat geen joint venture, partnerschap, dienstverband of agentschap relatie tussen de Gebruiker en EXID als gevolg van deze overeenkomst of uw gebruik van het EXID Platform. </p>
					<p>12.3 Indien enige bepaling van deze Voorwaarden ongeldig of niet-afdwingbaar wordt geacht, zal deze bepaling worden geschrapt en zal dit geen invloed hebben op de geldigheid en afdwingbaarheid van de overige bepalingen. </p>
					<p>12.4 Indien EXID enig recht of enige bepaling in deze Voorwaarden niet afdwingt, houdt dit niet in dat EXID afstand doet van een dergelijk recht of bepaling, tenzij dit door ons schriftelijk wordt erkend en overeengekomen. Tenzij uitdrukkelijk uiteengezet in deze Voorwaarden, zal de uitoefening door een partij van een van haar rechtsmiddelen onder deze Voorwaarden geen afbreuk doen aan haar andere rechtsmiddelen onder deze Voorwaarden of anderszins toegestaan onder de wet. </p>
					<p>12.5 De Gebruiker mag deze Overeenkomst en de rechten en verplichtingen op grond daarvan niet toewijzen, overdragen of delegeren zonder de voorafgaande schriftelijke toestemming van EXID. EXID kan deze Overeenkomst en alle rechten en verplichtingen op grond daarvan zonder enige beperking toewijzen, overdragen of delegeren, naar eigen goeddunken. Het recht van de Gebruiker om deze Overeenkomst te allen tijde te beëindigen blijft onverlet. </p>
					<p>12.6 Tenzij anders aangegeven, zullen enige kennisgevingen of andere mededelingen aan Gebruikeren toegestaan of vereist onder deze Overeenkomst, elektronisch worden verstrekt door EXID via e-mail, EXID Platform kennisgeving, of berichtendienst.</p>
				</div>
			</div>
        </>
    );
}