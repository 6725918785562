import {getAllTemplates, getFilteredTemplates, getTemplate} from "../../Api/templatesApi";

export const createTemplateSlice = (set, get) => ({
    templates: [],
    templateDetails: {},
    templateMetaData: [],
    fetchTemplates: async () => {
        const templates = await getAllTemplates();
        set({templates: templates});
    },
    fetchTemplateDetail: async (id) => {
        const templateDetail = await getTemplate(id);
        set({templateDetails: templateDetail});
        get().setVideoAmount(templateDetail?.jsonConfig?.clips?.length)
    },
    fetchTemplatesPaginated: async (page =1) => {
        const templates = await getFilteredTemplates('',[],page);
        set({templates: templates.data, templateMetaData: templates.meta});
    },
    setTemplateDetails: (details) => {
        set({templateDetails: details});
        get().setVideoAmount(details?.jsonConfig?.clips?.length)
    }
});