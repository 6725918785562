import {useContext} from "react";
import AuthContext from "../../Helpers/AuthenticationHelper";

export const HasCorrectRole = (props) => {
    const {
        roles,
        complyToAll = false,
        children
    } = props;

    const { hasRole } = useContext(AuthContext);

    let compliedToAll = true;
    if(complyToAll){
        roles.map((role) => {
            if(!hasRole(role)) compliedToAll = false;
        });
        return compliedToAll;
    } else {
        let hasHitArole = false;
        roles.map((role) => {
            if(hasRole(role)) hasHitArole = true;
        });

        if(hasHitArole) return children;
    }

    return null;
}